import React, { Fragment, useState } from 'react';
import { message, Space } from 'antd';
import Typography from 'component-library/src/typography/Typography';
import { spacing } from 'component-library/src/theme/theme';
import { useTranslation } from 'react-i18next';
import { useSubscriptionModalContext } from '../SubscriptionModalContext';
import { useForm } from 'antd/es/form/Form';
import Form from 'antd/lib/form/Form';
import FormItem from 'antd/lib/form/FormItem';
import { mixpanel } from 'tracking/tracking';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import theme from 'component-library/src/theme/theme';
import axios from 'axios';
import { useAuth } from 'hooks/useAuth';
import Radio, { Group } from 'components/radio/Radio';
import subscriptionService from 'features/subscriptions/services/subscriptionService';
import InputText from 'component-library/src/input-text/InputText';
import intercomService from 'services/intercomService';
import Modal from 'component-library/src/modal/Modal';
import { logging } from 'logging/logging';

export const cancellationReasons = [
  { reason: 'subscriptions.cancelReason.1', shouldAskDetail: false },
  { reason: 'subscriptions.cancelReason.2', shouldAskDetail: false },
  { reason: 'subscriptions.cancelReason.3', shouldAskDetail: true },
  { reason: 'subscriptions.cancelReason.4', shouldAskDetail: true },
  { reason: 'subscriptions.cancelReason.5', shouldAskDetail: true },
  { reason: 'subscriptions.cancelReason.6', shouldAskDetail: false },
] as const;
type CancelReason = {
  cancelReason: typeof cancellationReasons[number]['reason'];
  reasonDetail?: string;
};

const SubscriptionsCancelCheck: React.FC<React.PropsWithChildren<
  unknown
>> = () => {
  const { userSubscription, user, userProfile } = useAuth();
  const [mutating, setMutating] = useState(false);
  const [cancelReason, setCancelReason] = useState<
    CancelReason['cancelReason'] | null
  >(null);
  const { t } = useTranslation();
  const { dispatch } = useSubscriptionModalContext();
  const [form] = useForm();

  const handleSubscriptionCancel = async (
    formData: CancelReason
  ): Promise<void> => {
    setMutating(true);
    const { cancelReason, reasonDetail } = formData;
    try {
      await subscriptionService.cancelSubscription(
        userSubscription?.subscription?.id
      );
      await userSubscription?.revalidate();
      dispatch({ type: 'subscriptionsCancelConfirm' });
      mixpanel.track({
        event: 'SUBSCRIPTION_CANCELLATION',
        value: cancelReason,
        reasons: reasonDetail ? [cancelReason, reasonDetail] : [cancelReason],
      });
      intercomService.updateUser(user, userProfile, {
        cancellationReason: cancelReason,
      });
      intercomService.track('SUBSCRIPTION_CANCELLATION');
    } catch (e) {
      setMutating(false);
      if (axios.isAxiosError(e)) {
        logging.error({
          productArea: 'subscription',
          message: 'SUBSCRIPTION_CANCEL_FAILURE',
          messageContext: {
            error: e.response,
            errorMessage: e.response?.data,
          },
          error: e,
        });

        message.error({
          content: e.message,
          className: 'generic-api-error',
        });
        return;
      }
    }
  };

  return (
    <Modal
      onCancel={() => dispatch({ type: 'close' })}
      variant="functional"
      size="m"
      trackEvent={mixpanel.track.bind(mixpanel)}
      headerText={t('subscriptions.cancelPlanTitle')}
      modalRender={(children) => (
        <Form form={form} layout="vertical" onFinish={handleSubscriptionCancel}>
          {children}
        </Form>
      )}
      footerExtra={
        <Typography type="body" size="xs" color="neutral8" weightLevel="400">
          {t('subscriptions.cancelInfo')}
        </Typography>
      }
      footerButtons={[
        {
          children: t('subscriptions.cancelNo'),
          type: 'secondary',
          variant: 'outline',
          'data-testid': 'subscribe-cancel-no-button',
          onClick: () => dispatch({ type: 'close' }),
        },
        {
          children: t('subscriptions.cancelYes'),
          htmlType: 'submit',
          type: 'primary',
          variant: 'filled',
          'data-testid': 'subscribe-cancel-yes-button',
          loading: mutating,
          disabled:
            mutating ||
            form.getFieldsError().filter(({ errors }) => errors.length).length >
              0 ||
            !form.getFieldValue(['cancelReason']),
        },
      ]}
      className="subscription-modal"
      scroll
      open
      centered
      closable
    >
      <Space
        size={spacing.space40}
        direction="vertical"
        className="w-100"
        data-testid="subscription-cancel-modal"
      >
        <div className="subscription-cancel-effects">
          <Typography
            type="headline"
            size="xs"
            color="neutral9"
            weightLevel="500"
            customClass="w-100 mb-4"
          >
            {t('subscriptions.cancelEffectTitle')}
          </Typography>

          <ul className="cancellation-effects">
            {[1, 2, 3, 4, 5].map((idx) => (
              <li key={`subscriptions.cancelEffect.${idx}`}>
                {idx === 5 ? (
                  <CheckOutlined
                    style={{ color: theme.colors.primary5, fontSize: 16 }}
                  />
                ) : (
                  <CloseOutlined
                    style={{ color: theme.colors.danger4, fontSize: 16 }}
                  />
                )}
                <Typography
                  type="body"
                  size="s"
                  weightLevel="400"
                  color="neutral9"
                >
                  {t(`subscriptions.cancelEffectNewCancellation.${idx}`)}
                </Typography>
              </li>
            ))}
          </ul>
        </div>
        <FormItem
          className="mb-0"
          required
          name={['cancelReason']}
          label={
            <Typography
              block
              type="headline"
              size="xs"
              color="neutral9"
              weightLevel="500"
              customClass="w-100"
            >
              {t('subscriptions.cancelNumberOneReason')}
            </Typography>
          }
        >
          <Group
            onChange={(val): void => setCancelReason(val.target.value)}
            value={cancelReason}
            className="mt-4 w-100"
          >
            <Space
              direction="vertical"
              size={spacing.space16}
              className="w-100"
            >
              {cancellationReasons.map(({ reason, shouldAskDetail }) => (
                <Fragment key={reason.slice(reason.length - 1)}>
                  <Radio
                    variant="default"
                    value={reason}
                    label={t(reason)}
                    testId={`cancel-option-${reason}`}
                  />
                  <FormItem name={'reasonDetail'} noStyle>
                    {shouldAskDetail && reason === cancelReason && (
                      <InputText
                        testId={`reason-detail-field-${reason}`}
                        placeholder={t('subscriptions.cancelReasonPlaceholder')}
                      />
                    )}
                  </FormItem>
                </Fragment>
              ))}
            </Space>
          </Group>
        </FormItem>
      </Space>
    </Modal>
  );
};

export default SubscriptionsCancelCheck;
