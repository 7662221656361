import React from 'react';
import { Col, Row } from 'antd';
import Typography from 'component-library/src/typography/Typography';

interface RowInfoProps {
  label: React.ReactNode;
  value: React.ReactNode;
  testId?: string;
}

const RowInfoItem: React.FC<React.PropsWithChildren<RowInfoProps>> = ({
  label,
  value,
  testId,
}) => (
  <Row className="row-info-item">
    <Col xs={24} sm={24} md={12} lg={12} className="field-label">
      <Typography
        size="s"
        type="body"
        color="neutral7"
        data-testid={testId ? `${testId}-label` : ''}
      >
        {label}
      </Typography>
    </Col>
    <Col xs={24} sm={24} md={12} lg={12} className="field-value">
      <Typography
        size="s"
        type="body"
        color="neutral8"
        weightLevel="500"
        data-testid={testId ? `${testId}-value` : ''}
      >
        {value}
      </Typography>
    </Col>
  </Row>
);

export default RowInfoItem;
