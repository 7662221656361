import { Space } from 'antd';
import Button from 'component-library/src/button/Button';
import Typography from 'component-library/src/typography/Typography';
import { spacing } from 'component-library/src/theme/theme';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSubscriptionModalContext } from 'features/subscriptions/SubscriptionModalContext';
import Modal from 'component-library/src/modal/Modal';
import BeatportSalesBanner from 'components/subscriptions-payment/BeatportSalesBanner';
import { useNavigate } from 'react-router-dom';
import { paths } from 'config/routes';
import { mixpanel } from 'tracking/tracking';

const SubscriptionCycleSwitchSuccess = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { dispatch } = useSubscriptionModalContext();

  return (
    <Modal
      onCancel={() => dispatch({ type: 'close' })}
      variant="marketing"
      size="m"
      headerText={t('subscriptions.billingCycle.annual.switchSuccessTitle')}
      className="subscription-modal-switch-success"
      testId="subscription-modal-switch-success"
      trackEvent={mixpanel.track.bind(mixpanel)}
      open
      closable
      destroyOnClose
      centered
    >
      <Space
        direction="vertical"
        align="center"
        size={spacing.space32}
        className="w-100"
      >
        <Typography type="body" size="m" color="neutral8" align="center">
          {t('subscriptions.billingCycle.annual.switchSuccessBeatportSubtitle')}
        </Typography>
        <BeatportSalesBanner showDescription={false} />
        <Button
          type="primary"
          variant="filled"
          size="l"
          data-testid="switch-success-beatport-continue"
          trackEvent={mixpanel.track.bind(mixpanel)}
          onClick={() => {
            dispatch({ type: 'close' });
            navigate(paths.subscription);
          }}
        >
          {t('general.continue')}
        </Button>
      </Space>
    </Modal>
  );
};

export default SubscriptionCycleSwitchSuccess;
