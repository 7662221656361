import React, { useEffect, useState, useMemo } from 'react';
import Space from 'antd/es/space';
import { useTranslation } from 'react-i18next';
import Typography from 'component-library/src/typography/Typography';
import Button from 'component-library/src/button/Button';
import { spacing } from 'component-library/src/theme/theme';
import { useTermsConditions } from './hooks/useTermsConditions';
import { mixpanel } from 'tracking/tracking';
import termsConditionsService from './services/termsConditionsService';
import { useAuth } from 'hooks/useAuth';
import axios from 'axios';
import TermsConditionsIframe from './components/TermsConditionsIframe';
import { useLocation } from 'react-router-dom';
import { logging } from 'logging/logging';
import Modal from 'component-library/src/modal/Modal';

const TermsConditionsUpdated = (): JSX.Element => {
  const {
    t,
    i18n: { languages },
  } = useTranslation();
  const {
    user: { id: userId, isGemaMember },
  } = useAuth();
  const [modalOpen, setModalOpen] = useState(false);
  const { pathname } = useLocation();
  const { data: termsResponse, revalidate } = useTermsConditions();
  const termsSessionStoreVal = window.sessionStorage.getItem(
    'no-display-updated-terms'
  );
  const sessionStoreDisplayLogic =
    termsSessionStoreVal === null && pathname === '/';

  const mainUserAgreement =
    termsResponse !== undefined
      ? termsConditionsService.findLatestTerms(termsResponse)
      : undefined;

  const termsUrl = useMemo(
    () =>
      termsConditionsService.getTermsUrl(
        mainUserAgreement?.agreementLinks,
        languages[0],
        isGemaMember,
        'url'
      ),
    [isGemaMember, languages, mainUserAgreement?.agreementLinks]
  );

  useEffect(() => {
    if (
      termsResponse !== undefined &&
      Boolean(termsResponse.length) &&
      sessionStoreDisplayLogic &&
      mainUserAgreement
    ) {
      setModalOpen(true);
    }
  }, [termsResponse, mainUserAgreement, sessionStoreDisplayLogic]);

  const onClose = (): void => {
    window.sessionStorage.setItem('no-display-updated-terms', 'true');
    mixpanel.track({ event: 'TERMS_UPDATE_CLOSE' });
    setModalOpen(false);
  };

  const onAgree = async (): Promise<void> => {
    mixpanel.track({ event: 'TERMS_UPDATE_AGREE' });
    try {
      await termsConditionsService.postUserTermsAgreement(
        userId,
        mainUserAgreement?.id
      );

      revalidate();

      setModalOpen(false);
    } catch (e) {
      const error = e as Error;
      if (axios.isAxiosError(error)) {
        logging.error({
          productArea: 'misc',
          message: `ERROR: in user agreement updated terms - ${error.response?.data}`,
          messageContext: {
            userId: userId,
            agreementId: mainUserAgreement?.id,
          },
          error,
        });
      }
      logging.error({
        productArea: 'misc',
        message: `ERROR: in user agreement updated terms - ${error.message}`,
        messageContext: {
          userId: userId,
          agreementId: mainUserAgreement?.id,
        },
        error,
      });
    }
  };

  return (
    <Modal
      testId="terms-update"
      size="m"
      variant="functional"
      headerText={t('termsAndConditions.termsConditionsUpdateTitle')}
      headerSize="s"
      trackEvent={mixpanel.track.bind(mixpanel)}
      open={modalOpen}
      centered
      onCancel={onClose}
      footerButtons={[
        <Button
          type="secondary"
          variant="outline"
          size="m"
          onClick={() => mixpanel.track({ event: 'FULL_TERMS_UPDATE_READ' })}
          testId="full-terms-link"
          target="_blank"
          key="terms-link"
          rel="noopener noreferrer"
          href={termsUrl}
        >
          {t('termsAndConditions.termsConditionsUpdateCardBtn')}
        </Button>,
        <Button
          type="primary"
          size="m"
          key="terms-agree"
          onClick={onAgree}
          data-testid="terms-agree-btn"
          trackEvent={mixpanel.track.bind(mixpanel)}
        >
          {t('termsAndConditions.agreeContinueBtn')}
        </Button>,
      ]}
    >
      <Space
        className="w-100"
        data-testid="terms-conditions-modal"
        direction="vertical"
        size={spacing.space32}
      >
        <Space
          direction="vertical"
          size={spacing.space8}
          className="terms-frame-container w-100"
        >
          <Typography
            data-testid="terms-update-details-text"
            type="headline"
            size="xs"
            color="neutral9"
          >
            {t('termsAndConditions.termsConditionsUpdateReadAgree')}
          </Typography>

          <TermsConditionsIframe
            isGemaMember={isGemaMember}
            language={languages[0]}
            links={mainUserAgreement?.agreementLinks}
            fullWidth
            size="l"
          />
        </Space>
      </Space>
    </Modal>
  );
};

export default TermsConditionsUpdated;
