import axios, { Axios } from 'axios';
import { instance as mhApiInstance } from 'helper/api/api';
import {
  UserAgreementListRes,
  UserAgreementRes,
} from '../models/termsConditionsModels';
import dayjs from 'dayjs';
import {
  gemaTermsUrl,
  termsUrl,
  termsIframeGemaUrl,
  termsIframeNonGemaUrl,
} from 'helper/constants/constants';

export class TermsConditionsService {
  mhAuthInstance: Axios;
  mhUnAuthInstance: Axios;

  constructor(mhAuthInstance: Axios, mhUnAuthInstance: Axios) {
    this.mhAuthInstance = mhAuthInstance;
    this.mhUnAuthInstance = mhUnAuthInstance;
  }

  public async getUserTermsAgreement(
    userId: string
  ): Promise<UserAgreementListRes['openAgreements']> {
    const {
      data: { openAgreements },
    } = await this.mhAuthInstance.get<UserAgreementListRes>(
      `/users/${userId}/agreements`
    );

    return openAgreements;
  }

  public async postUserTermsAgreement(
    userId?: string,
    agreementId?: string
  ): Promise<void> {
    await this.mhAuthInstance.post(
      `/users/${userId}/agreements/${agreementId}`
    );
  }

  public async getAllAgreementsByUserType(
    isGemaMember: boolean
  ): Promise<UserAgreementRes[]> {
    const { data } = await this.mhUnAuthInstance.get<UserAgreementRes[]>(
      `/agreements?issuer=${isGemaMember ? 'gema' : 'firebase'}`
    );

    return data || [];
  }

  public async getLatestTermByType(
    isGemaMember: boolean,
    type: UserAgreementRes['type'] = 'TERMS'
  ): Promise<UserAgreementRes> {
    const agreements = await this.getAllAgreementsByUserType(isGemaMember);
    return this.findLatestTerms(agreements, type);
  }

  public findLatestTerms(
    agreements: UserAgreementRes[],
    type: UserAgreementRes['type'] = 'TERMS'
  ): UserAgreementRes {
    return agreements
      .filter((agreement) => agreement.type === type)
      .sort((a, b) =>
        dayjs(a.validFrom)
          .startOf('d')
          .isAfter(dayjs(b.validFrom).startOf('d'), 'd')
          ? -1
          : 1
      )[0];
  }

  public getTermsUrl(
    agreementLinks: UserAgreementRes['agreementLinks'],
    language: string,
    isGemaMember: boolean,
    termsType: 'iframeUrl' | 'url'
  ): string {
    const dynamicUrl = agreementLinks?.find((it) => it.locale === language);
    if (dynamicUrl) {
      return dynamicUrl[termsType];
    } else if (isGemaMember) {
      return termsType === 'iframeUrl'
        ? termsIframeGemaUrl[language]
        : gemaTermsUrl[language];
    }
    return termsType === 'iframeUrl'
      ? termsIframeNonGemaUrl[language]
      : termsUrl[language];
  }
}

const mhUnAuthInstance = axios.create({
  baseURL: process.env.REACT_APP_API_DOMAIN,
});

export default new TermsConditionsService(mhApiInstance, mhUnAuthInstance);
