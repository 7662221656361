import useSWR, { SWRResponse } from 'swr';
import { AxiosError, AxiosResponse } from 'axios';
import api from 'helper/api/api';
import { useCallback, useState } from 'react';
import { UserProfile, UserProfilePatchRequest } from 'models/Users';

export interface UseProfileReturn
  extends Pick<SWRResponse<UserProfile, AxiosError<Error>>, 'error'> {
  data: UserProfile | undefined;
  isLoading: boolean;
  mutate: (user: UserProfile) => Promise<void>;
  mutatePatch: (
    userProfilePatchRequest: UserProfilePatchRequest
  ) => Promise<void>;
  mutating: boolean;
}

export const trimVatId = (
  vatId?: string | null,
  taxResidency?: string | null
): string =>
  vatId && taxResidency
    ? vatId.replace(new RegExp(taxResidency, 'g'), '')
    : vatId || '';

export const useProfile = (): UseProfileReturn => {
  const [mutating, setMutating] = useState(false);
  const { data, error, mutate } = useSWR<UserProfile, AxiosError<Error>>(
    '/userProfile',
    () =>
      api.account
        .getUserProfile()
        .then((res: AxiosResponse<UserProfile>) => res.data),
    {
      revalidateOnFocus: false,
    }
  );

  const updateUserProfile = useCallback(
    async (userProfileData: UserProfile) => {
      setMutating(true);

      try {
        const updatedUserProfile = {
          ...((userProfileData as unknown) as UserProfile),
          taxResidency: userProfileData.country,
          iban: userProfileData.iban
            ? userProfileData.iban?.replace(/\s/g, '')
            : userProfileData.iban,
          bic: userProfileData.iban
            ? userProfileData.bic?.replace(/\s/g, '')
            : userProfileData.bic,
          vatId: userProfileData.vatId
            ? `${userProfileData.country}${trimVatId(
                userProfileData.vatId,
                userProfileData.country
              )}`
            : null,
        };
        const response = await api.account.setUserProfile(updatedUserProfile);
        await mutate(response.data, false);
        setMutating(false);
      } catch (e) {
        setMutating(false);
        throw e;
      }
    },
    [mutate]
  );

  const updateUserProfilePatch = useCallback(
    async (userProfilePatchRequest: UserProfilePatchRequest) => {
      setMutating(true);
      try {
        const response = await api.account.patchUserProfile(
          userProfilePatchRequest
        );
        await mutate(response.data, false);
        setMutating(false);
      } catch (e) {
        setMutating(false);
        throw e;
      }
    },
    [mutate]
  );

  return {
    data,
    isLoading: !data && !error,
    error: error,
    mutate: updateUserProfile,
    mutatePatch: updateUserProfilePatch,
    mutating,
  };
};
