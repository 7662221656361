/**
 * OnceExecutionPromiseManager<T> allows multiple listeners/resolutions to a single promise
 */
export class OnceExecutionPromiseManager<T = unknown> {
  private pending = false;
  private eventName: string;
  private event: Event;
  public returnValue: T | undefined;

  constructor(eventName: string) {
    this.eventName = eventName;
    this.event = new Event(eventName);
  }

  /**
   * Ensures that the provided promise function executes only once and any subsequent calls
   * wait for the result if the function is still pending.
   *
   * @param promiseFunction A function that returns a Promise of a specific type (T)
   * @returns Promise<T>
   */
  public once(promiseFunction: () => Promise<T>): Promise<T> {
    return new Promise((resolve, reject) => {
      if (!this.pending) {
        // If no pending execution, run the promise and dispatch the event on completion
        this.pending = true;
        promiseFunction()
          .then((result) => {
            this.pending = false;
            this.returnValue = result;
            document.dispatchEvent(this.event);
            resolve(result);
          })
          .catch((error) => {
            this.pending = false;
            reject(error);
          });
      } else {
        const listener = () => {
          document.removeEventListener(this.eventName, listener, false);
          resolve(this.returnValue as T);
        };
        document.addEventListener(this.eventName, listener);
      }
    });
  }
}
