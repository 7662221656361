import React from 'react';
import classnames from 'classnames';
import AntdTextArea, {
  TextAreaProps as AntdTextAreaProps,
  TextAreaRef,
} from 'antd/lib/input/TextArea';
import Typography from '../typography/Typography';
import { useTranslation } from 'react-i18next';

export interface InputTextAreaProps extends AntdTextAreaProps {
  size?: 'large';
  testId?: string;
}

const InputTextArea = React.forwardRef<TextAreaRef, InputTextAreaProps>(
  (
    { size = 'large', testId, maxLength, ...props }: InputTextAreaProps,
    ref
  ): JSX.Element => {
    const { t } = useTranslation();
    return (
      <div
        className={classnames(
          props.className,
          'w-100',
          'custom-input-text-area-container'
        )}
      >
        <AntdTextArea
          {...props}
          ref={ref}
          className={classnames(
            `custom-input-text-area`,
            `custom-input-text-area-${size}`
          )}
          size={size}
          data-testid={testId}
          maxLength={maxLength}
        />
        {maxLength && (
          <Typography
            type="body"
            size="xs"
            color="neutral7"
            customClass="max-length-text"
          >
            {t('general.maxCharLimit', {
              maxLength,
            })}
          </Typography>
        )}
      </div>
    );
  }
);

export default InputTextArea;
