import flags from 'config/flags';
import useSWR, { cache } from 'swr';
import featureFlagService, {
  FeatureFlagCheck,
} from 'services/featureFlagService';

export interface UseFeatureFlagCheck {
  loading: boolean;
  value: boolean;
}

export const useFeatureFlagCheck = (
  flagName?: keyof typeof flags
): UseFeatureFlagCheck => {
  if (!flagName) return { loading: false, value: true };

  const cacheKey = `/featureFlags/check/${flagName}`;
  const { data, isValidating } = useSWR<FeatureFlagCheck>(
    cacheKey,
    async () => {
      if (cache.has(cacheKey)) {
        return cache.get(cacheKey);
      }

      return await featureFlagService.checkFeatureFlag(flagName);
    },
    {
      revalidateOnFocus: false,
    }
  );

  return {
    loading: isValidating,
    value: !!data?.isOn,
  };
};
