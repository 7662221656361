import React, { ReactNode } from 'react';
import {
  CheckCircleFilled,
  CloseCircleFilled,
  ExclamationCircleFilled,
  InfoCircleFilled,
} from '@ant-design/icons';
import { Alert } from 'antd';
import { AlertProps } from 'antd/lib/alert';
import { BulbTwoTonePrimary } from 'assets/img';
import classNames from 'classnames';
import Button, {
  ButtonType,
  ButtonProps,
} from 'component-library/src/button/Button';
import Typography, {
  CustomTypographyStyleProps,
} from 'component-library/src/typography/Typography';
import theme, { Theme } from 'component-library/src/theme/theme';
import { mixpanel } from 'tracking/tracking';

export type AlertType = NonNullable<AlertProps['type'] | 'tip' | 'accent'>;
type AlertSize = 'regular' | 'small';

interface ButtonDefault {
  text: string;
  testId?: string;
  icon?: React.ReactNode;
  iconAlign?: 'l' | 'r';
}
export interface ButtonWithClickHandler extends ButtonDefault {
  onClick: () => void;
  size?: 's' | 'm' | 'l';
  variant?: ButtonProps['variant'];
}
interface ButtonWithHref extends ButtonDefault {
  href: string;
}

export interface SectionAlertProps {
  customClassName?: string;
  description?: React.ReactNode;
  message?: React.ReactNode;
  size?: AlertSize;
  testId?: string;
  type?: AlertType;
  actionButton?: ButtonWithClickHandler;
  linkButton?: ButtonWithClickHandler | ButtonWithHref;
  iconVerticalAlignment?: 'center' | 'flex-start';
  buttonVerticalAlignment?: 'center' | 'flex-start';
  closable?: AlertProps['closable'];
  banner?: AlertProps['banner'];
  closeIcon?: AlertProps['closeIcon'];
}
const mapTypeToButtonType: { [key in AlertType]: ButtonType } = {
  info: 'info',
  success: 'success',
  error: 'danger',
  warning: 'warning',
  tip: 'primary',
  accent: 'accent',
};
const mapTypeToButtonVariant: {
  [key in AlertType]: ButtonProps['variant'];
} = {
  info: 'outline',
  success: 'outline',
  error: 'filled',
  warning: 'outline',
  tip: 'filled',
  accent: 'filled',
};
const mapTypeToTextColor: {
  [key in AlertType]: {
    title: keyof Theme['colors'];
    description: keyof Theme['colors'];
  };
} = {
  info: {
    title: 'neutral9',
    description: 'neutral8',
  },
  success: {
    title: 'neutral9',
    description: 'neutral8',
  },
  error: {
    title: 'neutral9',
    description: 'neutral8',
  },
  warning: {
    title: 'neutral9',
    description: 'neutral8',
  },
  tip: {
    title: 'neutral9',
    description: 'neutral8',
  },
  accent: {
    title: 'neutral1',
    description: 'neutral1',
  },
};
const mapSizeToMessageTypo: {
  [key in AlertSize]: CustomTypographyStyleProps;
} = {
  regular: {
    type: 'body',
    size: 'm',
  },
  small: {
    type: 'body',
    size: 's',
  },
};
const mapSizeToDescriptionTypo: {
  [key in AlertSize]: CustomTypographyStyleProps;
} = {
  regular: {
    type: 'body',
    size: 's',
  },
  small: {
    type: 'body',
    size: 'xs',
  },
};
const mapSizeToLinkButtonProps: {
  [key in AlertSize]: ButtonProps['size'];
} = {
  regular: 'm',
  small: 's',
};

const SectionAlert: React.FC<React.PropsWithChildren<SectionAlertProps>> = ({
  message,
  description,
  customClassName = '',
  size = 'regular',
  type = 'info',
  linkButton,
  actionButton,
  testId,
  iconVerticalAlignment = 'flex-start',
  buttonVerticalAlignment = 'center',
  closable,
  closeIcon,
  banner,
}) => {
  const alertType = type === 'tip' || type === 'accent' ? 'info' : type;
  const hasActionButton = !!actionButton;

  const mapTypeToIcon: {
    [key in AlertType]: ReactNode;
  } = {
    info: <InfoCircleFilled />,
    success: <CheckCircleFilled />,
    warning: <ExclamationCircleFilled />,
    error: <CloseCircleFilled style={{ color: theme.colors.danger4 }} />,
    tip: <BulbTwoTonePrimary />,
    accent: <></>,
  };

  return (
    <Alert
      className={classNames(
        'section-alert',
        `section-alert-${type}`,
        `section-alert-${size}`,
        `section-alert-icon-align-${iconVerticalAlignment}`,
        `section-alert-button-align-${buttonVerticalAlignment}`,
        {
          'section-alert-with-button': hasActionButton,
        },
        customClassName
      )}
      data-testid={testId}
      showIcon
      icon={<div className="section-alert-icon">{mapTypeToIcon[type]}</div>}
      type={alertType}
      banner={banner}
      closable={closable}
      closeIcon={
        closeIcon && <div className="section-alert-close-msg">{closeIcon}</div>
      }
      message={
        message && (
          <Typography
            {...mapSizeToMessageTypo[size]}
            color={mapTypeToTextColor[type]['title']}
            weightLevel="500"
            block
          >
            {message}
          </Typography>
        )
      }
      description={
        <div className="alert-description-container">
          {description && (
            <Typography
              {...mapSizeToDescriptionTypo[size]}
              customClass={classNames({ 'mt-1': !!message })}
              color={mapTypeToTextColor[type]['description']}
              weightLevel="400"
            >
              {description}
            </Typography>
          )}
          {!!linkButton && (
            <Button
              className={classNames({ 'mt-1': size === 'regular' })}
              onClick={
                'onClick' in linkButton ? linkButton?.onClick : undefined
              }
              data-testid={
                'onClick' in linkButton ? linkButton.testId : undefined
              }
              type="secondary"
              variant="link"
              size={mapSizeToLinkButtonProps[size]}
              trackEvent={mixpanel.track.bind(mixpanel)}
            >
              {'onClick' in linkButton ? (
                linkButton.text
              ) : (
                <a
                  data-testid={linkButton?.testId}
                  href={linkButton?.href}
                  target="_blank"
                  rel="noopener noreferrer"
                  title={linkButton?.text}
                >
                  {linkButton?.text}
                </a>
              )}
            </Button>
          )}
        </div>
      }
      action={
        hasActionButton && (
          <Button
            data-testid={actionButton?.testId}
            className={`ant-btn-${type}`}
            variant={actionButton?.variant || mapTypeToButtonVariant[type]}
            size={actionButton?.size || 's'}
            type={mapTypeToButtonType[type]}
            onClick={actionButton?.onClick}
            icon={actionButton?.icon}
            iconAlign={actionButton?.iconAlign}
            trackEvent={mixpanel.track.bind(mixpanel)}
          >
            {actionButton?.text}
          </Button>
        )
      }
    />
  );
};

export default SectionAlert;
