type GemaErrorCodes =
  | 'NOT_AUTHORISED'
  | 'NOT_CONTENT_CREATOR'
  | 'NOT_USING_GEMA_ISSUER'
  | 'NOT_REGISTERED_WITH_MUSIC_HUB'
  | 'TOKEN_EXCHANGE_ERROR';
export class GemaLoginError extends Error {
  code: GemaErrorCodes;

  constructor(message: string, code: GemaErrorCodes) {
    super(message);
    this.message = message;
    this.code = code;
  }
}
