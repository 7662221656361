import firebase from 'firebase/app';
import 'firebase/auth';
import api from 'helper/api/api';
import { auth } from 'firebaseui';
import { mixpanel } from 'tracking/tracking';
import { GemaLoginError } from 'errors/authenticationErrors';
import { UpdatePasswordError } from 'errors/updatePasswordErrors';
import musicAnalyticsAuthentication from './musicAnalyticsAuthentication';
import { paths } from 'config/routes';
import { AxiosError } from 'axios';
import { FirebaseError } from 'firebase-admin/app';
import OktaAuth, {
  OktaAuthOptions,
  IDToken,
  UserClaims,
} from '@okta/okta-auth-js';
import algoliaService from 'services/algoliaService';
import { logging } from 'logging/logging';
import cookies from './cookies';

const config = {
  apiKey: process.env.REACT_APP_IDENTITY_PLATFORM_API_KEY,
  authDomain: process.env.REACT_APP_IDENTITY_PLATFORM_AUTH_DOMAIN,
};

// ALLOW WINDOW PROCESS TO OVERRIDE MAIN PROCESS FOR E2E TESTING
const env = { ...process.env, ...window.process?.env };
const oktaConfigLogin: OktaAuthOptions = {
  issuer: `https://${env.REACT_APP_OKTA_DOMAIN}/oauth2/default`,
  clientId: env.REACT_APP_OKTA_CLIENT_ID,
  redirectUri: env.REACT_APP_OKTA_REDIRECT_URI,
  postLogoutRedirectUri: env.REACT_APP_OKTA_LOGOUT_REDIRECT_URI,
  tokenManager: {
    autoRenew: true,
  },
  services: {
    autoRenew: true,
  },
};

const oktaAuth = new OktaAuth(oktaConfigLogin);

export type IssuerType = 'firebase' | 'gemaCiam';

export interface MusicHubAuthUser {
  issuerId?: string | null;
  issuer?: IssuerType;
  email?: string | null;
  emailVerified?: boolean | null;
  displayName?: string | null;
}

export interface MusicHubCrossOriginAuthUser {
  isLoggedIn: boolean;
  userId?: string;
  firstName?: string | null;
  lastName?: string | null;
  accessToken?: string;
}

type CustomOktaGemaUserClaims = UserClaims & {
  userid: string;
  roles: string[];
};

export class MusicHubAth {
  issuer?: IssuerType = 'firebase';
  _user: MusicHubAuthUser | null = null;
  authStateCallbacks: ((user: MusicHubAuthUser | null) => void)[] = [];
  unsubscribe?: firebase.Unsubscribe;
  config: auth.Config = {
    signInOptions: [
      {
        provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
        disableSignUp: {
          status: true,
          adminEmail: 'support@music-hub.com',
          helpLink: `https://intercom.help/music-hub/de/articles/5178970-deine-verknupften-gema-und-musichub-login-daten`,
        },
      },
    ],
    credentialHelper: auth.CredentialHelper.NONE,
    callbacks: {
      signInSuccessWithAuthResult: (_: unknown, oktaRedirectUrl?: string) => {
        mixpanel.track({
          event: 'USER_LOGIN',
          issuer: this.issuer,
        });

        const redirectTo = this.getSuccessUrl(oktaRedirectUrl);
        localStorage.removeItem('release-nav-prompt');
        localStorage.removeItem('okta-redirect-pathname');
        location.href = redirectTo;
        // Return false to let firebase know that we will handle our own redirect
        // Cypress tests will fail if the redirect happens on the window.top due to test runner in an iframe
        return false;
      },
    },
  };
  firebaseCredential = firebase.auth.EmailAuthProvider.credential;

  constructor() {
    this.init();
  }

  get user(): MusicHubAuthUser | null {
    return this._user;
  }

  get oktaAuthClient(): OktaAuth | undefined {
    return this.issuer === 'gemaCiam' ? oktaAuth : undefined;
  }

  get externalRedirectUrlStorageKey(): string {
    return 'external-redirect-url';
  }

  get crossOriginAuthUserCacheKey(): string {
    return 'cross-origin-auth-user-cache-key';
  }

  /**
   * Initalises authentication - defaults to firebase issuer if no gema token is stored
   */
  private async init() {
    // Prevent error for duplicate DEFAULT firebase app
    if (firebase.apps.length === 0) {
      firebase.initializeApp(config);
    }

    const gemaOktaUser = await this.isGemaOktaUser();
    if (gemaOktaUser) {
      this.setIssuer('gemaCiam');
      oktaAuth.authStateManager.subscribe(this.setUser.bind(this));
      oktaAuth.authStateManager.updateAuthState();
    } else {
      this.setIssuer('firebase');
    }

    this.unsubscribe = firebase
      .auth()
      .onAuthStateChanged(this.setFirebaseUser.bind(this));
  }

  // getSuccessUrl attempts to return a user to the page they previously if using unauthed
  // Firebase users who have attempted to visit a logged in route are redirect to /login?redirectUrl=/stats
  // This is then used here to form the path to take users to on successful authentication
  // OKTA users rely on a redirect from OKTA so any paths/qs in urls are lost, so for these we store in localstorage in Login and ensure to use in that scenario
  public getSuccessUrl(oktaRedirectUrl?: string): string {
    try {
      const initialUrl = new URL(
        oktaRedirectUrl
          ? `${window.location.origin}${oktaRedirectUrl}`
          : window.location.href
      );
      const queryStringParams = initialUrl.searchParams;
      const baseUrl = oktaRedirectUrl
        ? initialUrl.pathname
        : queryStringParams.get('redirectUrl') ?? '/';
      queryStringParams.delete('redirectUrl');
      queryStringParams.delete('oktaToken');
      queryStringParams.set('loginRedirect', 'true');
      queryStringParams.set('issuer', `${this.issuer}`);
      return `${baseUrl}?${queryStringParams.toString()}`;
    } catch (e) {
      return `/?loginRedirect=true&issuer=${this.issuer}`;
    }
  }

  /* Persists a URL to local storage so that after successful register and login, the user is taken to that URL */
  /* URL needs to be a full URL with http|https or it will not persist */
  setExternalRedirectUrl(url: string | null) {
    if (url && (url.startsWith('http://') || url.startsWith('https://'))) {
      localStorage.setItem(this.externalRedirectUrlStorageKey, url);
    }
  }

  getExternalRedirectUrl(): string | null {
    return localStorage.getItem(this.externalRedirectUrlStorageKey);
  }

  removeExternalRedirectUrl() {
    localStorage.removeItem(this.externalRedirectUrlStorageKey);
  }

  /* create a cache of user for use cross domain via secure iframe - this is a backup for currentUser */
  setCrossOriginUserCache(crossOriginUserCache: MusicHubCrossOriginAuthUser) {
    try {
      const backup = JSON.stringify(crossOriginUserCache);
      const expirationDate = new Date();
      expirationDate.setTime(expirationDate.getTime() + 1 * 60 * 60 * 1000); // 1 hour
      const currentDomain = window.location.hostname;
      cookies.setCookie(
        this.crossOriginAuthUserCacheKey,
        backup,
        expirationDate,
        '/',
        currentDomain
      );
    } catch {}
  }

  getCrossOriginUserCache(): MusicHubCrossOriginAuthUser | null {
    try {
      const cookieValue = cookies.getCookie(this.crossOriginAuthUserCacheKey);
      return cookieValue
        ? (JSON.parse(cookieValue) as MusicHubCrossOriginAuthUser)
        : null;
    } catch (e) {
      return null;
    }
  }

  removeCrossOriginUserCache() {
    try {
      const currentDomain = window.location.hostname;
      cookies.deleteCookie(
        this.crossOriginAuthUserCacheKey,
        '/',
        currentDomain
      );
    } catch (e) {}
  }

  /**
   * shouldRedirectUserToEmailVerification - We should only redirect firebase users to verify email address if they are on private routes
   */
  shouldRedirectUserToEmailVerification(user: firebase.User | null): boolean {
    return (
      user?.emailVerified === false &&
      ![paths.login, paths.register, paths.emailVerification].some((path) =>
        window.location.pathname.includes(path)
      )
    );
  }

  /**
   * emitAuthStateChanged - notify all listeners a change to signed in user has occured
   */
  public emitAuthStateChanged(): void {
    this.authStateCallbacks.map((callback) => callback(this.user));
  }

  /**
   * setIssuer - set issuer of token, used to branch between authenticators
   */
  public setIssuer(issuer: IssuerType): this {
    this.issuer = issuer;
    return this;
  }

  /**
   * destroy - clear listeners of callbacks for firebase tokens
   */
  public destroy(): void {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }

  /**
   * onAuthStateChanged - abstracts the same behaviour as firebase to create auth state change listeners
   * @param {function} callback
   */
  public onAuthStateChanged(
    callback: (user: MusicHubAuthUser | null) => void
  ): void {
    this.authStateCallbacks.push(callback);
  }

  /**
   * signOut - sign user out and notify listeners
   */
  public async signOut(): Promise<void> {
    this._user = null;
    musicAnalyticsAuthentication.signOut();
    algoliaService.deleteAlgoliaKey();
    this.removeCrossOriginUserCache();

    try {
      try {
        await this.oktaAuthClient?.signOut({
          revokeAccessToken: true,
        });
      } catch (e) {
        const error = e as Error;
        logging.error({
          productArea: 'auth',
          message: `Error revoking user token from okta - ${error.message}`,
          messageContext: {
            errorMessage: error.message,
          },
          error,
        });
      }
      localStorage.removeItem('okta-cache-storage');
      localStorage.removeItem('okta-token-storage');
    } catch (e) {}

    if (this.issuer === 'firebase') {
      // Firebase listener bound in init will notify other listeners
      await firebase.auth().signOut();
      this.destroy();
    } else {
      await firebase.auth().signOut();
      this.emitAuthStateChanged();
    }
  }

  /**
   * setUser - fetches the required music hub user info and formats to a common MusicHubUser
   */
  async setUser(): Promise<void> {
    const musicHubUser: MusicHubAuthUser = {
      issuer: this.issuer,
      emailVerified: false,
    };

    const firebaseUser = this.getFirebaseAuthUser();
    const gemaUser = await this.getOktaAuthUser();

    if (!firebaseUser && !gemaUser) {
      return;
    }

    if (gemaUser) {
      // Gema signin and gema user - takes email verification from firebase if it exists
      this._user = {
        ...musicHubUser,
        issuerId: gemaUser.userid as string,
        displayName: `${gemaUser.given_name} ${gemaUser.family_name}`,
        email: gemaUser.email as string,
        emailVerified: true,
      };
    } else {
      // Defaults to firebase
      const {
        email,
        uid,
        displayName,
        emailVerified,
      } = firebaseUser as firebase.User;
      this._user = {
        ...musicHubUser,
        issuerId: uid,
        displayName,
        email,
        emailVerified,
      };
    }
    // Sets global window variable for GTM enhanced conversions
    window.MUSICHUB_USER_EMAIL = this.user?.email as string;
    this.emitAuthStateChanged();
  }

  /* Firebase methods */

  public getFirebaseAuthUser(): firebase.User | null {
    return firebase.auth().currentUser;
  }

  public firebaseSendPasswordResetEmailMethod(email: string): Promise<void> {
    return firebase.auth().sendPasswordResetEmail(email);
  }

  async setFirebaseUser(user: firebase.User | null): Promise<void> {
    if (user) {
      if (this.shouldRedirectUserToEmailVerification(user)) {
        window.location.href = paths.emailVerification;
        return;
      }
      await user.getIdToken();
      await this.setUser();
    } else {
      this.emitAuthStateChanged();
    }
  }

  /**
   * signinWithFirebaseCredentials - sign in user with email and password via firebase - being used for auto login after register
   * @param {string} email
   * @param {string} password
   */
  public async signinWithFirebaseCredentials(
    email: string,
    password: string
  ): Promise<void> {
    const user = await firebase
      .auth()
      .signInWithEmailAndPassword(email, password);
    await firebase.auth().updateCurrentUser(user.user);
  }

  public async signinWithCustomFirebaseToken(
    customFirebaseToken: string
  ): Promise<void> {
    try {
      const user = await firebase
        .auth()
        .signInWithCustomToken(customFirebaseToken);
      await firebase.auth().updateCurrentUser(user.user);
      await user.user?.getIdToken();
    } catch (e) {
      const error = e as FirebaseError;
      logging.error({
        productArea: 'auth',
        message: 'Error signing in firebase user with custom token',
        messageContext: {
          error: error,
          errorMessage: error.message,
          code: error.code,
        },
        error: e as Error,
      });
      throw e;
    }
  }

  /**
   * signIn - sign in user with email and password
   * @param {string} email
   * @param {string} password
   */
  public async signIn(email: string, password: string): Promise<void> {
    try {
      if (this.issuer === 'firebase') {
        await this.signinWithFirebaseCredentials(email, password);
        // Returning here without the setting of ID and config callbacks as all subsequent logic will be handled via firebase.auth().onAuthStateChanged ()
        return;
      }
    } catch (e) {
      throw new GemaLoginError('Authentication error', 'NOT_AUTHORISED');
    }
  }

  public async getFirebaseIdToken(): Promise<string | undefined> {
    const firebaseToken = await firebase.auth().currentUser?.getIdToken();
    return firebaseToken;
  }

  public async sendEmailConfirmation(emailLanguage: string): Promise<void> {
    firebase.auth().languageCode = emailLanguage;
    await firebase.auth().currentUser?.sendEmailVerification();
  }

  public async reauthenticateUser(password: string): Promise<void> {
    const user = this.getFirebaseAuthUser();
    const userEmail = user?.email;
    if (userEmail) {
      const credential = this.firebaseCredential(userEmail, password);
      try {
        await user?.reauthenticateWithCredential(credential);
      } catch (e) {
        const { code } = e as FirebaseError;
        if (code === 'auth/too-many-requests') {
          throw new UpdatePasswordError(
            'Too many attempts. Try again later.',
            'TOO_MANY_REQUESTS'
          );
        }
        throw new UpdatePasswordError('Incorrect password', 'WRONG_PASSWORD');
      }
    } else {
      throw new UpdatePasswordError('Something went wrong', 'UNKNOWN_ERROR');
    }
  }

  public async updatePassword(
    currentPassword: string,
    newPassword: string
  ): Promise<void> {
    try {
      await this.reauthenticateUser(currentPassword);
      try {
        await this.getFirebaseAuthUser()?.updatePassword(newPassword);
      } catch (e) {
        throw new UpdatePasswordError('Weak password', 'WEAK_PASSWORD');
      }
    } catch (e) {
      if (e instanceof UpdatePasswordError) {
        throw e;
      }
      throw new UpdatePasswordError('Something went wrong', 'UNKNOWN_ERROR');
    }
  }

  public async sendResetPasswordMail(): Promise<void> {
    const user = this.getFirebaseAuthUser();
    const userEmail = user?.email;
    if (userEmail) {
      try {
        await this.firebaseSendPasswordResetEmailMethod(userEmail);
      } catch (e) {
        const { code } = e as FirebaseError;
        if (code === 'auth/too-many-requests') {
          throw new UpdatePasswordError(
            'Too many attempts. Try again later.',
            'TOO_MANY_REQUESTS'
          );
        }
        throw new UpdatePasswordError('Something went wrong', 'UNKNOWN_ERROR');
      }
    } else {
      throw new UpdatePasswordError('Something went wrong', 'UNKNOWN_ERROR');
    }
  }

  /* GEMA OKTA Methods */

  /**
   * Exchange the OKTA Token from oktaAuthClient?.getIdToken() to Firebase one
   * Push user to register flow gemaCiam issuer if exchange fails
   * @param ciamNonceVal nonce string parsed from within given OKTA token
   * @returns Promise void
   */
  async exchangeGemaCiamTokenToFirebase(
    ciamNonceVal?: string
  ): Promise<boolean> {
    try {
      const { idToken: gemaOktaIdToken } =
        (await this.oktaAuthClient?.tokenManager.getTokens()) ?? {};

      if (!gemaOktaIdToken?.idToken) {
        throw new Error('No token exists');
      }

      this.checkOktaIdTokenForRoles(gemaOktaIdToken);

      const customToken = await api.account.getCustomToken(
        gemaOktaIdToken.idToken,
        'gemaCiam',
        ciamNonceVal
      );
      await this.signinWithCustomFirebaseToken(customToken.data);

      return Promise.resolve(true);
    } catch (err) {
      if (err instanceof AxiosError) {
        if (
          err.response?.status === 404 ||
          err.response?.data.message === 'No user found for the gema token'
        ) {
          return Promise.resolve(false);
        }
      }
      this.signOut();
      throw new GemaLoginError(
        'Cannot exchange GEMA token to Firebase token',
        'TOKEN_EXCHANGE_ERROR'
      );
    }
  }

  public async signInWithOkta(): Promise<void> {
    if (this.issuer === 'gemaCiam') {
      try {
        oktaAuth.start();
        await oktaAuth.signInWithRedirect();
      } catch (e) {
        const error = e as Error;
        logging.error({
          productArea: 'auth',
          message: error.message,
          error,
        });
      }
      return;
    }
  }

  public async getGemaOktaAccessToken(): Promise<string | undefined> {
    if (!this.oktaAuthClient) {
      return undefined;
    }
    try {
      const isAuthenticated = await this.isGemaOktaUserAuthenticated();
      const accessToken = this.oktaAuthClient.getAccessToken();

      logging.info({
        productArea: 'auth',
        message: `Get access token for gema user email ${this.user?.email}`,
        messageContext: {
          isAuthenticated: isAuthenticated,
          hasOktaAccessToken: Boolean(accessToken),
        },
      });

      return accessToken;
    } catch (e) {
      const error = e as Error;
      logging.error({
        productArea: 'auth',
        message: `Okta error occurred in getGemaOktaAccessToken`,
        messageContext: {
          errorMessage: error.message,
          error: error,
        },
        error,
      });
      return undefined;
    }
  }

  public checkOktaIdTokenForRoles(idToken?: IDToken) {
    const GEMA_OKTA_ALLOWED_ROLES = ['ciamgema_urheber', 'ciamgema_publisher'];

    // TODO: This is a workaround for using our dev okta as we can't set the roles in the claim as we expect them from GEMA okta
    const isGemaOktaIdToken = Boolean(idToken?.claims.roles);
    const isMissingGemaOktaRole =
      isGemaOktaIdToken &&
      !(idToken?.claims.roles as string[]).some((it) =>
        GEMA_OKTA_ALLOWED_ROLES.includes(it)
      );

    if (isMissingGemaOktaRole) {
      logging.error({
        productArea: 'auth',
        message: `Okta ID token not allowed to login/register ${
          this.user?.email
        } as roles ${JSON.stringify(
          idToken?.claims.roles
        )} do not contain ${GEMA_OKTA_ALLOWED_ROLES.join(' or ')}`,
      });
      throw new Error(
        `Failed check for role ${GEMA_OKTA_ALLOWED_ROLES.join(' or ')}`
      );
    }
  }

  public async isGemaOktaUserAuthenticated(): Promise<boolean> {
    return await oktaAuth?.isAuthenticated({
      onExpiredToken: 'renew',
    });
  }

  public async isGemaOktaUser(): Promise<boolean> {
    const isOktaAuthenticated = await this.isGemaOktaUserAuthenticated();
    const hasOktaToken = Boolean(oktaAuth?.getIdToken());
    const hasExpiredToken = Boolean(localStorage.getItem('okta-cache-storage'));
    return isOktaAuthenticated || hasOktaToken || hasExpiredToken || false;
  }

  public async getOktaAuthUser(): Promise<
    CustomOktaGemaUserClaims | undefined
  > {
    try {
      if (await this.isGemaOktaUser()) {
        return (await this.oktaAuthClient?.getUser()) as CustomOktaGemaUserClaims;
      }
      return undefined;
    } catch (e) {
      return undefined;
    }
  }

  public emitSuccessfulGEMAAuthentication(redirectUrl?: string): void {
    if (this.config?.callbacks?.signInSuccessWithAuthResult) {
      this.config?.callbacks?.signInSuccessWithAuthResult({}, redirectUrl);
    }
  }
}

// Export instantiated to maintain state across sessions
export default new MusicHubAth();
