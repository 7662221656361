import React from 'react';
import { TrackEventHandler } from '../types';
import ConfirmModal from './ConfirmModal';
import modalConfirmFn from 'antd/lib/modal/confirm';

export interface ConfirmDeleteModalProps {
  onCancel: () => void;
  onDelete: () => void;
  heading: string;
  body?: string;
  cancelButtonText: string;
  deleteButtonText: string;
  deleteInProgress?: boolean;
  trackEvent?: TrackEventHandler;
  size?: 's' | 'm';
  testId?: string;
}

const ConfirmDeleteModal: React.FC<React.PropsWithChildren<
  ConfirmDeleteModalProps
>> = ({
  deleteInProgress = false,
  onCancel,
  onDelete,
  heading,
  body,
  cancelButtonText,
  deleteButtonText,
  trackEvent,
  size = 'm',
  testId,
}: ConfirmDeleteModalProps) => {
  return (
    <ConfirmModal
      okayInProgress={deleteInProgress}
      onCancel={onCancel}
      onOkay={onDelete}
      heading={heading}
      body={body}
      okayButtonText={deleteButtonText}
      cancelButtonText={cancelButtonText}
      trackEvent={trackEvent}
      size={size}
      variant="delete"
      testId={testId}
    />
  );
};

export const confirmDeleteModalPromise = (
  modalProps: Omit<ConfirmDeleteModalProps, 'onCancel' | 'onDelete'>
) =>
  new Promise((resolve, reject) => {
    const modal = modalConfirmFn({
      modalRender() {
        return (
          <ConfirmDeleteModal
            {...modalProps}
            onCancel={() => {
              modal.destroy();
              reject(undefined);
            }}
            onDelete={() => {
              modal.destroy();
              resolve(undefined);
            }}
          />
        );
      },
    });
    return modal;
  });

export default ConfirmDeleteModal;
