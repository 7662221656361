class Cookies {
  getCookie(name: string): string | null {
    const cookies = document.cookie.split(';');
    for (let cookie of cookies) {
      cookie = cookie.trim();
      if (cookie.startsWith(`${name}=`)) {
        return cookie.substring(name.length + 1);
      }
    }
    return null;
  }

  setCookie(
    name: string,
    value: string,
    expires: Date,
    path = '/',
    domain?: string
  ) {
    let cookieString = `${name}=${value}; expires=${expires.toUTCString()}; path=${path};`;
    if (domain) {
      cookieString += ` domain=${domain};`;
    }
    document.cookie = cookieString;
  }

  deleteCookie(name: string, path = '/', domain?: string) {
    let cookieString = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=${path};`;
    if (domain) {
      cookieString += ` domain=${domain};`;
    }
    document.cookie = cookieString;
  }
}

export default new Cookies();
