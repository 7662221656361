import React, { ReactNode, ReactElement } from 'react';
import classnames from 'classnames';
import { Col, Row } from 'antd';

interface ContainerProps {
  children: ReactNode;
  className?: string;
  dark?: boolean;
  withPadding?: boolean;
  testId?: string;
}

const Container = ({
  children,
  className,
  dark,
  withPadding,
  testId,
}: ContainerProps): ReactElement => (
  <Row
    justify="center"
    className={classnames(className, {
      container: true,
      withPadding: withPadding,
    })}
    data-theme={dark ? 'dark' : undefined}
    data-testid={testId}
  >
    <Col xs={24} sm={24} md={22} lg={22} xl={22} xxl={20}>
      {children}
    </Col>
  </Row>
);

Container.defaultProps = {
  dark: false,
  withPadding: false,
};

export default Container;
