import React from 'react';
import { Space } from 'antd';
import { spacing } from 'component-library/src/theme/theme';
import { BeatportIconMain } from 'features/dsps/assets/dsp-icons-main';
import Typography from 'component-library/src/typography/Typography';
import List from 'component-library/src/list/List';
import { CheckOutlined } from '@ant-design/icons';
import theme from 'component-library/src/theme/theme';
import { useTranslation } from 'react-i18next';

interface BeatportSalesBannerProps {
  showDescription?: boolean;
}

const BeatportSalesBanner: React.FC<React.PropsWithChildren<
  BeatportSalesBannerProps
>> = ({ showDescription = true }) => {
  const { t } = useTranslation();

  return (
    <div className="beatport-sales-banner" data-testid="beatport-sales-banner">
      <Space direction="vertical" size={spacing.space16} className="w-100">
        <div className="flex flex-align-c">
          <BeatportIconMain />
          <div className="flex-end flex-grow">
            <Typography type="body" size="m" color="white">
              {t('subscriptions.beatportAddOn.priceYear')}
            </Typography>
          </div>
        </div>
        {showDescription && (
          <Typography type="body" size="m" color="white">
            {t('subscriptions.beatportPriceModal.description')}
          </Typography>
        )}
        <List
          fontColor="white"
          listItems={[
            t('subscriptions.beatportPriceModal.benefit1'),
            t('subscriptions.beatportPriceModal.benefit2'),
            t('subscriptions.beatportPriceModal.benefit3'),
          ]}
          listIcon={<CheckOutlined style={{ color: theme.colors.primary3 }} />}
        />
      </Space>
    </div>
  );
};

export default BeatportSalesBanner;
