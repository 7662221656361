import React, { useMemo } from 'react';
import { HowItWorks1, HowItWorks2, HowItWorks3 } from 'assets/img';
import { useTranslation, Trans } from 'react-i18next';
import { TFunction } from 'i18next';
import Steps from 'component-library/src/modal/modal-content/steps/Steps';
import { RELEASE_PLAN_LINK } from 'features/landing-page/constants';
import { mixpanel } from 'tracking/tracking';

const highlightFormatText = (key: string, lngCode = 'de'): React.ReactNode => (
  <Trans i18nKey={key}>
    <span className="font-bold" />
    <a
      className="link-in-text"
      href={RELEASE_PLAN_LINK[lngCode]}
      title="Release Plan"
      target="_blank"
      rel="noopener noreferrer"
    />
  </Trans>
);

const getStepsColumns = (t: TFunction, lngCode = 'de') => [
  {
    title: t('subscriptions.pricingModal.step1.heading'),
    icon: <HowItWorks1 title={t('subscriptions.pricingModal.step1.heading')} />,
    listItems: [
      highlightFormatText('subscriptions.pricingModal.step1.item1'),
      highlightFormatText('subscriptions.pricingModal.step1.item5'),
      highlightFormatText('subscriptions.pricingModal.step1.item2'),
      highlightFormatText('subscriptions.pricingModal.step1.item3'),
      highlightFormatText('subscriptions.pricingModal.step1.item4'),
    ],
  },
  {
    title: t('subscriptions.pricingModal.step2.heading'),
    icon: <HowItWorks2 title={t('subscriptions.pricingModal.step2.heading')} />,
    listItems: [
      highlightFormatText('subscriptions.pricingModal.step2.item1'),
      highlightFormatText('subscriptions.pricingModal.step2.item2'),
      highlightFormatText('subscriptions.pricingModal.step2.item3'),
      highlightFormatText('subscriptions.pricingModal.step2.item4'),
    ],
  },
  {
    title: t('subscriptions.pricingModal.step3.heading'),
    icon: <HowItWorks3 />,
    listItems: [
      highlightFormatText('subscriptions.pricingModal.step3.item1'),
      highlightFormatText('subscriptions.pricingModal.step3.item2'),
      highlightFormatText('subscriptions.pricingModal.step3.item3'),
      highlightFormatText('subscriptions.pricingModal.step3.item4', lngCode),
    ],
  },
];

const SubscriptionPoints: React.FC<React.PropsWithChildren<unknown>> = () => {
  const {
    t,
    i18n: { languages },
  } = useTranslation();
  const stepsColumns = useMemo(() => getStepsColumns(t, languages[0]), [
    t,
    languages,
  ]);

  return (
    <Steps
      variant="prompt"
      steps={stepsColumns}
      trackEvent={mixpanel.track.bind(mixpanel)}
    />
  );
};

export default SubscriptionPoints;
