import dayjs, { Dayjs } from 'dayjs';
import { SubscriptionPaymentMethod } from 'models/Subscription';
import { DEFAULT_TIMEFRAME } from '../../helper/constants/constants';

export const isTodayOrInThePast = (current: Dayjs | null): boolean =>
  current ? current < dayjs().endOf('day') : false;

export const isInTheFuture = (current: Dayjs | null): boolean =>
  current ? current.isAfter(dayjs().endOf('day')) : false;

export const isMoreThan1YearInTheFuture = (
  current: Dayjs | null | undefined
): boolean =>
  current
    ? current.isAfter(
        dayjs()
          .endOf('day')
          .add(1, 'year')
      )
    : false;

export const isInvalidYear = (current: Dayjs | null | undefined): boolean =>
  current ? current.isBefore(dayjs('1971-01-01')) : false;

export const isWithinTimeframe = (
  current: Dayjs,
  timeframe: number
): boolean => {
  if (!current) return false;

  return (
    current.isValid() &&
    current.isBefore(
      dayjs()
        .add(timeframe - 1, 'days')
        .endOf('day')
    )
  );
};

export const dayjsToStringFormat = (date: Dayjs, formatType: string): string =>
  dayjs(date).format(formatType);

export const getMonth = (month: string): string =>
  dayjs()
    .month(parseInt(month))
    .format('MMMM');

/**
 *
 * @param StartDate DayJs date param set for start
 * @param EndDate DayJs date param set for end
 * @param timeframe Stats date filter amount (week / month)
 * @param dateFormat DayJS date string template
 * @param isMobile Is screen size mobile?
 *
 */
export const lastXDaysArray = (
  startDate: Dayjs,
  endDate: Dayjs,
  dateFormat: string,
  isMobile?: boolean
): string[] => {
  const timeframeDiff =
    endDate !== undefined ? endDate.diff(startDate, 'days') : DEFAULT_TIMEFRAME;
  const timeframeTicks = timeframeDiff + 1;

  return new Array(timeframeTicks).fill(null).map((_, index) => {
    if (isMobile) {
      return index === 0 || index === timeframeTicks - 1
        ? startDate.add(index, 'days').format('MMM, DD')
        : '';
    } else {
      return startDate.add(index, 'days').format(dateFormat);
    }
  });
};

export const cardIsAboutToExpire = (
  paymentMethod?: SubscriptionPaymentMethod | null
) => {
  if (
    !paymentMethod?.card?.expirationMonth ||
    !paymentMethod.card.expirationYear
  ) {
    return false;
  }

  // dayjs indexes months from 0
  const expireDate = dayjs()
    .set('month', paymentMethod.card.expirationMonth - 1)
    .set('year', paymentMethod.card.expirationYear);

  return (
    expireDate.endOf('month').isAfter(dayjs()) &&
    expireDate.endOf('month').isBefore(dayjs().add(2, 'months'))
  );
};

export const normalizedDate = (
  responseDate?: string | null
): Dayjs | undefined => (responseDate ? dayjs(responseDate) : undefined);

export const formatDuration = (duration: number): string => {
  return `${Math.floor(duration / 60)
    .toString()
    .padStart(2, '0')}:${(duration % 60).toString().padStart(2, '0')}`;
};
