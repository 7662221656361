type UpdatePasswordErrorCodes =
  | 'WRONG_PASSWORD'
  | 'TOO_MANY_REQUESTS'
  | 'WEAK_PASSWORD'
  | 'UNKNOWN_ERROR';
export class UpdatePasswordError extends Error {
  code: UpdatePasswordErrorCodes;

  constructor(message: string, code: UpdatePasswordErrorCodes) {
    super(message);
    this.message = message;
    this.code = code;
  }
}
