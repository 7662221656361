import { AxiosError } from 'axios';
import {
  InvoicesResponse,
  Invoice,
  PaymentIntentStatus,
} from 'models/Subscription';
import useSWR, { SWRConfiguration, SWRResponse } from 'swr';
import subscriptionService from 'features/subscriptions/services/subscriptionService';
import { useMemo } from 'react';

export interface UseInvoicesReturn
  extends Pick<
    SWRResponse<InvoicesResponse, AxiosError>,
    'error' | 'revalidate'
  > {
  isLoading: boolean;
  invoices?: Invoice[];
  unpaidInvoice?: Invoice;
}

export const useInvoices = (
  subscriptionId?: string,
  options?: SWRConfiguration
): UseInvoicesReturn => {
  const { data: invoicesResponse, error, revalidate } = useSWR<
    InvoicesResponse | undefined,
    AxiosError
  >(
    [`/payments/subscriptions/${subscriptionId}/invoices?status=open`],
    () =>
      Boolean(subscriptionId)
        ? subscriptionService.getInvoices(subscriptionId)
        : undefined,
    { revalidateOnFocus: false, ...options }
  );
  const unpaidInvoice = useMemo(
    () =>
      invoicesResponse?.invoices?.find(
        ({ paymentIntent }) =>
          (paymentIntent?.status === PaymentIntentStatus.requires_action ||
            paymentIntent?.status ===
              PaymentIntentStatus.requires_payment_method) &&
          Boolean(paymentIntent?.clientSecret)
      ),
    [invoicesResponse?.invoices]
  );

  return {
    error,
    unpaidInvoice,
    invoices: invoicesResponse?.invoices,
    isLoading: !error && !invoicesResponse,
    revalidate,
  };
};
