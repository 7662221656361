import { UserProfile, UserResponse } from 'models/Users';
import { MusicHubUser } from 'hooks/useAuth';

interface IntercomCustomProperties {
  issuer?: string;
  hasReleased?: boolean;
  hasCreatedRelease?: boolean;
  cancellationReason?: string;
  userRole?: string;
}

export interface IntercomCreateCustomProperties {
  min_age?: number;
  max_age?: number;
  min_income_from_music?: number;
  max_income_from_music?: number;
  min_releases_in_store?: number;
  max_releases_in_store?: number;
  min_planned_releases?: number;
  max_planned_releases?: number;
  min_gema_membership_length?: number;
  max_gema_membership_length?: number;
  discovery_source?: string;
  user_role?: string;
}

export const intercomEvents = {
  SUBSCRIPTION_CANCELLATION: 'SUBSCRIPTION_CANCELLATION',
  BIO_PAGE_CREATED: 'BIO_PAGE_CREATED',
  TRACK_AUDIO_SHARE: 'TRACK_AUDIO_SHARE',
  TRACK_AUDIO_ADD: 'TRACK_AUDIO_ADD',
  UPLOAD_CERTIFICATE_DOWNLOAD: 'UPLOAD_CERTIFICATE_DOWNLOAD',
};

export class IntercomService {
  createUser(
    user: UserResponse,
    emailSubscription: boolean,
    isGemaMember: boolean,
    creationCustomProperties: IntercomCreateCustomProperties
  ) {
    window.Intercom('update', {
      email: user.email,
      first_name: user.firstName,
      last_name: user.lastName,
      user_id: user.userId,
      music_hub_user_id: user.userId,
      name: `${user.firstName} ${user.lastName}`,
      has_released: false,
      is_gema_member: isGemaMember,
      unsubscribed_from_emails: emailSubscription === false,
      ...creationCustomProperties,
    });
  }

  async updateUser(
    user: MusicHubUser,
    userProfile?: UserProfile,
    intercomCustomProperties?: IntercomCustomProperties
  ) {
    userProfile &&
      window.Intercom('update', {
        email: user.email,
        first_name: userProfile.firstName,
        is_gema_member: user.isGemaMember,
        issuer: user.issuer,
        last_name: userProfile.lastName,
        music_hub_user_id: userProfile.userId,
        name: user.displayName,
        language_override: userProfile.locale,
        user_id: userProfile.userId,
        ...(intercomCustomProperties?.hasReleased
          ? {
              has_released: intercomCustomProperties?.hasReleased,
            }
          : {}),
        ...(intercomCustomProperties?.hasCreatedRelease
          ? {
              has_created_release: intercomCustomProperties?.hasCreatedRelease,
            }
          : {}),
        ...(intercomCustomProperties?.cancellationReason
          ? {
              cancellation_reason: intercomCustomProperties?.cancellationReason,
            }
          : {}),
        ...(intercomCustomProperties?.userRole
          ? {
              user_role: intercomCustomProperties?.userRole,
            }
          : {}),
      });
  }

  reset() {
    window.Intercom('update', {
      email: null,
      user_id: null,
      music_hub_user_id: null,
    });
  }

  track(event: keyof typeof intercomEvents, payload?: object) {
    window.Intercom('trackEvent', event, payload);
  }
}

export default new IntercomService();
