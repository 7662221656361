import { Release } from 'models/Release';
import { Track } from 'models/Track';

export const newRelease = (): Release => ({
  tracklist: [],
});

export const newTrack = (): Track => ({
  participants: [],
});
