import useSWR from 'swr';
import { AxiosError, AxiosResponse } from 'axios';
import api, { HealthResponse } from 'helper/api/api';
import { useEffect, useRef } from 'react';
import { useFeatureFlagCheck } from './useFeatureFlagCheck';

export interface UseStatusReturn {
  showMaintenance: boolean;
  showResolved: boolean;
}

export const useStatusCheck = (): UseStatusReturn => {
  const ref = useRef(false);

  const { data, error } = useSWR<HealthResponse, AxiosError<Error>>(
    '/actuator/health',
    () =>
      api.healthCheck
        .health()
        .then((res: AxiosResponse<HealthResponse>) => res.data),
    {
      revalidateOnFocus: true,
      refreshInterval: 30000,
    }
  );
  const { value } = useFeatureFlagCheck('backend-status-alert');

  const showMaintenance = data?.status === 'DOWN' || !!error || value;

  useEffect(() => {
    ref.current = showMaintenance;
  }, [showMaintenance]);

  return {
    showMaintenance: showMaintenance,
    showResolved: ref.current,
  };
};
