import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import getQueryString from 'helper/querystring/getQueryString';
import algoliaService from 'services/algoliaService';

export interface ImpersonateUser {
  impersonatedUser: string | null;
  clearImpersonatedUser: () => void;
}

export const getCurrentImpersonatedUser = (): string | null =>
  localStorage.getItem('impersonatedUser') ||
  getQueryString(window.location.search, 'impersonate');

export const useImpersonate = (): ImpersonateUser => {
  const navigate = useNavigate();
  const { search, pathname } = useLocation();

  const [user, setUser] = useState<string | null>(getCurrentImpersonatedUser());

  const impersonatedUserId = getQueryString(search, 'impersonate');

  useEffect(() => {
    if (impersonatedUserId) {
      localStorage.setItem('impersonatedUser', impersonatedUserId);
      setUser(impersonatedUserId);
    }
  }, [impersonatedUserId]);

  const clearImpersonatedUser = (): void => {
    localStorage.removeItem('impersonatedUser');
    algoliaService.deleteAlgoliaKey();
    setUser(null);
    navigate(pathname);
    window.location.reload(); //trigger hard refresh
  };

  return {
    impersonatedUser: user,
    clearImpersonatedUser,
  };
};
