import authentication from 'helper/authentication/authentication';
import { MusicHubUser } from 'hooks/useAuth';
import {
  SubscriptionResponse,
  SubscriptionPlan,
  SubscriptionStatus,
  SubscriptionForm,
  SubscriptionPostRequest,
  SubscriptionPostResponse,
  ClientSecretType,
  SubscriptionErrorResponse,
  SubscriptionPreviewResponse,
  SubscriptionResponseNested,
  AddPaymentMethodResponse,
  SubscriptionPostGemaRequest,
  AddonName,
  PaymentMethod,
  InvoicesResponse,
  Invoice,
  SubscriptionPaymentMethod,
  SubscriptionPatchRequest,
} from 'models/Subscription';
import { instance as mhApiInstance } from 'helper/api/api';
import { trimVatId } from 'hooks/useProfile';
import dayjs from 'dayjs';
import {
  Stripe,
  StripeElements,
  PaymentIntentResult,
  StripeCardNumberElement,
  StripeError,
  SetupIntentResult,
} from '@stripe/stripe-js';
import { CardNumberElement } from '@stripe/react-stripe-js';
import {
  SubscriptionStripeCardError,
  SubscriptionApiError,
  SubscriptionStripeSepaError,
  SubscriptionApiVatIdError,
  SubscriptionVoucherError,
  SubscriptionStripeGenericMessageError,
  SubscriptionStripePaypalError,
} from 'errors/subscriptionErrors';
import { Axios, AxiosError, AxiosResponse } from 'axios';
import i18next from 'i18next';
import { BillingCycleType } from 'helper/constants/constants';
import { UserProfile } from 'models/Users';
import { logging } from 'logging/logging';

const i18nPrefix = 'subscriptions.stripeCardDeclineCodes.';
const i18nVoucherErrorsPrefix = 'subscriptions.voucherErrors.';
const subscriptionReturnUrl = `${process.env.REACT_APP_BASE_URL}/account/subscription`;

export class SubscriptionService {
  mhAuthInstance: Axios;

  constructor(mhAuthInstance: Axios) {
    this.mhAuthInstance = mhAuthInstance;
  }

  getCombinedSubscriptionStatus(
    subscription: SubscriptionResponse
  ): SubscriptionStatus | undefined {
    // Updates status to custom CANCEL_PENDING if sub is cancelled but payment cycle date hasn't reached
    if (
      subscription.cancelAt &&
      dayjs().isBefore(dayjs(subscription.cancelAt)) &&
      (subscription.status === SubscriptionStatus.ACTIVE ||
        subscription.status === SubscriptionStatus.CANCELED)
    ) {
      return SubscriptionStatus.CANCEL_PENDING;
    }
    return subscription.status;
  }

  userIsSubscribed(subscription?: SubscriptionResponse): boolean {
    if (!subscription || !subscription?.plan) return false;

    return (
      ([SubscriptionPlan.BASIC, SubscriptionPlan.LITE].includes(
        subscription.plan
      ) &&
        (subscription?.status === SubscriptionStatus.ACTIVE ||
          subscription?.status === SubscriptionStatus.CANCEL_PENDING)) ||
      subscription?.plan === SubscriptionPlan.GEMA_BASIC
    );
  }

  userIsUnpaid(subscription?: SubscriptionResponse): boolean {
    if (!subscription || !subscription?.plan) return false;
    return (
      [SubscriptionPlan.BASIC, SubscriptionPlan.LITE].includes(
        subscription.plan
      ) && subscription?.status === SubscriptionStatus.UNPAID
    );
  }

  userHasAddon(addOn: AddonName, subscription?: SubscriptionResponse): boolean {
    if (!subscription) return false;
    return subscription?.addOns?.some((it) => it.name === addOn) ?? false;
  }

  async getSubscription(
    user: MusicHubUser | null
  ): Promise<SubscriptionResponse> {
    // * When user is a gema member do not call the payments svc as it has no authentication for gema users
    // * When user is empty show basic plan until user loads to prevent showing banners before firebase auth is established
    if (!user || !user?.isLoggedIn || user?.isAdminImpersonating) {
      return {
        plan: SubscriptionPlan.BASIC,
        status: SubscriptionStatus.ACTIVE,
      };
    }

    try {
      const {
        data: subscriptionResAxiosData,
      }: AxiosResponse<SubscriptionResponseNested> = await this.mhAuthInstance.get(
        '/payments/subscriptions'
      );
      const subscriptionData = subscriptionResAxiosData.subscriptions[0];

      return {
        ...subscriptionData,
        status: this.getCombinedSubscriptionStatus(subscriptionData),
      };
    } catch (e) {
      // TODO: remove this once payment-api treats unsubscribed GEMA members as basic/active
      if (user.isGemaMember) {
        return {
          plan: SubscriptionPlan.GEMA_BASIC,
          status: SubscriptionStatus.ACTIVE,
        };
      }

      // If error occurs fetching subscription for firebase user assume no sub (returns same as no sub)
      return {
        plan: SubscriptionPlan.FREE,
        status: SubscriptionStatus.ACTIVE,
      };
    }
  }

  async getInvoices(subscriptionId?: string): Promise<InvoicesResponse> {
    const {
      data: invoicesResponse,
    }: AxiosResponse<InvoicesResponse> = await this.mhAuthInstance.get(
      `/payments/subscriptions/${subscriptionId}/invoices?status=open`
    );
    return invoicesResponse;
  }

  async createGemaSubscription(request: SubscriptionPostGemaRequest) {
    try {
      const {
        data,
      }: AxiosResponse<SubscriptionPostResponse> = await this.mhAuthInstance.post(
        '/payments/subscriptions?gema=true',
        request
      );

      return data;
    } catch (e) {
      const error = e as AxiosError;
      throw this.throwApiError(error);
    }
  }

  async createCardSubscription(
    formData: SubscriptionForm,
    stripe: Stripe,
    elements: StripeElements
  ): Promise<PaymentIntentResult | SetupIntentResult> {
    let mhSubscriptionResponse: SubscriptionPostResponse;
    try {
      const subscriptionRequest = this.toCustomerPaymentSubscription(formData);
      const {
        data,
      }: AxiosResponse<SubscriptionPostResponse> = await this.mhAuthInstance.post(
        '/payments/subscriptions',
        subscriptionRequest
      );

      mhSubscriptionResponse = data;
    } catch (e) {
      const error = e as AxiosError;
      throw this.throwApiError(error);
    }

    try {
      let stripeResponse;
      if (
        mhSubscriptionResponse.clientSecretType === ClientSecretType.PAYMENT
      ) {
        stripeResponse = await stripe.confirmCardPayment(
          mhSubscriptionResponse?.clientSecret,
          {
            // Options to ensure payment is configured as a recurring payment
            setup_future_usage: 'off_session',
            payment_method: {
              card: elements.getElement(
                CardNumberElement
              ) as StripeCardNumberElement,
              billing_details: {
                name: formData.accountHolderName,
                address: {
                  line1: formData.addressLine1,
                  line2: formData.addressLine2,
                  postal_code: formData.postcode,
                  city: formData.city,
                  country: formData.country,
                },
              },
            },
            return_url: subscriptionReturnUrl,
          }
        );
      } else {
        stripeResponse = await stripe.confirmCardSetup(
          mhSubscriptionResponse?.clientSecret,
          {
            payment_method: {
              card: elements.getElement(
                CardNumberElement
              ) as StripeCardNumberElement,
              billing_details: {
                address: {
                  line1: formData.addressLine1,
                  line2: formData.addressLine2,
                  postal_code: formData.postcode,
                  city: formData.city,
                  country: formData.country,
                },
              },
            },
            return_url: subscriptionReturnUrl,
          }
        );
      }

      if (stripeResponse?.error) {
        throw stripeResponse.error;
      }
      return stripeResponse;
    } catch (e) {
      const error = e as StripeError;
      const code = error.decline_code || error.code;
      logging.error({
        productArea: 'subscription',
        message: 'STRIPE_CARD_CONFIRM_FAILURE',
        messageContext: {
          code,
          type: error.type,
          errorMessage: error.message,
        },
        error: e as Error,
      });
      throw new SubscriptionStripeCardError(
        i18next.exists(`${i18nPrefix}${code}`)
          ? i18next.t(`${i18nPrefix}${code}`)
          : error.message ?? i18next.t(`validationErrors.generic_decline`)
      );
    }
  }

  async createPaypalSubscription(
    formData: SubscriptionForm,
    stripe: Stripe
  ): Promise<PaymentIntentResult | SetupIntentResult> {
    let mhSubscriptionResponse: SubscriptionPostResponse;
    const paymentMethod = {
      billing_details: {
        name: formData.accountHolderName,
        address: {
          line1: formData.addressLine1,
          line2: formData.addressLine2,
          postal_code: formData.postcode,
          city: formData.city,
          country: formData.country,
        },
      },
    };
    try {
      const subscriptionRequest = this.toCustomerPaymentSubscription(formData);
      const {
        data,
      }: AxiosResponse<SubscriptionPostResponse> = await this.mhAuthInstance.post(
        '/payments/subscriptions',
        subscriptionRequest
      );

      mhSubscriptionResponse = data;
    } catch (e) {
      const error = e as AxiosError;
      throw this.throwApiError(error);
    }

    try {
      const paypalReturnUrl = subscriptionReturnUrl;
      let stripeResponse;
      if (
        mhSubscriptionResponse.clientSecretType === ClientSecretType.PAYMENT
      ) {
        stripeResponse = await stripe.confirmPayPalPayment(
          mhSubscriptionResponse.clientSecret,
          {
            return_url: paypalReturnUrl,
            payment_method: paymentMethod,
          }
        );
      } else {
        stripeResponse = await stripe.confirmPayPalSetup(
          mhSubscriptionResponse.clientSecret,
          {
            return_url: paypalReturnUrl,
            payment_method: paymentMethod,
            mandate_data: {
              customer_acceptance: {
                type: 'online',
                online: {
                  infer_from_client: true,
                },
              },
            },
          }
        );
      }

      if (stripeResponse?.error) {
        throw stripeResponse.error;
      }
      return stripeResponse;
    } catch (e) {
      const error = e as StripeError;
      const code = error.decline_code || error.code;
      logging.error({
        productArea: 'subscription',
        message: 'STRIPE_PAYPAL_CONFIRM_FAILURE',
        messageContext: {
          code,
          type: error.type,
          errorMessage: error.message,
        },
        error: e as Error,
      });
      throw new SubscriptionStripePaypalError(
        i18next.exists(`${i18nPrefix}${code}`)
          ? i18next.t(`${i18nPrefix}${code}`)
          : error.message ?? i18next.t(`validationErrors.generic_decline`)
      );
    }
  }

  async updatePaymentMethod(
    subscriptionId: string,
    stripe: Stripe,
    elements: StripeElements,
    paymentMethod: PaymentMethod,
    subscription?: SubscriptionResponse
  ): Promise<PaymentIntentResult | SetupIntentResult | undefined> {
    try {
      const clientSecret = await this.addPaymentMethod(subscriptionId);

      let stripeResponse;
      if (paymentMethod === PaymentMethod.CARD) {
        stripeResponse = await stripe.confirmCardSetup(clientSecret || '', {
          return_url: subscriptionReturnUrl,
          payment_method: {
            card: elements.getElement(
              CardNumberElement
            ) as StripeCardNumberElement,
          },
        });
      } else if (paymentMethod === PaymentMethod.PAYPAL) {
        const paypalReturnUrl = subscriptionReturnUrl;
        stripeResponse = await stripe.confirmPayPalSetup(clientSecret, {
          return_url: paypalReturnUrl,
          mandate_data: {
            customer_acceptance: {
              type: 'online',
              online: {
                infer_from_client: true,
              },
            },
          },
          payment_method: {
            billing_details: {
              name: subscription?.fullName,
              address: {
                line1: subscription?.addressLine1,
                line2: subscription?.addressLine2,
                postal_code: subscription?.postcode,
                city: subscription?.city,
                country: subscription?.country,
              },
            },
          },
        });
      }

      if (stripeResponse?.error) {
        throw { ...stripeResponse.error, paymentMethod };
      }

      return stripeResponse;
    } catch (e) {
      const error = e as StripeError & { paymentMethod: PaymentMethod };
      const code = error.decline_code || error.code;
      logging.error({
        productArea: 'subscription',
        message: `STRIPE_${paymentMethod}_UPDATE_FAILURE`,
        messageContext: {
          code,
          type: error.type,
          errorMessage: error.message,
        },
        error: e as Error,
      });
      if (paymentMethod === PaymentMethod.PAYPAL) {
        throw new SubscriptionStripePaypalError(
          i18next.exists(`${i18nPrefix}${code}`)
            ? i18next.t(`${i18nPrefix}${code}`)
            : error.message ??
              i18next.t(
                `subscriptions.stripePaypalDeclineCodes.generic_decline`
              )
        );
      }
      throw new SubscriptionStripeCardError(
        i18next.exists(`${i18nPrefix}${code}`)
          ? i18next.t(`${i18nPrefix}${code}`)
          : error.message ??
            i18next.t(`subscriptions.stripeCardDeclineCodes.generic_decline`)
      );
    }
  }

  async addPaymentMethod(subscriptionId: string): Promise<string> {
    try {
      const {
        data,
      }: AxiosResponse<AddPaymentMethodResponse> = await this.mhAuthInstance.post(
        `/payments/subscriptions/${subscriptionId}/paymentMethod`
      );

      return data.clientSecret;
    } catch (e) {
      const error = e as AxiosError;
      throw this.throwApiError(error);
    }
  }

  async getCustomerPortalLink(): Promise<string> {
    try {
      const { data }: AxiosResponse<string> = await this.mhAuthInstance.post(
        `/payments/subscriptions/customer-portal-session?page=SUBSCRIPTION_UPDATE&returnUrl=${window.encodeURIComponent(
          process.env.REACT_APP_CUSTOMER_PORTAL_REDIRECT_URL ?? ''
        )}`
      );
      if (typeof data !== 'string') {
        throw new Error('Customer portal URL is not a string');
      }
      return data;
    } catch (e) {
      const error = e as AxiosError;
      throw this.throwApiError(error);
    }
  }

  async cancelSubscription(
    subscriptionId?: string
  ): Promise<SubscriptionResponse> {
    const {
      data,
    }: AxiosResponse<SubscriptionResponse> = await this.mhAuthInstance.patch(
      `/payments/subscriptions/${subscriptionId}`,
      {
        cancelAtBillingEnd: true,
      } as SubscriptionPatchRequest
    );

    return data;
  }

  async cancelAddOn(
    subscriptionId: string,
    addonToCancel: AddonName
  ): Promise<SubscriptionResponse> {
    const {
      data,
    }: AxiosResponse<SubscriptionResponse> = await this.mhAuthInstance.patch(
      `/payments/subscriptions/${subscriptionId}`,
      {
        addOns: [
          {
            name: addonToCancel,
            cancelAtBillingEnd: true,
          },
        ],
      } as SubscriptionPatchRequest
    );

    return data;
  }

  async reactivateSubscription(
    subscriptionId?: string
  ): Promise<SubscriptionResponse> {
    const {
      data,
    }: AxiosResponse<SubscriptionResponse> = await this.mhAuthInstance.patch(
      `/payments/subscriptions/${subscriptionId}`,
      {
        cancelAtBillingEnd: false,
      } as SubscriptionPatchRequest
    );

    return data;
  }

  async switchBillingCycle(
    billingCycle: BillingCycleType,
    subscriptionId: string | undefined,
    stripe: Stripe | null
  ): Promise<SubscriptionResponse> {
    const {
      data: subscriptionResponse,
    }: AxiosResponse<SubscriptionResponse> = await this.mhAuthInstance.patch(
      `/payments/subscriptions/${subscriptionId}`,
      {
        billingCycle: billingCycle,
        subscriptionId,
      }
    );

    if (subscriptionResponse.paymentMethod?.card) {
      await this.confirmPayment(
        stripe,
        subscriptionResponse?.clientSecret,
        subscriptionResponse?.paymentMethod,
        subscriptionResponse?.paymentMethodId
      );
    }

    return subscriptionResponse;
  }

  async confirmPayment(
    stripe: Stripe | null,
    clientSecret?: string,
    paymentMethod?: SubscriptionPaymentMethod | null,
    paymentMethodId?: string
  ) {
    const STRIPE_EXCEPTION_PAYMENT_PREVIOUSLY_CONFIRMED =
      'payment_intent_unexpected_state';
    if (clientSecret && paymentMethodId) {
      const handleError = (
        stripeError: StripeError | undefined,
        throwError:
          | SubscriptionStripeCardError
          | SubscriptionStripePaypalError
          | SubscriptionStripeSepaError
      ) => {
        if (stripeError) {
          if (
            stripeError.code ===
              STRIPE_EXCEPTION_PAYMENT_PREVIOUSLY_CONFIRMED &&
            stripeError.payment_intent?.status === 'succeeded'
          ) {
            // Cannot confirm this PaymentIntent because it has already succeeded after being previously confirmed. Treat as success
            return;
          }
          throw throwError;
        }
      };

      if (paymentMethod?.card) {
        const response = await stripe?.confirmCardPayment(clientSecret, {
          return_url: subscriptionReturnUrl,
          payment_method: paymentMethodId,
        });
        handleError(
          response?.error,
          new SubscriptionStripeCardError(
            i18next.exists(`${i18nPrefix}${response?.error?.code}`)
              ? i18next.t(`${i18nPrefix}${response?.error?.code}`)
              : response?.error?.message ??
                i18next.t(`validationErrors.generic_decline`)
          )
        );
      } else if (paymentMethod?.paypal) {
        const response = await stripe?.confirmPayPalPayment(clientSecret, {
          return_url: subscriptionReturnUrl,
          payment_method: paymentMethodId,
        });
        handleError(
          response?.error,
          new SubscriptionStripePaypalError(
            i18next.exists(`${i18nPrefix}${response?.error?.code}`)
              ? i18next.t(`${i18nPrefix}${response?.error?.code}`)
              : response?.error?.message ??
                i18next.t(
                  `subscriptions.stripePaypalDeclineCodes.generic_decline`
                )
          )
        );
      } else {
        const response = await stripe?.confirmSepaDebitPayment(clientSecret, {
          return_url: subscriptionReturnUrl,
          payment_method: paymentMethodId,
        });
        if (response?.error) {
          if (
            response.error.code ===
              STRIPE_EXCEPTION_PAYMENT_PREVIOUSLY_CONFIRMED &&
            response.error.payment_intent?.status === 'succeeded'
          ) {
            // Cannot confirm this PaymentIntent because it has already succeeded after being previously confirmed. Treat as success
            return;
          }
          handleError(
            response?.error,
            new SubscriptionStripeSepaError(
              i18next.exists(`${i18nPrefix}${response?.error?.code}`)
                ? i18next.t(`${i18nPrefix}${response?.error?.code}`)
                : response?.error?.message ??
                  i18next.t(`validationErrors.generic_decline`)
            )
          );
        }
      }
    } else {
      throw Error('No client secret or payment method ID in confirmPayment');
    }
  }

  async retryInvoice(
    stripe: Stripe | null,
    invoice?: Invoice,
    subscription?: SubscriptionResponse
  ) {
    return this.confirmPayment(
      stripe,
      invoice?.paymentIntent?.clientSecret,
      subscription?.paymentMethod,
      subscription?.paymentMethodId
    );
  }

  async retryAllInvoices(
    stripe: Stripe | null,
    invoice?: Invoice,
    subscription?: SubscriptionResponse
  ) {
    try {
      if (invoice) await this.retryInvoice(stripe, invoice, subscription);
      if (subscription?.id) {
        await this.mhAuthInstance.post(
          `/payments/subscriptions/${subscription?.id}/invoices/retry`
        );
      }
    } catch (e) {
      logging.error({
        productArea: 'subscription',
        message: `Error retrying invoices for subscription ID: ${subscription?.id}`,
        error: e as Error,
      });
      if (e instanceof SubscriptionStripeCardError) {
        throw e;
      }
    }
  }

  async billingInvoicePreview(
    billingCycle: BillingCycleType,
    plan: SubscriptionPlan,
    subscriptionId?: string,
    voucherCode?: string
  ): Promise<SubscriptionPreviewResponse> {
    const {
      data: bilingPreviewResData,
    }: AxiosResponse<SubscriptionPreviewResponse> = await this.mhAuthInstance.get(
      `/payments/subscriptions${
        subscriptionId ? `/${subscriptionId}` : ''
      }/preview?billingCycle=${billingCycle}${
        voucherCode ? `&voucherCode=${voucherCode}` : ''
      }&plan=${plan}`
    );

    return bilingPreviewResData;
  }

  toCustomerPaymentSubscription(
    formData: SubscriptionForm
  ): SubscriptionPostRequest {
    return {
      fullName: formData.fullName,
      companyName: formData.companyName,
      addressLine1: formData.addressLine1,
      addressLine2: formData.addressLine2,
      postcode: formData.postcode,
      city: formData.city,
      country: formData.country,
      vatId: this.toVatId(formData),
      preferredEmailLocale: formData.preferredEmailLocale,
      voucher: formData.hasVoucher ? formData.voucher : undefined,
      billingCycle: formData.billingCycle,
      plan: formData.plan,
      ...(formData.campaign ? { campaign: formData.campaign } : {}),
    };
  }

  toUserProfile(
    formData: SubscriptionForm,
    userProfile?: UserProfile
  ): UserProfile {
    return {
      firstName: userProfile?.firstName,
      lastName: userProfile?.lastName,
      email: userProfile?.email || authentication.user?.email || null,
      locale: userProfile?.locale,
      taxResidency: formData?.country,
      city: formData.city,
      addressLine1: formData.addressLine1,
      addressLine2: formData.addressLine2,
      postcode: formData.postcode,
      vatId: formData.vatId,
      country: formData.country,
      iban: formData.iban ?? undefined,
      accountHolderName: formData?.accountHolderName ?? undefined,
      vatExempt: false, // always set to false and allow user to set it
      bic: undefined,
      taxId: undefined,
    };
  }

  private toVatId(formData: SubscriptionForm): string | undefined {
    return formData.vatId
      ? `${formData.country}${trimVatId(formData.vatId, formData.country)}`
      : undefined;
  }

  private throwApiError(e: AxiosError) {
    logging.error({
      productArea: 'subscription',
      message: 'API_SUBSCRIPTION_FAILURE',
      messageContext: {
        errorMessage: e?.response?.data,
        errorResponse: e?.response?.data || {},
      },
      error: e as Error,
    });

    if (e.response?.status === 400) {
      const error = e as AxiosError<SubscriptionErrorResponse>;
      const { field, code, message } = error.response?.data || {};

      if (field === 'vatId') {
        return new SubscriptionApiVatIdError('Vat ID error');
      } else if (
        [
          'voucher_not_found',
          'voucher_expired',
          'voucher_customer_used',
        ].includes(code || '')
      ) {
        throw new SubscriptionVoucherError(
          i18next.t(`${i18nVoucherErrorsPrefix}${code}`)
        );
      } else if (message) {
        throw new SubscriptionStripeGenericMessageError(message);
      }
    }

    return new SubscriptionApiError('Payment API Error');
  }
}

export default new SubscriptionService(mhApiInstance);
