import React from 'react';
import { useImpersonate, ImpersonateUser } from 'hooks/useImpersonate';
import Banner from 'components/banner/Banner';
import Typography from 'component-library/src/typography/Typography';

const ImpersonatedUserAlertBanner = (props: ImpersonateUser): JSX.Element => (
  <Banner
    testId="impersonated-alert"
    type="warning"
    actionButton={{
      text: 'Sign out',
      testId: 'signout',
      variant: 'filled',
      onClick: props.clearImpersonatedUser,
    }}
    description={
      <>
        <div>
          <Typography size="s" type="body" weightLevel="500" color="neutral9">
            {`You are currently impersonating a user with userId: `}
          </Typography>
          <Typography
            size="s"
            type="body"
            weightLevel="600"
            color="neutral9"
            data-testid="impersonated-user-id"
          >
            {props.impersonatedUser}
          </Typography>
        </div>
        <div>
          <Typography size="s" type="body" color="neutral9">
            Make sure you sign out before changing anything in the interface!
          </Typography>
        </div>
      </>
    }
  />
);

export const ImpersonatedUserAlert = (): JSX.Element => {
  const impersonateContext = useImpersonate();
  if (impersonateContext.impersonatedUser) {
    return <ImpersonatedUserAlertBanner {...impersonateContext} />;
  }
  return <></>;
};
