import React from 'react';

const PageLoader = () => (
  <div className="loading-container">
    <svg
      id="audio-wave-loading"
      data-name="layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 38.05"
    >
      <path
        id="freq_1"
        data-name="freq_1"
        d="M0.91,15L0.78,15A1,1,0,0,0,0,16v6a1,1,0,1,0,2,0s0,0,0,0V16a1,1,0,0,0-1-1H0.91Z"
      />
      <path
        id="freq_2"
        data-name="freq_2"
        d="M6.91,9L6.78,9A1,1,0,0,0,6,10V28a1,1,0,1,0,2,0s0,0,0,0V10A1,1,0,0,0,7,9H6.91Z"
      />
      <path
        id="freq_3"
        data-name="freq_3"
        d="M12.91,0L12.78,0A1,1,0,0,0,12,1V37a1,1,0,1,0,2,0s0,0,0,0V1a1,1,0,0,0-1-1H12.91Z"
      />
      <path
        id="freq_4"
        data-name="freq_4"
        d="M18.91,10l-0.12,0A1,1,0,0,0,18,11V27a1,1,0,1,0,2,0s0,0,0,0V11a1,1,0,0,0-1-1H18.91Z"
      />
      <path
        id="freq_5"
        data-name="freq_5"
        d="M24.91,15l-0.12,0A1,1,0,0,0,24,16v6a1,1,0,0,0,2,0s0,0,0,0V16a1,1,0,0,0-1-1H24.91Z"
      />
      <path
        id="freq_6"
        data-name="freq_6"
        d="M30.91,10l-0.12,0A1,1,0,0,0,30,11V27a1,1,0,1,0,2,0s0,0,0,0V11a1,1,0,0,0-1-1H30.91Z"
      />
      <path
        id="freq_7"
        data-name="freq_7"
        d="M36.91,0L36.78,0A1,1,0,0,0,36,1V37a1,1,0,1,0,2,0s0,0,0,0V1a1,1,0,0,0-1-1H36.91Z"
      />
      <path
        id="freq_8"
        data-name="freq_8"
        d="M42.91,9L42.78,9A1,1,0,0,0,42,10V28a1,1,0,1,0,2,0s0,0,0,0V10a1,1,0,0,0-1-1H42.91Z"
      />
      <path
        id="freq_9"
        data-name="freq_9"
        d="M48.91,15l-0.12,0A1,1,0,0,0,48,16v6a1,1,0,1,0,2,0s0,0,0,0V16a1,1,0,0,0-1-1H48.91Z"
      />
    </svg>
  </div>
);

export default PageLoader;
