import React from 'react';
import { Space } from 'antd';
import { spacing } from 'component-library/src/theme/theme';
import { Rocket } from 'assets/img';
import Typography from 'component-library/src/typography/Typography';
import { useReleaseCount } from 'hooks/useReleaseCount';
import { useTranslation } from 'react-i18next';

const ReleaseCount: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { t } = useTranslation();
  const { releaseCount } = useReleaseCount();

  return Boolean(releaseCount) ? (
    <Space size={spacing.space4} align="center" className="nav-release-count">
      <Rocket />
      <Typography
        type="body"
        size="xs"
        color="neutral9"
        weightLevel="600"
        data-testid="footer-total-releases-number"
      >{`${releaseCount?.delivered}`}</Typography>
      <Typography type="body" size="xs" color="neutral8" weightLevel="400">
        {t('footer.totalReleases')}
      </Typography>
    </Space>
  ) : (
    <></>
  );
};

export default ReleaseCount;
