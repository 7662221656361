import { BillingCycleType } from 'helper/constants/constants';

export type SubscriptionCampaignType =
  | 'pricing-experiment-variant-a'
  | 'pricing-experiment-variant-b';

export enum SubscriptionStatus {
  ACTIVE = 'ACTIVE',
  PAST_DUE = 'PAST_DUE',
  UNPAID = 'UNPAID',
  CANCELED = 'CANCELED',
  INCOMPLETE = 'INCOMPLETE',
  INCOMPLETE_EXPIRED = 'INCOMPLETE_EXPIRED',
  // Custom for FE
  CANCEL_PENDING = 'CANCEL_PENDING',
}

export enum SubscriptionPlan {
  FREE = 'FREE',
  BASIC = 'BASIC',
  GEMA_BASIC = 'GEMA_BASIC',
  LITE = 'LITE',
  //only used for subscription creation during experiment
  ESSENTIAL = 'ESSENTIAL',
}

export enum ClientSecretType {
  PAYMENT = 'PAYMENT',
  SETUP = 'SETUP',
}

export interface SubscriptionPostGemaRequest {
  fullName: string;
  country: string;
  preferredEmailLocale: string;
}

export interface SubscriptionPostRequest {
  fullName?: string;
  companyName?: string;
  addressLine1: string;
  addressLine2?: string;
  postcode: string;
  city: string;
  country: string;
  vatId?: string;
  preferredEmailLocale?: string;
  voucher?: string;
  billingCycle: BillingCycleType;
  plan: SubscriptionPlan;
  campaign?: SubscriptionCampaignType;
}

export enum PaymentMethod {
  'CARD' = 'CARD',
  'SEPA' = 'SEPA',
  'PAYPAL' = 'PAYPAL',
}

export interface SubscriptionForm extends SubscriptionPostRequest {
  accountHolderName?: string;
  iban?: string;
  cardNumber?: boolean;
  cardExpiration?: boolean;
  cardCvc?: boolean;
  hasVoucher?: boolean;
  paymentType?: PaymentMethod;
  voucher?: string;
  billingCycle: BillingCycleType;
  plan: SubscriptionPlan;
}

export interface SubscriptionPostResponse {
  id: string;
  clientSecret: string;
  clientSecretType: ClientSecretType;
}

export interface AddPaymentMethodResponse {
  clientSecret: string;
  clientSecretType: ClientSecretType;
}

export interface SepaPaymentResponse {
  accountHolderName?: string;
  ibanLastFour?: string;
}

export interface CardPaymentResponse {
  brand?: string;
  cardLastFour?: string;
  expirationMonth?: number;
  expirationYear?: number;
}

export interface PaypalPaymentResponse {
  email?: string;
}

export interface SubscriptionPaymentMethod {
  sepa: SepaPaymentResponse | null;
  card: CardPaymentResponse | null;
  paypal: PaypalPaymentResponse | null;
}

export enum PaymentIntentStatus {
  requires_payment_method = 'requires_payment_method',
  requires_action = 'requires_action',
}

export enum AddonName {
  'BEATPORT' = 'BEATPORT',
}

export interface SubscriptionPatchRequest {
  cancelAtBillingEnd?: boolean;
  addOns?: {
    name: AddonName;
    cancelAtBillingEnd: boolean;
  }[];
}

export interface SubscriptionResponse {
  id?: string;
  plan: SubscriptionPlan;
  userId?: string;
  status?: SubscriptionStatus;
  cancelAt?: string;
  email?: string;
  fullName?: string;
  companyName?: string;
  addressLine1?: string;
  addressLine2?: string;
  postcode?: string;
  city?: string;
  country?: string;
  vatId?: string;
  currentPeriodEnd?: string;
  paymentMethod?: SubscriptionPaymentMethod | null;
  billingCycle?: BillingCycleType;
  paymentMethodId?: string;
  clientSecret?: string;
  paymentIntentStatus?: PaymentIntentStatus;
  addOns?: {
    name: AddonName;
    cancelAt?: string;
  }[];
  campaign?: SubscriptionCampaignType;
}

export interface SubscriptionResponseNested {
  subscriptions: SubscriptionResponse[];
}

export interface SubscriptionErrorResponse {
  field: keyof SubscriptionPostRequest;
  message: string;
  code: 'voucher_expired' | 'voucher_not_found';
}

export enum CurrencyResponse {
  EUR = 'eur',
}

export interface SubscriptionPreviewResponse {
  currency?: CurrencyResponse;
  planAmount?: number;
  discountPercentage?: number;
  discountAmount?: number;
  discountDurationInMonths?: number;
  proratedAmount?: number;
  total: number;
  nextChargeDate?: string;
}

export interface Invoice {
  id?: string;
  status?: string;
  paymentIntent?: {
    id?: string;
    status?: PaymentIntentStatus;
    clientSecret?: string;
  };
}

export interface InvoicesResponse {
  invoices: Invoice[];
}
