import React from 'react';
import useFileUpload from 'hooks/useFileUpload';
import { Collapse } from 'antd';
import {
  DownOutlined,
  LoadingOutlined,
  CheckCircleTwoTone,
  WarningTwoTone,
  CloseOutlined,
} from '@ant-design/icons';
const { Panel } = Collapse;

const FileUploadProgress: React.FC<React.PropsWithChildren<unknown>> = () => {
  const fileUploadContext = useFileUpload();

  return fileUploadContext.files.length ? (
    <Collapse
      defaultActiveKey={['1']}
      expandIconPosition="right"
      expandIcon={() => (
        <DownOutlined className="file-upload-progress-toggle" />
      )}
    >
      <Panel
        data-testid="file-upload-progress"
        className="file-upload-progress"
        header={`Uploading ${fileUploadContext.files.length} files`}
        key="1"
        showArrow={false}
        extra={
          <CloseOutlined
            data-testid="file-upload-progress-close"
            onClick={fileUploadContext.clearFiles}
            className="file-upload-progress-close"
          />
        }
      >
        {fileUploadContext.files.map((file, index) => {
          return (
            <div
              key={index}
              data-testid="file-upload-progress-row"
              className="file-upload-progress-row"
            >
              <div className="file-upload-progress-title">
                <p key={index} data-testid={`upload-file-${index}`}>
                  {file.name}
                </p>
              </div>
              <div className="file-upload-progress-indicator">
                {file.status === 'uploading' ? (
                  <LoadingOutlined data-testid="file-upload-loading" />
                ) : null}
                {file.status === 'done' ? (
                  <CheckCircleTwoTone
                    data-testid="file-upload-success"
                    twoToneColor="#52C41A"
                  />
                ) : null}
                {file.status === 'error' && (
                  <WarningTwoTone
                    data-testid="file-upload-error"
                    twoToneColor="#FA8C16"
                  />
                )}
              </div>
            </div>
          );
        })}
      </Panel>
    </Collapse>
  ) : null;
};

export default FileUploadProgress;
