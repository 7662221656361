import { mutate, cache } from 'swr';
import {
  toReleaseRecentList,
  toRelease,
  toReleaseList,
} from 'models/responses/ReleaseResponseData';
import api from 'helper/api/api';
import trackService, { TrackService } from './trackService';
import releaseTrackService, {
  ReleaseTrackService,
} from './releaseTrackService';
import {
  Release,
  ReleaseList,
  ReleasePatchRequest,
  ReleaseRequest,
  ReleaseIngestionStatusRequest,
  ReleaseRecentList,
  ReleaseLockFailureResponse,
} from 'models/Release';
import { ApiSpec } from 'specs/api-spec';
import dayjs from 'dayjs';
import { AxiosError } from 'axios';

export interface AllReleasesList {
  releases: ReleaseList;
  meta?: ApiSpec['schemas']['PaginationMetaResponse'];
}

export interface AllReleaseRecentList {
  releases: ReleaseRecentList;
  meta?: ApiSpec['schemas']['PaginationMetaResponse'];
}

export class ReleaseService {
  trackService: TrackService;
  releaseTrackService: ReleaseTrackService;

  constructor(
    trackService: TrackService,
    releaseTrackService: ReleaseTrackService
  ) {
    this.trackService = trackService;
    this.releaseTrackService = releaseTrackService;
  }

  async getRelease(id: string): Promise<Release | undefined> {
    const cacheKey = ['/releases', id];
    if (cache.has(cacheKey)) {
      return cache.get(cacheKey) as Release;
    }
    return api.release
      .get({ id })
      .then((res) => toRelease(res.data))
      .then((release) => {
        mutate(cacheKey, release);
        return release;
      });
  }

  async getAllReleases(
    page: number,
    size: number,
    ingestionStatus?: ReleaseIngestionStatusRequest
  ): Promise<AllReleasesList> {
    const releaseList = await api.release.getAll(page, size, ingestionStatus);
    return {
      releases: releaseList.data.releases
        ? toReleaseList(releaseList.data)
        : [],
      meta: releaseList.data.meta,
    };
  }

  async getReleaseList(filterIds?: string[]) {
    if (!filterIds || !filterIds.length) return [];

    const releasePromises: Promise<
      Release | undefined
    >[] = filterIds.map((id) => this.getRelease(id));
    return Promise.all(releasePromises) as Promise<(Release | undefined)[]>;
  }

  async createRelease(release: ReleaseRequest): Promise<Release | undefined> {
    const res = await api.release.create(release);
    return toRelease(res.data);
  }

  async patchRelease(
    releaseId: string,
    releasePatchRequest: ReleasePatchRequest
  ): Promise<unknown> {
    const res = await api.release.patch(releaseId, releasePatchRequest);
    return res.data;
  }

  async lockReleaseForEditing(
    releaseId: string
  ): Promise<ReleaseLockFailureResponse | undefined> {
    try {
      const response = await api.release.lock(releaseId);
      return response.data;
    } catch (e) {
      const errorResponse = e as AxiosError;
      return errorResponse.response?.data as ReleaseLockFailureResponse;
    }
  }

  async unlockReleaseForEditing(releaseId: string): Promise<void> {
    // Always create a lock before releasing it to ensure lock to un-lock is sequential.
    // If the create is still pending the unlock will silently succeed but fail to unlock.
    await this.lockReleaseForEditing(releaseId);
    await api.release.unlock(releaseId);
  }

  async unlockActiveReleaseForEditing(url: string): Promise<void> {
    const regex = /\/release\/([^/]+)\/edit(\/.+)?$/;
    const match = url.match(regex);

    if (match) {
      const releaseId = match[1];
      await this.unlockReleaseForEditing(releaseId);
    }
  }

  async createReleaseFromTrack(trackId: string): Promise<Release | undefined> {
    const year = dayjs().year();
    const track = await trackService.getTrack(trackId);
    const release = await this.createRelease({
      title: track?.title,
      genre1: track?.genre1,
      genre2: track?.genre2,
      copyright: { name: track?.recordingRight?.name || '', year },
      recordingRight: { name: track?.recordingRight?.name || '', year },
    });
    if (!release?.id || !track) return;
    await releaseTrackService.updateTrackList(release?.id, [track]);
    return release;
  }

  async getReleaseRecentList(
    page: number,
    size: number
  ): Promise<AllReleaseRecentList> {
    const releaseRecentList = await api.release.getReleaseRecentList(
      page,
      size
    );

    return {
      releases: releaseRecentList.data.releases
        ? toReleaseRecentList(releaseRecentList.data)
        : [],
      meta: releaseRecentList.data.meta,
    };
  }
}

export default new ReleaseService(trackService, releaseTrackService);
