import React from 'react';
import subscriptionService from 'features/subscriptions/services/subscriptionService';
import { useTranslation, Trans } from 'react-i18next';
import { useAuth } from 'hooks/useAuth';
import { useNavigate, useLocation } from 'react-router-dom';
import { paths } from 'config/routes';
import { cardIsAboutToExpire } from 'helper/time/time';
import Banner from 'components/banner/Banner';
import { useInvoices } from 'components/subscriptions-payment/hooks/useInvoices';

const SubscriptionAlertBannerInner: React.FC<React.PropsWithChildren<
  unknown
>> = () => {
  const { t } = useTranslation();
  const { userSubscription } = useAuth();
  const navigate = useNavigate();
  const { unpaidInvoice } = useInvoices(userSubscription?.subscription?.id);

  // Non-GEMA and UNPAID show services restricted banner
  if (subscriptionService.userIsUnpaid(userSubscription?.subscription)) {
    return (
      <Banner
        fireEventOnMount
        type="error"
        testId="account-is-unpaid-banner"
        description={t('subscriptions.subscriptionAlertBanner.unpaidMessage')}
        actionButton={{
          onClick: () =>
            navigate(paths.subscription, {
              state: {
                updatePaymentMethod: false,
              },
            }),
          text: t('subscriptions.subscriptionAlertBanner.requiresActionButton'),
          testId: 'account-is-unpaid-banner-button',
        }}
      />
    );
  }

  // GEMA & non-GEMA with an unpaid invoice regardless of status show retry banner
  if (Boolean(unpaidInvoice)) {
    return (
      <Banner
        fireEventOnMount
        type="error"
        testId="card-payment-failed-alert-action"
        description={
          <Trans
            i18nKey={
              'subscriptions.subscriptionAlertBanner.requiresActionMessage'
            }
          >
            <a
              onClick={(): void => window.Intercom('show')}
              className="link-in-text"
              target="_blank"
            >
              {''}
            </a>
          </Trans>
        }
        actionButton={{
          onClick: () =>
            navigate(paths.subscription, {
              state: {
                updatePaymentMethod: false,
              },
            }),
          text: t('subscriptions.subscriptionAlertBanner.requiresActionButton'),
          testId: 'card-payment-failed-alert-action-button',
        }}
      />
    );
  }

  if (cardIsAboutToExpire(userSubscription?.subscription?.paymentMethod)) {
    return (
      <Banner
        fireEventOnMount
        type="warning"
        testId="card-about-to-expire"
        description={
          <Trans
            i18nKey={
              'subscriptions.subscriptionAlertBanner.cardAboutToExpireAlert'
            }
          >
            <span className="font-bold">{''}</span>
          </Trans>
        }
        actionButton={{
          onClick: () =>
            navigate(paths.subscription, {
              state: {
                updatePaymentMethod: true,
              },
            }),
          text: t(
            'subscriptions.subscriptionAlertBanner.cardAboutToExpireAlertButton'
          ),
          testId: 'card-about-to-expire-button',
        }}
      />
    );
  }

  if (Boolean(userSubscription?.subscription?.paymentMethod?.sepa)) {
    return (
      <Banner
        fireEventOnMount
        type="warning"
        testId="sepa-switch-bannner"
        description={t('subscriptions.subscriptionAlertBanner.sepaSwitchAlert')}
        actionButton={{
          onClick: () =>
            navigate(paths.subscription, {
              state: {
                updatePaymentMethod: true,
              },
            }),
          text: t(
            'subscriptions.subscriptionAlertBanner.sepaSwitchAlertButton'
          ),
          testId: 'sepa-switch-bannner-button',
        }}
      />
    );
  }

  return null;
};

const SubscriptionAlertBanner: React.FC<React.PropsWithChildren<
  unknown
>> = () => {
  const location = useLocation();

  return (
    <>
      {!location.pathname.includes(paths.subscription) && (
        <SubscriptionAlertBannerInner />
      )}
    </>
  );
};

export default SubscriptionAlertBanner;
