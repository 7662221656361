import { UserRole } from 'models/Users';
import Space from 'component-library/src/space/Space';
import Modal from 'component-library/src/modal/Modal';
import Select from 'components/select/Select';
import { useProfile } from 'hooks/useProfile';
import { useTranslation } from 'react-i18next';
import { spacing } from 'component-library/src/theme/theme';
import React, { useCallback, useState } from 'react';
import Typography from 'component-library/src/typography/Typography';
import { mainRoleOptions } from 'pages/register/steps/RegisterStep2';
import intercomService from 'services/intercomService';
import { useAuth } from 'hooks/useAuth';
import { mixpanel } from 'tracking/tracking';

const UserRoleModal = () => {
  const { t } = useTranslation();
  const { user, isLoadingState } = useAuth();
  const {
    data: userProfile,
    mutatePatch: updateUserRole,
    isLoading,
  } = useProfile();
  const [userRole, setUserRole] = useState<UserRole | undefined>(
    userProfile?.userRole
  );

  const onSubmit = useCallback(async () => {
    if (userProfile && userRole) {
      await updateUserRole({ userRole });
      intercomService.updateUser(user, userProfile, {
        userRole,
      });
    }
  }, [updateUserRole, user, userProfile, userRole]);

  if (isLoading || isLoadingState) {
    return null;
  }

  return (
    <Modal
      size="s"
      closable={false}
      testId="user-role-modal"
      trackEvent={mixpanel.track.bind(mixpanel)}
      open={
        !Boolean(userProfile?.userRole) && !Boolean(user?.isAdminImpersonating)
      }
      headerText={t('userSegmentation.userRoleModal.heading')}
      footerButtons={[
        {
          children: t('general.submit'),
          disabled: Boolean(!userRole),
          onClick: onSubmit,
          testId: 'user-role-submit-button',
        },
      ]}
    >
      <Space size={spacing.space32} direction="vertical">
        <Typography type="body" size="m">
          {t('userSegmentation.userRoleModal.description')}
        </Typography>
        <Select
          allowClear
          showSearch
          size="large"
          testId="user-role-selector"
          dropdownClassName="user-role-select-dropdown"
          placeholder={t('userSegmentation.mainRoleOptionsPlaceholder')}
          onSelect={(role: UserRole) => setUserRole(role)}
          options={mainRoleOptions.map(({ key, value }) => ({
            optionKey: key,
            value,
            label: t(`userSegmentation.mainRoleOptions.${key}`),
            testId: `user-role-selector-${value}`,
          }))}
        />
      </Space>
    </Modal>
  );
};

export default UserRoleModal;
