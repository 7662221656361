import React, { useState, createRef } from 'react';
import { ClockCircleOutlined } from '@ant-design/icons';
import Input, { InputProps, InputRef } from 'antd/lib/input';
import dayjs, { Dayjs } from 'dayjs';
import InputText from '../input-text/InputText';
import { useDebouncedEffect } from '../hooks/useDebouncedEffect';

export interface TimeInputProps
  extends Omit<
    InputProps,
    'allowClear' | 'defaultValue' | 'value' | 'onChange' | 'placeholder'
  > {
  testId?: string;
  value?: Dayjs;
  defaultValue?: Dayjs;
  onChange?: (time: Dayjs | undefined) => void;
  placeholderMinutes?: string;
  placeholderSeconds?: string;
  showIcon?: boolean;
}

const TimeInput: React.FC<React.PropsWithChildren<TimeInputProps>> = ({
  testId,
  value,
  onChange = () => null,
  defaultValue,
  placeholderMinutes = '00',
  placeholderSeconds = '00',
  showIcon = false,
  ...props
}) => {
  const originalValue = defaultValue || value;
  const originalValueFormatted = originalValue?.format('mm:ss');
  const minuteRef = createRef<InputRef>();
  const secondRef = createRef<InputRef>();
  const [minutes, setMinute] = useState<string | undefined>(
    originalValue?.format('mm')
  );
  const [seconds, setSeconds] = useState<string | undefined>(
    originalValue?.format('ss')
  );

  useDebouncedEffect(
    () => {
      if (originalValue && !originalValue?.isValid()) {
        return;
      }
      if (originalValue?.format('mm') != minutes) {
        setMinute(originalValue?.format('mm'));
      }
      if (originalValue?.format('ss') != seconds) {
        setSeconds(originalValue?.format('ss'));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    500,
    [originalValueFormatted]
  );

  const setTime = (
    minutes: string | undefined,
    seconds: string | undefined
  ) => {
    const time = dayjs()
      .startOf('day')
      .set('minutes', parseInt(minutes || '0'))
      .set('seconds', parseInt(seconds || '0'));
    onChange(time);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace') {
      if (!seconds || seconds?.length === 0) minuteRef.current?.focus();
    }
  };

  return (
    <div className="w-100" data-testid={testId}>
      <Input.Group
        compact
        className={`ant-input time-input-group ${
          props.disabled ? 'time-input-group-disabled' : ''
        }`}
      >
        <InputText
          {...props}
          ref={minuteRef}
          value={minutes}
          onChange={(e) => {
            setMinute(e.target.value);
            setTime(e.target.value, seconds);
            if (e.target.value.length === 2) {
              secondRef.current?.focus();
            }
          }}
          className="time-input-minute"
          placeholder={placeholderMinutes}
          maxLength={2}
          testId="time-input-minutes"
        />
        <span className="time-input-colon">:</span>
        <InputText
          {...props}
          ref={secondRef}
          value={seconds}
          onKeyDown={handleKeyDown}
          onChange={(e) => {
            setSeconds(e.target.value);
            setTime(minutes, e.target.value);
          }}
          className="time-input-second"
          placeholder={placeholderSeconds}
          maxLength={2}
          testId="time-input-seconds"
        />
        {showIcon ? (
          <span className="time-input-icon">
            <ClockCircleOutlined />
          </span>
        ) : null}
      </Input.Group>
    </div>
  );
};

export default TimeInput;
