import { datadogRum } from '@datadog/browser-rum';
import * as Sentry from '@sentry/react';
import axios, { Axios, AxiosRequestConfig } from 'axios';
import authentication from 'helper/authentication/authentication';
import { ApiSpec } from 'specs/api-spec';

export type FeatureFlagCheck = ApiSpec['schemas']['FeatureFlagCheck'];

export class FeatureFlagService {
  featureFlagService: Axios;

  constructor(featureFlagService: Axios) {
    this.featureFlagService = featureFlagService;
  }

  public async checkFeatureFlag(name: string): Promise<FeatureFlagCheck> {
    const { data } = await this.featureFlagService.post<FeatureFlagCheck>(
      `/featureFlags/check`,
      {
        name,
        origin: 'WEB_APP',
      }
    );

    return data;
  }
}

const featureFlagInstance = axios.create({
  baseURL: process.env.REACT_APP_API_DOMAIN,
  timeout: 0,
});

featureFlagInstance.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    // gets impersonated user if available
    const impersonatedUser = localStorage.getItem('impersonatedUser');
    // getIdToken also refreshes token if the access token has expired
    const authTokenBearer = await authentication.getFirebaseIdToken();

    return {
      ...config,
      headers: {
        ...config.headers,
        ...(authTokenBearer && { Authorization: `Bearer ${authTokenBearer}` }),
        ...(impersonatedUser && { 'X-Impersonate': impersonatedUser }),
      },
    };
  },
  (error) => {
    Sentry.captureException(error);
    datadogRum.addError(error);
    return Promise.reject(error);
  }
);

export default new FeatureFlagService(featureFlagInstance);
