import React, { ReactNode } from 'react';
import { Row, Col } from 'antd';
import {
  ArrowLeftOutlined,
  HomeOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Layout from 'components/layout/Layout';
import Container from 'components/container/Container';
import { ReactComponent as ErrorSVG } from 'components/error/img/400error.svg';
import { ReactComponent as NotFoundSVG } from 'components/error/img/404error.svg';
import { ReactComponent as NetworkErrorSVG } from 'components/error/img/500error.svg';
import Button from 'component-library/src/button/Button';
import Typography from 'component-library/src/typography/Typography';
import Space from 'component-library/src/space/Space';
import { spacing } from 'component-library/src/theme/theme';
import { mixpanel } from 'tracking/tracking';

interface ErrorFallbackProps {
  title: string;
  subtitle: string;
  actionIcon: ReactNode;
  actionText: string;
  handleClick: () => void;
  errorImg: ReactNode;
}

const ErrorFallback: React.FC<React.PropsWithChildren<ErrorFallbackProps>> = ({
  title,
  subtitle,
  actionIcon,
  actionText,
  handleClick,
  errorImg,
}) => {
  return (
    <Layout className="error-layout" data-testid="error-page-fallback">
      <Container withPadding>
        <Row className="error-container">
          <Col className="error-content" xs={24} sm={24} md={24} lg={12}>
            <Space direction="vertical" size={spacing.space32}>
              <Typography
                size="xl"
                type="headline"
                color="secondary7"
                data-testid="error-page-title"
                customClass="mb-0"
              >
                {title}
              </Typography>

              <Typography size="s" type="body" color="neutral8">
                {subtitle}
              </Typography>

              <div>
                <Button
                  icon={actionIcon}
                  type="primary"
                  size="l"
                  onClick={handleClick}
                  trackEvent={mixpanel.track.bind(mixpanel)}
                >
                  {actionText}
                </Button>
              </div>
            </Space>
          </Col>

          <Col className="error-img" xs={24} sm={24} md={24} lg={12}>
            {errorImg}
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export const NotFoundError: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <ErrorFallback
      title={t('errorPage.notFoundTitle')}
      subtitle={t('errorPage.notFoundDescription')}
      actionIcon={<HomeOutlined />}
      actionText={t('errorPage.notFoundButton')}
      errorImg={<NotFoundSVG />}
      handleClick={(): void => {
        navigate('/');
      }}
    />
  );
};

export const StandardError: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <ErrorFallback
      title={t('errorPage.errorTitle')}
      subtitle={t('errorPage.errorDescription')}
      actionIcon={<ArrowLeftOutlined />}
      actionText={t('errorPage.errorButton')}
      errorImg={<ErrorSVG />}
      handleClick={(): void => {
        navigate(-1);
        location.reload();
      }}
    />
  );
};

export const NetworkError: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { t } = useTranslation();
  return (
    <ErrorFallback
      title={t('errorPage.500Title')}
      subtitle={t('errorPage.500Description')}
      actionIcon={<ReloadOutlined />}
      actionText={t('errorPage.500Button')}
      errorImg={<NetworkErrorSVG />}
      handleClick={(): void => {
        window.location.reload();
      }}
    />
  );
};
