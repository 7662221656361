import React, { useEffect, useRef, useState } from 'react';
import Container from 'components/container/Container';
import Layout from 'components/layout/Layout';
import Subheader from 'components/subheader/Subheader';
import { useTranslation } from 'react-i18next';
import Section from 'components/section/Section';
import Typography from 'component-library/src/typography/Typography';
import Card from 'components/card/Card';
import Space from 'component-library/src/space/Space';
import { spacing } from 'component-library/src/theme/theme';
import Button from 'component-library/src/button/Button';
import { useUserIsSubscribed } from 'hooks/useUserIsSubscribed';
import { DownOutlined, LockOutlined, UpOutlined } from '@ant-design/icons';
import SectionAlert from 'components/section-alert/SectionAlert';
import { mixpanel } from 'tracking/tracking';
import { useSubscriptionModalContext } from 'features/subscriptions/SubscriptionModalContext';
import {
  AuraleLogo,
  GrooverLogo,
  SubmithubLogo,
  UnhurdLogo,
} from 'features/landing-page/assets';
import {
  AURALE_TYPEFORM,
  GROOVER_LINK,
  SUBMITHUB_LINK,
  UNHURD_LINK,
} from 'features/landing-page/constants';
import { useFeatureFlagCheck } from 'hooks/useFeatureFlagCheck';
import classNames from 'classnames';

interface PromoPartnerProps {
  backgroundColor?: string;
  button: string;
  description: string;
  logo: React.JSX.Element;
  logoBackgroundColor?: string;
  sectionTitle?: string;
  testId: string;
  title: string;
  url: string;
  featureFlag?: boolean;
}

const PromoPartnerContent: React.FC<React.PropsWithChildren<{
  promoPartnerProps: PromoPartnerProps;
  isUserSubscribed: boolean;
}>> = ({
  promoPartnerProps: {
    backgroundColor,
    button,
    description,
    logo,
    sectionTitle,
    testId,
    title,
    url,
    featureFlag,
  },
  isUserSubscribed,
}) => {
  const { t } = useTranslation();
  const descriptionRef = useRef<HTMLSpanElement>(null);
  const [isClamped, setIsClamped] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    if (descriptionRef.current) {
      const isOverflowing =
        descriptionRef.current.scrollHeight >
        descriptionRef.current.clientHeight;
      setIsClamped(isOverflowing);
    }
  }, []);

  const handleReadMoreClick = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      {(featureFlag === undefined || featureFlag) && (
        <>
          {Boolean(sectionTitle) && (
            <div key={sectionTitle} className="promo-partners-section-title">
              <Typography type="label" size="default">
                {sectionTitle?.toUpperCase()}
              </Typography>
            </div>
          )}
          <Card
            key={url}
            cardSize="sm"
            testId={testId}
            customClass="promo-partners-card"
          >
            <Space size={spacing.space8} direction="vertical">
              <div style={{ backgroundColor }} className="promo-partners-logo">
                {logo}
              </div>
              <Typography type="headline" size="xs" color="neutral9">
                {title}
              </Typography>
              <Typography
                type="body"
                size="m"
                color="neutral8"
                customClass={classNames(
                  'promo-partner-description',
                  isExpanded ? 'expanded' : ''
                )}
                ref={descriptionRef}
              >
                {description}
              </Typography>

              <div className={classNames('pt-1', isClamped ? 'pb-1' : 'pb-5')}>
                {isClamped && !isExpanded && (
                  <Button
                    size="s"
                    iconAlign="r"
                    variant="text"
                    type="secondary"
                    icon={<DownOutlined />}
                    onClick={handleReadMoreClick}
                  >
                    {t('general.seeMore')}
                  </Button>
                )}
                {isExpanded && (
                  <Button
                    size="s"
                    iconAlign="r"
                    variant="text"
                    type="secondary"
                    icon={<UpOutlined />}
                    onClick={handleReadMoreClick}
                  >
                    {t('general.seeLess')}
                  </Button>
                )}
              </div>

              <a
                data-testid="promo-partners-button"
                target="_blank"
                rel="noopener noreferrer"
                href={isUserSubscribed ? url : ''}
                title={`${title} link`}
                onClick={(e) => !isUserSubscribed && e.preventDefault()}
              >
                <Button
                  className="mt-2"
                  testId={`${testId}-button`}
                  icon={!isUserSubscribed ? <LockOutlined /> : null}
                  type={isUserSubscribed ? 'primary' : 'secondary'}
                  variant={isUserSubscribed ? 'filled' : 'outline'}
                  disabled={!isUserSubscribed}
                  size="m"
                  trackEvent={mixpanel.track.bind(mixpanel)}
                >
                  {button}
                </Button>
              </a>
            </Space>
          </Card>
        </>
      )}
    </>
  );
};

const PromoPartners: React.FC<React.PropsWithChildren<unknown>> = () => {
  const {
    t,
    i18n: {
      languages: [language],
    },
  } = useTranslation();
  const modalContext = useSubscriptionModalContext();
  const { isUserSubscribed } = useUserIsSubscribed();
  const { value: unHurdAvailable, loading } = useFeatureFlagCheck(
    'unhurd-partner'
  );

  const promoPartners: PromoPartnerProps[] = [
    {
      button: t('landingPageSmart.featureAnnouncePromoPartners.groover.button'),
      description: t(
        'landingPageSmart.featureAnnouncePromoPartners.groover.description'
      ),
      logo: <GrooverLogo />,
      logoBackgroundColor: 'white',
      sectionTitle: t(
        'landingPageSmart.featureAnnouncePromoPartners.groover.sectionTitle'
      ),
      testId: 'promo-partners-groover',
      title: t('landingPageSmart.featureAnnouncePromoPartners.groover.title'),
      url: GROOVER_LINK,
    },
    {
      button: t(
        'landingPageSmart.featureAnnouncePromoPartners.submithub.button'
      ),
      description: t(
        'landingPageSmart.featureAnnouncePromoPartners.submithub.description'
      ),
      logo: <SubmithubLogo />,
      logoBackgroundColor: '#9477D1',
      testId: 'promo-partners-submithub',
      title: t('landingPageSmart.featureAnnouncePromoPartners.submithub.title'),
      url: SUBMITHUB_LINK,
    },
    {
      button: t('landingPageSmart.featureAnnouncePromoPartners.aurale.button'),
      description: t(
        'landingPageSmart.featureAnnouncePromoPartners.aurale.description'
      ),
      logo: <AuraleLogo />,
      logoBackgroundColor: 'white',
      sectionTitle: t(
        'landingPageSmart.featureAnnouncePromoPartners.aurale.sectionTitle'
      ),
      testId: 'promo-partners-aurale',
      title: t('landingPageSmart.featureAnnouncePromoPartners.aurale.title'),
      url: AURALE_TYPEFORM[language],
    },
    {
      button: t('landingPageSmart.featureAnnouncePromoPartners.unhurd.button'),
      description: t(
        'landingPageSmart.featureAnnouncePromoPartners.unhurd.description'
      ),
      featureFlag: unHurdAvailable,
      logo: <img src={UnhurdLogo} alt="" />,
      logoBackgroundColor: 'white',
      testId: 'promo-partners-unhurd',
      title: t('landingPageSmart.featureAnnouncePromoPartners.unhurd.title'),
      url: UNHURD_LINK,
    },
  ];

  return (
    <Layout
      isLoading={loading}
      header={<Subheader title={t('promoPartners.title')} />}
    >
      <Container withPadding>
        <Section
          className="promo-partners-content"
          title={t('promoPartners.subtitle')}
          subtitle={t('promoPartners.description')}
        >
          {!isUserSubscribed && (
            <SectionAlert
              testId="promo-partners-subscribe-banner"
              type="accent"
              customClassName="w-100 mb-8"
              description={t(
                'landingPageSmart.featureAnnouncePromoPartnerModal.unsubsBannerDescription'
              )}
              actionButton={{
                text: t(
                  'landingPageSmart.featureAnnouncePromoPartnerModal.unsubsBannerButton'
                ),
                testId: 'promo-partners-subscribe-banner-button',
                onClick: () => {
                  mixpanel.track({
                    event: 'SUBSCRIPTIONS_CTA',
                    element: 'promo-partners-subscribe-banner',
                  });
                  modalContext.dispatch({ type: 'subscriptionsFaq' });
                },
              }}
            />
          )}
          {promoPartners.map((promoPartnerProps) => (
            <PromoPartnerContent
              key={promoPartnerProps.testId}
              promoPartnerProps={promoPartnerProps}
              isUserSubscribed={isUserSubscribed}
            />
          ))}
        </Section>
      </Container>
    </Layout>
  );
};

export default PromoPartners;
