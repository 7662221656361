import React, { useEffect } from 'react';
import { Space } from 'antd';
import Typography from 'component-library/src/typography/Typography';
import Button from 'component-library/src/button/Button';
import { spacing } from 'component-library/src/theme/theme';
import { PeepPolkaDot } from 'assets/img';
import { useProfile } from 'hooks/useProfile';
import { useTranslation } from 'react-i18next';
import { mixpanel } from 'tracking/tracking';
import { useSubscriptionModalContext } from 'features/subscriptions/SubscriptionModalContext';
import Modal from 'component-library/src/modal/Modal';

interface SubscriptionReactivateSuccessModalProps {
  handleClose: () => void;
}

const SubscriptionReactivateSuccessModal: React.FC<React.PropsWithChildren<
  SubscriptionReactivateSuccessModalProps
>> = ({ handleClose }) => {
  const modalContext = useSubscriptionModalContext();
  const { data } = useProfile();
  const { t } = useTranslation();

  useEffect(() => {
    mixpanel.track({
      event: 'REACTIVATE_SUCCESS_MODAL_OPEN',
    });
  }, []);

  return (
    <Modal
      onCancel={() => modalContext.dispatch({ type: 'close' })}
      variant="marketing"
      size="m"
      headerText={t('subscriptions.reactivateSubscriptionSuccessTitle', {
        name: data?.firstName || '',
      })}
      trackEvent={mixpanel.track.bind(mixpanel)}
      fixedImage={<PeepPolkaDot title="Success!" />}
      className="subscription-modal"
      open
      closable
    >
      <Space
        size={spacing.space40}
        direction="vertical"
        className="w-100 text-align-c"
        data-testid="subscribe-reactivate-success-modal"
      >
        <Typography
          block
          type="body"
          size="m"
          responsiveFont={false}
          color="neutral8"
          customClass="w-100 text-align-c"
        >
          {t('subscriptions.reactivateSubscriptionSuccessSub')}
        </Typography>

        <Typography
          block
          type="body"
          size="m"
          responsiveFont={false}
          color="neutral8"
          customClass="w-100 text-align-c"
        >
          {t('subscriptions.reactivateSubscriptionSuccessSub2')}
        </Typography>

        <Button
          type="primary"
          variant="filled"
          data-testid="subscribe-reactivate-success-modal-button"
          onClick={handleClose}
          trackEvent={mixpanel.track.bind(mixpanel)}
        >
          {t('subscriptions.reactivateSubscriptionSuccessButton')}
        </Button>
      </Space>
    </Modal>
  );
};

export default SubscriptionReactivateSuccessModal;
