import { useAuth } from 'hooks/useAuth';
import { useFeatureFlagCheck } from 'hooks/useFeatureFlagCheck';
import { SubscriptionCampaignType } from 'models/Subscription';

export const PRICING_EXPERIMENT_ON_VARIANT = 'B';

export const usePricingExperiment = (): {
  loading: boolean;
  value: boolean;
  campaign?: SubscriptionCampaignType;
} => {
  const {
    value: isPricingExperimentOn,
    loading: isPricingExperimentOnLoading,
  } = useFeatureFlagCheck('pricing-experiment');
  const { user } = useAuth();

  const showExperimentPricing =
    isPricingExperimentOn &&
    user.pricingExperimentVariant === PRICING_EXPERIMENT_ON_VARIANT;

  const campaign =
    !isPricingExperimentOn || !user.pricingExperimentVariant
      ? undefined
      : (`pricing-experiment-variant-${user.pricingExperimentVariant.toLowerCase()}` as SubscriptionCampaignType);

  return {
    loading: isPricingExperimentOnLoading,
    value: showExperimentPricing,
    campaign,
  };
};
