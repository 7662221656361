import { paths } from 'config/routes';
import { useAuth } from 'hooks/useAuth';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import authentication from 'helper/authentication/authentication';

const WebviewLogin = () => {
  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const postMessageToApp = async () => {
      if (window.ReactNativeWebView) {
        if (auth.isLoadingState) {
          return;
        }
        if (auth.user.isLoggedIn) {
          window.ReactNativeWebView.postMessage(
            JSON.stringify({
              userId: auth.userProfile?.userId,
              firstName: auth.userProfile?.firstName,
              lastName: auth.userProfile?.lastName,
              isLoggedIn: auth.user.isLoggedIn,
              accessToken: await authentication.getFirebaseIdToken(),
            })
          );
          return;
        }
      }
    };
    if (auth.user.isLoggedIn) {
      postMessageToApp();
    } else {
      navigate(`${paths.login}?redirectUrl=${paths.authWebview}`);
    }
  }, [auth, navigate]);

  return null;
};

export default WebviewLogin;
