import { Drawer } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from 'assets/img/logo.svg';
import { CloseOutlined } from '@ant-design/icons';
import theme from 'component-library/src/theme/theme';
import { spacing } from 'component-library/src/theme/theme';
import InnerMenu from 'features/navigation/InnerMenu';
import { MenuClickEventHandler, MenuInfo } from 'rc-menu/lib/interface';

interface MobileMenuDrawerProps {
  currentMenuKey: string;
  isOpen: boolean;
  openKeys?: React.Key[];
  setIsMenuOpen: () => void;
  handleSignOut: () => void;
  setOpenKeys: (keys?: React.Key[]) => void;
  handleItemClick: MenuClickEventHandler;
}

const MobileMenuDrawer: React.FC<React.PropsWithChildren<
  MobileMenuDrawerProps
>> = ({
  currentMenuKey,
  isOpen,
  openKeys,
  setOpenKeys,
  setIsMenuOpen,
  handleSignOut,
  handleItemClick,
}) => {
  const handleClick = (info: MenuInfo) => {
    handleItemClick(info);
    setIsMenuOpen();
  };

  return (
    <>
      <Drawer
        closeIcon={
          <CloseOutlined
            style={{
              fontSize: spacing.space24,
              color: theme.colors.secondary5,
            }}
            onClick={setIsMenuOpen}
            data-testid="mobile-hamburger-menu-opened"
          />
        }
        title={
          <div className="drawer-header__container">
            <div className="logo">
              <Link to={'/'} data-testid="nav-logo-mobile" title="Home link">
                <span className="sr-only">Home link</span>
                <Logo color={theme.colors.secondaryColor} />
              </Link>
            </div>
          </div>
        }
        placement="right"
        className="mobile-menu-drawer"
        open={isOpen}
        width="100vw"
      >
        <InnerMenu
          currentMenuKey={currentMenuKey}
          onItemClick={handleClick}
          setOpenKeys={setOpenKeys}
          onSignOut={handleSignOut}
          openKeys={openKeys}
          isCollapsed={false}
        />
      </Drawer>
    </>
  );
};

export default MobileMenuDrawer;
