import api from 'helper/api/api';
import { Track, TrackRequest } from 'models/Track';
import { toTrack } from 'models/responses/ReleaseResponseData';
import { ReleaseTrackRequest, ReleaseTrackResponse } from 'models/Release';
import { toTrackRequestData } from 'models/requests/TrackRequestData';

interface ReleaseTrack {
  trackNumber: number | undefined;
  track: Track | undefined;
}

export class ReleaseTrackService {
  sortTrackList(tracklist: ReleaseTrackResponse[]): ReleaseTrackResponse[] {
    return [...tracklist].sort(
      (a, b) => (a.trackNumber || -1) - (b.trackNumber || -1)
    );
  }

  tracksToReleaseTracksRequest(tracks: Track[]): ReleaseTrackRequest[] {
    return [...tracks].map((t, index) => ({
      trackId: t?.id as string,
      trackNumber: index + 1,
    }));
  }

  toReleaseTrack(releaseTrackResponse: ReleaseTrackResponse): ReleaseTrack {
    return {
      trackNumber: releaseTrackResponse.trackNumber,
      track: toTrack(releaseTrackResponse.track),
    };
  }

  toTracklist(releaseTracks: ReleaseTrack[]): Track[] {
    return releaseTracks.map((track) => ({
      ...track.track,
    }));
  }

  // Get all releaseTrack entities for a release by ID
  async getTrackList(id: string): Promise<ReleaseTrack[]> {
    const releaseTracks = await api.release.getReleaseTracks(id);
    return this.sortTrackList(releaseTracks.data).map(
      this.toReleaseTrack.bind(this)
    );
  }

  // Update the releaseTrack list
  async updateTrackList(
    id: string,
    tracks: Track[]
  ): Promise<ReleaseTrackResponse[]> {
    const releaseTracks = await api.release.updateReleaseTracks(
      id,
      this.tracksToReleaseTracksRequest(tracks)
    );
    return this.sortTrackList(releaseTracks.data);
  }

  async createReleaseTrack(
    id: string,
    track: Track
  ): Promise<ReleaseTrackResponse> {
    const releaseTrack = await api.release.createReleaseTrack(
      id,
      toTrackRequestData(track, false) as TrackRequest
    );
    return releaseTrack.data;
  }
}

export default new ReleaseTrackService();
