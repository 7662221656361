export const UPLOAD_CERTIFICATE_GEMA_EXPERIMENT_ID = 'upload_certificate_gema';
export const UPLOAD_CERTIFICATE_GEMA_EXPERIMENT_ON_VARIANT = 'on';
export const UPLOAD_CERTIFICATE_GEMA_EXPERIMENT_OFF_VARIANT = 'off';

interface Experiment {
  id: string;
  variants: string[];
  variantWeights?: number[];
}

export const EXPERIMENTS: Experiment[] = [
  {
    id: UPLOAD_CERTIFICATE_GEMA_EXPERIMENT_ID,
    variants: [
      UPLOAD_CERTIFICATE_GEMA_EXPERIMENT_ON_VARIANT,
      UPLOAD_CERTIFICATE_GEMA_EXPERIMENT_OFF_VARIANT,
    ],
    variantWeights: [0, 100],
  },
];
