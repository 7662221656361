import useSWR, { SWRResponse } from 'swr';
import { UserFeatureResponse } from '../models/Users';
import { AxiosError, AxiosResponse } from 'axios';
import api from '../helper/api/api';

export interface UseUserFeaturesReturn
  extends Pick<SWRResponse<UserFeatureResponse, AxiosError<Error>>, 'error'> {
  data: UserFeatureResponse[] | undefined;
  isLoading: boolean;
}

export const useUserFeatures = (): UseUserFeaturesReturn => {
  const { data, error } = useSWR<UserFeatureResponse[], AxiosError<Error>>(
    '/user/features',
    () =>
      api.account
        .getUserFeatures()
        .then((res: AxiosResponse<UserFeatureResponse[]>) => res.data),
    {
      revalidateOnFocus: false,
    }
  );
  return {
    data,
    isLoading: !data && !error,
    error: error,
  };
};
