import React, { FC, ReactElement } from 'react';
import ReactImageFallback from 'react-image-fallback';

export interface ImageCompProps {
  imageSrc?: string;
  imageAlt?: string;
  loadingType?: 'lazy' | 'eager';
  testId?: string;
  className?: string;
  fallbackImage:
    | string
    | ReactElement
    | string[]
    | Array<string | undefined>
    | ReactElement[];
}

const ImageComp: FC<React.PropsWithChildren<ImageCompProps>> = ({
  imageSrc,
  imageAlt = '',
  loadingType = 'lazy',
  testId,
  className,
  fallbackImage,
}) => (
  <ReactImageFallback
    src={imageSrc}
    alt={imageAlt}
    loading={loadingType}
    fallbackImage={fallbackImage}
    data-testid={testId}
    id={testId}
    className={className}
  />
);

export default ImageComp;
