import React, { useMemo } from 'react';
import { Space as AntdSpace, SpaceProps as AntdSpaceProps } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { spacing } from '../theme/theme';
import { ScreenMap } from 'antd/lib/_util/responsiveObserver';

export interface SpaceProps extends Omit<AntdSpaceProps, 'size'> {
  size?: number | Partial<Record<keyof ScreenMap, number>>;
}

const Space: React.FC<React.PropsWithChildren<SpaceProps>> = ({
  size,
  ...props
}) => {
  const screen = useBreakpoint();

  const currentSpacingSize = useMemo(() => {
    if (typeof size === 'number') return size;
    if (screen.xxl)
      return (
        size?.xxl ?? size?.xl ?? size?.lg ?? size?.md ?? size?.sm ?? size?.xs
      );
    if (screen.xl)
      return size?.xl ?? size?.lg ?? size?.md ?? size?.sm ?? size?.xs;
    if (screen.lg) return size?.lg ?? size?.md ?? size?.sm ?? size?.xs;
    if (screen.md) return size?.md ?? size?.sm ?? size?.xs;
    if (screen.sm) return size?.sm ?? size?.xs;
    if (screen.xs) return size?.xs;
    return;
  }, [screen, size]);

  return <AntdSpace {...props} size={currentSpacingSize || spacing.space4} />;
};

export default Space;
