import React, { useEffect } from 'react';
import { Space } from 'antd';
import Typography from 'component-library/src/typography/Typography';
import { spacing } from 'component-library/src/theme/theme';
import { useTranslation } from 'react-i18next';
import { mixpanel } from 'tracking/tracking';
import { PeepSad } from 'assets/img';
import Button from 'component-library/src/button/Button';
import { useSubscriptionModalContext } from 'features/subscriptions/SubscriptionModalContext';
import { CloseOutlined } from '@ant-design/icons';
import Modal from 'component-library/src/modal/Modal';

export type SubscriptionPaymentErrorModalType = 'card' | 'paypal';

const SubscriptionPaymentErrorModal: React.FC<React.PropsWithChildren<{
  paymentType?: SubscriptionPaymentErrorModalType;
}>> = ({ paymentType = 'card' }) => {
  const { t, i18n } = useTranslation();
  const { error, dispatch } = useSubscriptionModalContext();

  useEffect(() => {
    mixpanel.track({
      event: 'PAYMENT_ERROR_MODAL_OPEN',
      type: paymentType,
    });
  }, [paymentType]);

  return (
    <Modal
      onCancel={() => dispatch({ type: 'close' })}
      variant="marketing"
      size="m"
      trackEvent={mixpanel.track.bind(mixpanel)}
      headerText={
        <>
          <div className="payment-error-icon mb-6">
            <CloseOutlined />
          </div>
          <Typography
            block
            type="headline"
            size="l"
            color="secondary7"
            responsiveFont={false}
            customClass="w-100 text-align-c"
          >
            {t('subscriptions.paymentErrorModal.heading')}
          </Typography>
        </>
      }
      fixedImage={<PeepSad title={error} />}
      className="subscription-modal"
      open
      closable
      centered
    >
      <Space
        size={spacing.space40}
        direction="vertical"
        className="w-100 text-align-c"
        data-testid="payment-error-modal"
      >
        <Typography
          block
          type="body"
          size="m"
          responsiveFont={false}
          color="neutral8"
          customClass="w-100 text-align-c"
          data-testid="payment-error-modal-msg"
        >
          {`${t('subscriptions.paymentErrorModal.genericError')} ${
            i18n.exists(error ?? '') ? t(error ?? '') : error
          }`}
        </Typography>

        <Button
          type="primary"
          variant="filled"
          data-testid="payment-error-modal-button"
          onClick={() => dispatch({ type: 'close' })}
          trackEvent={mixpanel.track.bind(mixpanel)}
        >
          {t('subscriptions.paymentErrorModal.button')}
        </Button>
      </Space>
    </Modal>
  );
};

export default SubscriptionPaymentErrorModal;
