import React from 'react';
import { SubscriptionResponse } from 'models/Subscription';
import UserCondition from 'features/user-condition/UserCondition';

interface SubscriptionConditionalProps {
  fallback?: React.ReactNode;
  // Whether children should be rendered - if other conditions are met
  shouldDisplay: (subscription: SubscriptionResponse | undefined) => boolean;
}

const SubscriptionConditional: React.FC<React.PropsWithChildren<
  SubscriptionConditionalProps
>> = (props) => {
  return (
    <UserCondition
      condition={(user, subscription) =>
        !user.isGemaMember &&
        subscription !== undefined &&
        props.shouldDisplay(subscription)
      }
      fallback={props.fallback}
    >
      {props.children}
    </UserCondition>
  );
};

export default SubscriptionConditional;
