import api from 'helper/api/api';
import { CombinedTrackReleaseStatus } from 'helper/constants/CombinedTrackReleaseStatus';
import {
  Track,
  TracklistPaginated,
  TrackRequest,
  TrackResponse,
  TrackParticipantResponse,
  TrackAudioWavelyzerFeedbackResponse,
  TrackAudioResponse,
  TrackAudioRequest,
  TrackPatchRequest,
  TrackForm,
} from 'models/Track';
import { toTrackRequestData } from 'models/requests/TrackRequestData';
import { toTrack } from 'models/responses/ReleaseResponseData';
import { Dictionary } from 'lodash';
import keyBy from 'lodash/keyBy';
import { Diff, diff } from 'deep-diff';
import { DuplicateTrackError } from 'errors/trackErrors';
import { ParticipantRequest } from 'features/participants/models/Participant';
import intercomService from './intercomService';
import { AlgoliaTrack } from 'features/track/hooks/useTrackSearch.types';
import { AxiosError } from 'axios';
import releaseTrackService from './releaseTrackService';

export class TrackService {
  trackUpdateAllowed(track: Track): boolean {
    return !(
      track.trackStatus === CombinedTrackReleaseStatus.AVAILABLE ||
      track.trackStatus === CombinedTrackReleaseStatus.PENDING ||
      track.trackStatus === CombinedTrackReleaseStatus.APPROVED
    );
  }

  async getTracks(
    statuses: Track['trackStatus'][] = [],
    page: number,
    size: number,
    search: string
  ): Promise<TracklistPaginated> {
    const tracks = await api.tracks.getAll(statuses, page, size, search);
    return {
      tracks: tracks?.data?.tracks
        ? (tracks?.data?.tracks.map(toTrack) as Track[])
        : [],
      meta: tracks.data.meta,
    };
  }

  async getTrack(trackId: string): Promise<Track | undefined> {
    const trackResp = await api.tracks.getSingleTrack(trackId);
    return toTrack(trackResp?.data);
  }

  async createTrack(
    track: Track,
    releaseId?: string
  ): Promise<Track | undefined> {
    const releaseRequest = toTrackRequestData(track, false) as TrackRequest;
    let newTrack: TrackResponse | undefined;
    // If within the context of a shared release and not the owner
    // create the track under the release id so it belongs to the creator
    if (releaseId) {
      newTrack = await releaseTrackService
        .createReleaseTrack(releaseId, track)
        .then((it) => it.track);
    } else {
      newTrack = (await api.tracks.createTrack(releaseRequest)).data;
    }

    const audio = releaseRequest.audio
      ? await this.setTrackAudio(newTrack?.id as string, releaseRequest.audio)
      : undefined;
    if (releaseRequest.audio) {
      intercomService.track('TRACK_AUDIO_ADD', {
        trackId: newTrack?.id,
        audioFilename: releaseRequest.audio.originalFileName,
      });
    }
    return toTrack({ ...newTrack, audio, participants: [] });
  }

  async updateTrack(
    id: string,
    track: Track,
    previousTracks?: Dictionary<Track>
  ): Promise<Track | undefined> {
    if (!this.trackUpdateAllowed(track)) {
      return track;
    }
    const participantsRegex = /authors|artists|participants/gim;
    const trackRequest = toTrackRequestData(track, false) as TrackRequest;
    let audio = trackRequest.audio;
    let participants = trackRequest.participants;
    let changes: Diff<TrackRequest, TrackRequest>[] | undefined = [];
    let updatedTrack = track as TrackResponse;
    let hasPrevious = false;

    if (previousTracks && track.id && previousTracks[track.id]) {
      const previousTrack = toTrackRequestData(
        previousTracks[track.id],
        false
      ) as TrackRequest;
      hasPrevious = true;
      changes = diff(trackRequest, previousTrack)?.filter(
        (diff) =>
          !diff.path?.join('.').includes('.position') &&
          !(
            diff.path?.join('.').includes('preListeningStart') &&
            previousTrack.preListeningStart === undefined &&
            trackRequest.preListeningStart === 0
          )
      );
    }

    try {
      // If there are changes to the track update it
      if (!hasPrevious || changes?.length) {
        updatedTrack = await api.tracks
          .updateTrack(id, trackRequest)
          .then(({ data }) => data);
      }
    } catch (e) {
      const error = e as AxiosError;
      if (error.message.includes('409')) {
        throw new DuplicateTrackError(id);
      }
      throw e;
    }

    const changesOnParticipants = (changes || []).filter(
      (diffField) =>
        diffField.path && diffField.path[0].match(participantsRegex)
    );
    const changesOnAudio = (changes || []).filter(
      (diffField) =>
        (diffField.path || []).join('.') === 'audio.storageFileName' ||
        (diffField.path || []).join('.') === 'audio'
    );

    // If there are changes to the track participants update them
    if (!hasPrevious || !!changesOnParticipants.length) {
      participants = await this.setParticipants(
        updatedTrack?.id as string,
        trackRequest.participants || []
      );
    }

    // If there are changes to the track audio or it's being deleted (only using fileName here to prevent updates)
    if (
      !!changesOnAudio.length ||
      (track.id && !!previousTracks?.[track.id]?.audio && !audio)
    ) {
      intercomService.track('TRACK_AUDIO_ADD', {
        trackId: updatedTrack.id,
        audioFilename: trackRequest?.audio?.originalFileName,
      });
      audio = await this.setTrackAudio(
        updatedTrack?.id as string,
        trackRequest.audio
      );
    }

    return toTrack({
      ...updatedTrack,
      audio: audio,
      participants,
    });
  }

  async patchTrack(
    id: string,
    track: TrackPatchRequest
  ): Promise<Track | undefined> {
    const res = await api.tracks.patchTrack(id, track);
    return toTrack(res.data);
  }

  async updateTracks(
    tracks: Track[],
    previousTracks: Track[]
  ): Promise<(Track | undefined)[]> {
    const prevTrackMap = previousTracks.length
      ? keyBy(previousTracks, 'id')
      : undefined;
    return Promise.all(
      tracks.map((track) =>
        this.updateTrack(track.id as string, track, prevTrackMap)
      )
    ) as Promise<(Track | undefined)[]>;
  }

  async deleteTrack(track: Track | undefined): Promise<void> {
    if (!track || !track?.id) return;
    await api.tracks.deleteTrack(track.id);
  }

  async deleteAlgoliaTrack(track: AlgoliaTrack | undefined): Promise<void> {
    if (!track || !track?.objectID) return;
    await api.tracks.deleteTrack(track.objectID);
  }

  async deleteTrackAudio(trackId: string): Promise<void> {
    if (!trackId) return;
    await api.tracks.deleteTrackAudio(trackId);
  }

  async getTrackAudio(id: string): Promise<TrackAudioResponse | undefined> {
    const newTrack = await api.tracks.getTrackAudio(id);
    return newTrack.data;
  }

  async setTrackAudio(
    id: string,
    audio?: TrackAudioRequest
  ): Promise<TrackAudioResponse | undefined> {
    if (!audio) {
      try {
        await this.deleteTrackAudio(id);
        return undefined;
      } catch (e) {
        return undefined;
      }
    }
    const trackAudio = await api.tracks.setTrackAudio(id, audio);
    return trackAudio.data;
  }

  async setParticipants(
    id: string,
    participants: ParticipantRequest[]
  ): Promise<TrackParticipantResponse[] | undefined> {
    const trackParticipants = await api.tracks.setTrackParticpants(
      id,
      participants
    );
    return trackParticipants.data;
  }

  async getTrackWavealyzerFeedback(
    trackId: string
  ): Promise<TrackAudioWavelyzerFeedbackResponse | undefined> {
    const trackResp = await api.tracks.getTrackWavealyzerFeedback(trackId);
    return trackResp?.data;
  }

  async copyTrackFromTo(from: Track, to: Track): Promise<void> {
    if (this.trackUpdateAllowed(to)) {
      await this.updateTrack(
        to.id as string,
        {
          ...from,
          id: to.id,
          title: {
            ...from.title,
            title: to.title?.title || '',
          },
          audio: to.audio,
          isrc: to.isrc || undefined,
        },
        {
          [to.id as string]: { ...to },
        }
      );
    }
  }

  isTrackEditable(track?: Track) {
    return (
      !!track?.trackStatus &&
      ['DRAFT', 'READY', 'RETURNED'].includes(track?.trackStatus)
    );
  }

  // toTrackRequest does not send all fields so this can be set for form reasons and ignored
  toTrackForm(track: Track): TrackForm {
    return {
      ...track,
      hasIsrc: Boolean(track.isrc),
      hasIswc: Boolean(track.iswc),
      isPreviouslyReleased: Boolean(track.originalReleaseDate),
    };
  }
}

export default new TrackService();
