import { Space, Row } from 'antd';
import Typography from 'component-library/src/typography/Typography';
import { spacing } from 'component-library/src/theme/theme';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Loading3QuartersOutlined } from '@ant-design/icons';
import theme from 'component-library/src/theme/theme';
import { useSubscriptionModalContext } from 'features/subscriptions/SubscriptionModalContext';
import Modal from 'component-library/src/modal/Modal';
import { mixpanel } from 'tracking/tracking';

interface SubscriptionProcessingProps {
  type: 'PAYMENT' | 'REACTIVATION';
}

const SubscriptionProcessing: React.FC<React.PropsWithChildren<
  SubscriptionProcessingProps
>> = ({ type }) => {
  const { dispatch } = useSubscriptionModalContext();
  const { t } = useTranslation();

  return (
    <Modal
      onCancel={() => dispatch({ type: 'close' })}
      variant="marketing"
      trackEvent={mixpanel.track.bind(mixpanel)}
      size="m"
      headerText={
        type === 'PAYMENT'
          ? t('subscriptions.subscriptionProcessingHeader')
          : t('subscriptions.subscriptionProcessingReactivationHeader')
      }
      className="subscription-modal"
      open
      closable
      centered
    >
      <Space
        size={spacing.space32}
        direction="vertical"
        className="w-100 subscription-processing"
        data-testid="subscription-processing"
      >
        <Typography
          type="body"
          size="m"
          weightLevel="400"
          color="neutral8"
          customClass="w-100"
        >
          {t('subscriptions.subscriptionProcessingBody')}
        </Typography>
        <Row justify="center">
          <Space size="middle">
            <Loading3QuartersOutlined
              spin
              style={{ fontSize: 80, color: theme.colors.secondary4 }}
            />
          </Space>
        </Row>
      </Space>
    </Modal>
  );
};

export default SubscriptionProcessing;
