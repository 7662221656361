// import getQueryString from 'helper/querystring/getQueryString';
import { useSearchParams } from 'react-router-dom';
import { useEffect, useState, useCallback } from 'react';

export interface UseQueryStringReturn<T> {
  paramValue: T;
  update: (paramValue?: T | null) => void;
  mounted: boolean;
}

// TODO: to be deprecated we should move to using useSearchParams at a page/component level as we now have issues managing multiple qs in parallel
export const useQueryString = <T>(
  query: string,
  format?: (paramValue: string | null) => T
): UseQueryStringReturn<T | null> => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [mounted, setMounted] = useState(false);
  const [paramValue, setParamValue] = useState<T | null>(null);
  const formatParamValue = format || ((val) => (val as unknown) as T);

  const getParam = (query: string): string | null => {
    const param = searchParams.get(query);
    try {
      const parsedParam = JSON.parse(param || '');
      return parsedParam;
    } catch (e) {
      return param;
    }
  };

  useEffect(() => {
    const qs = getParam(query);
    setParamValue(formatParamValue(qs));
    setMounted(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, searchParams]);

  const update = useCallback(
    (newParamValue?: T | null) => {
      setSearchParams((previous) => {
        if (typeof newParamValue !== 'undefined' && newParamValue !== null) {
          previous.set(
            query,
            typeof newParamValue !== 'object'
              ? String(newParamValue)
              : JSON.stringify(newParamValue)
          );
        } else {
          previous.delete(query);
        }
        return previous;
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history, query]
  );

  return {
    paramValue,
    update,
    mounted,
  };
};
