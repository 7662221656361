import React from 'react';
import Typography, {
  CustomTypographyProps,
} from 'component-library/src/typography/Typography';
import classNames from 'classnames';
import Space from 'component-library/src/space/Space';
import { spacing } from 'component-library/src/theme/theme';

interface CardProps {
  heading?: React.ReactNode;
  headingSize?: 'xs' | 's';
  headingEllipsis?: CustomTypographyProps['ellipsis'];
  headingBlock?: CustomTypographyProps['block'];
  headingColor?: CustomTypographyProps['color'];
  subheading?: React.ReactNode;
  customClass?: string;
  cardSize?: 'sm' | 'md' | 'lg';
  testId?: string;
  subheadingEllipsis?: boolean;
  extra?: React.ReactNode;
}

const Card: React.FC<React.PropsWithChildren<CardProps>> = ({
  children,
  customClass,
  heading,
  headingColor,
  subheading,
  headingSize = 'xs',
  testId,
  cardSize = 'md',
  headingEllipsis = false,
  subheadingEllipsis = false,
  headingBlock = false,
  extra,
}) => (
  <div
    className={classNames(
      'custom-card',
      `custom-card-${cardSize}`,
      customClass
    )}
    data-testid={testId ?? ''}
  >
    {Boolean(heading) ? (
      <Space
        className="custom-card__header w-100"
        direction="vertical"
        size={spacing.space8}
      >
        <div className="custom-card__heading">
          <Typography
            block={headingBlock}
            size={headingSize}
            type="headline"
            data-testid={`${testId}-heading`}
            ellipsis={headingEllipsis}
            color={headingColor}
            level={3}
          >
            {heading}
          </Typography>
          {extra ?? extra}
        </div>

        {subheading && (
          <Typography
            size="s"
            type="body"
            color="neutral8"
            data-testid={`${testId}-subheading`}
            ellipsis={subheadingEllipsis}
          >
            {subheading}
          </Typography>
        )}
      </Space>
    ) : (
      <></>
    )}
    <div className="custom-card__content">{children}</div>
  </div>
);

export default Card;
