import React from 'react';
import { Input as AntdInput, InputRef } from 'antd';
import { InputProps as AntdInputProps } from 'antd/lib/input';
import classnames from 'classnames';

export interface InputTextProps extends Omit<AntdInputProps, 'size'> {
  testId?: string;
  size?: AntdInputProps['size'];
}

const InputText = React.forwardRef<InputRef, InputTextProps>(
  (
    { testId, prefix, suffix, size = 'large', ...props }: InputTextProps,
    ref
  ): JSX.Element => (
    <AntdInput
      {...props}
      ref={ref}
      prefix={prefix}
      suffix={suffix}
      size={size}
      className={classnames(
        props.className,
        `custom-input`,
        `custom-input-${size}`,
        Boolean(props.type) ? `custom-input-${props.type}` : ''
      )}
      data-testid={testId}
    />
  )
);

export default InputText;
