function isIE(): boolean {
  const ua = window.navigator.userAgent;

  const msie = ua.indexOf('MSIE ');
  const trident = ua.indexOf('Trident/');

  if (msie > 0 || trident > 0) {
    return true;
  }

  // other browser
  return false;
}

if (isIE()) {
  const rootEl: HTMLElement | null = document.getElementById('root');
  if (rootEl) rootEl.style.display = 'none';

  const ieErrorEl: HTMLElement | null = document.getElementById('ie-error');
  if (ieErrorEl) ieErrorEl.style.display = 'block';
} else {
  require('index-render');
}

export default {};
