import { useMemo } from 'react';
import Grid from 'antd/es/grid';
import { isMdScreen, isSmScreen, isXsScreen } from 'helper/styling/styling';

const useIsMobileCheck = (): boolean => {
  const screens = Grid.useBreakpoint();
  return useMemo(
    () => (isXsScreen(screens) || isSmScreen(screens)) && !isMdScreen(screens),
    [screens]
  );
};

export default useIsMobileCheck;
