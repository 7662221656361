import React, { useEffect } from 'react';
import SectionAlert, {
  SectionAlertProps,
} from 'components/section-alert/SectionAlert';
import Typography from 'component-library/src/typography/Typography';
import classNames from 'classnames';
import { mixpanel } from 'tracking/tracking';

interface BannerProps extends SectionAlertProps {
  fireEventOnMount?: boolean;
}

const Banner = ({
  message,
  description,
  customClassName,
  type,
  actionButton,
  closable,
  testId,
  fireEventOnMount = false,
}: BannerProps): JSX.Element => {
  useEffect(() => {
    if (fireEventOnMount) {
      mixpanel.track({ event: 'USER_VIEWED', element: testId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SectionAlert
      banner
      closable={closable}
      type={type}
      testId={testId}
      actionButton={actionButton}
      customClassName={classNames(
        'custom-banner',
        `custom-banner-${type}`,
        customClassName
      )}
      description={
        typeof description === 'string' ? (
          <>
            {message && (
              <Typography
                size="s"
                type="body"
                weightLevel="500"
                color="neutral9"
              >
                {`${message} `}
              </Typography>
            )}
            <Typography type="body" size="s" color="neutral9">
              {description}
            </Typography>
          </>
        ) : (
          description
        )
      }
    />
  );
};

export default Banner;
