import React, {
  createContext,
  useState,
  useRef,
  MutableRefObject,
  useCallback,
} from 'react';
import { UploadFile } from 'antd/lib/upload/interface';
import Dragger from 'antd/lib/upload/Dragger';
import uniqBy from 'lodash/uniqBy';

export interface FileUploadContextProps {
  files: UploadFile[];
  setFiles: (file: UploadFile[]) => void;
  clearFiles: () => void;
  draggerRef?: MutableRefObject<typeof Dragger | undefined>;
  openFileSystem: () => void;
}

export const FileUploadContext = createContext<FileUploadContextProps>({
  files: [],
  setFiles: () => null,
  clearFiles: () => null,
  openFileSystem: () => null,
});

export const useFileUploadContextProvider = (): FileUploadContextProps => {
  const [files, setFiles] = useState<UploadFile[]>([]);
  const draggerRef = useRef<typeof Dragger>();

  const openFileSystem = () => {
    try {
      if (draggerRef?.current) {
        // @ts-ignore
        draggerRef?.current?.upload?.uploader?.fileInput?.click();
      }
    } catch (e) {}
  };

  const setFilesState = useCallback(
    (updateFiles: UploadFile[]) => {
      const onlyFilesWithUploadStatus = updateFiles.filter((it) =>
        Boolean(it.status)
      );
      const uniqueFiles = uniqBy(
        [...onlyFilesWithUploadStatus, ...files],
        'name'
      );
      setFiles(uniqueFiles);
    },
    [files, setFiles]
  );

  const props: FileUploadContextProps = {
    files,
    draggerRef,
    setFiles: setFilesState,
    openFileSystem,
    clearFiles: () => setFiles([]),
  };

  return props;
};

export const FileUploadContextProvider: React.FC<React.PropsWithChildren<
  unknown
>> = ({ children }) => {
  const props = useFileUploadContextProvider();
  return (
    <FileUploadContext.Provider value={props}>
      {children}
    </FileUploadContext.Provider>
  );
};
