import { Space } from 'antd';
import { Peep2Beanie } from 'assets/img';
import Button from 'component-library/src/button/Button';
import Typography from 'component-library/src/typography/Typography';
import dayjs from 'dayjs';
import { spacing } from 'component-library/src/theme/theme';
import { useAuth } from 'hooks/useAuth';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSubscriptionModalContext } from '../SubscriptionModalContext';
import { GLOBAL_DATE_FORMAT, termsUrl } from 'helper/constants/constants';
import Modal from 'component-library/src/modal/Modal';
import { mixpanel } from 'tracking/tracking';

const SubscriptionsCancelConfirm: React.FC<React.PropsWithChildren<
  unknown
>> = () => {
  const {
    t,
    i18n: { languages },
  } = useTranslation();
  const { userSubscription } = useAuth();
  const { dispatch } = useSubscriptionModalContext();

  return (
    <Modal
      onCancel={() => dispatch({ type: 'close' })}
      variant="marketing"
      size="m"
      trackEvent={mixpanel.track.bind(mixpanel)}
      headerText={t('subscriptions.cancelConfirmTitle')}
      fixedImage={<Peep2Beanie className="cancellation-confirm-peep" />}
      className="subscription-modal"
      open
      closable
    >
      <Space
        size={spacing.space32}
        direction="vertical"
        className="w-100 subscription-cancel-confirm"
        data-testid="subscription-cancel-confirm-modal"
      >
        <Typography
          type="body"
          size="m"
          weightLevel="400"
          color="neutral8"
          customClass="w-100"
        >
          {t('subscriptions.cancelConfirmSubtitle')}
        </Typography>
        <Typography
          type="body"
          size="m"
          weightLevel="400"
          color="neutral8"
          customClass="w-100 cancellation-downgrade-date"
        >
          <Trans
            i18nKey={
              Boolean(userSubscription?.subscription?.cancelAt)
                ? 'subscriptions.pendingCancelBannerText'
                : 'subscriptions.pendingCancelBannerTextNoDate'
            }
            values={{
              date: dayjs(userSubscription?.subscription?.cancelAt)?.format(
                GLOBAL_DATE_FORMAT
              ),
            }}
          >
            <Typography
              type="body"
              size="m"
              weightLevel="500"
              color="neutral8"
            />

            <a
              href={`${termsUrl[languages[0]]}#account-cancel`}
              className="link-in-text"
              target="_blank"
              rel="noopener noreferrer"
            >
              {''}
            </a>
          </Trans>
        </Typography>

        <div className="w-100 text-align-c">
          <Button
            type="primary"
            size="l"
            onClick={() => dispatch({ type: 'close' })}
            data-testid="subscription-cancel-done"
            trackEvent={mixpanel.track.bind(mixpanel)}
          >
            {t('subscriptions.cancelConfirmDone')}
          </Button>
        </div>
      </Space>
    </Modal>
  );
};

export default SubscriptionsCancelConfirm;
