import { emitter, experimentDebugger } from '@marvelapp/react-ab-test';
import { useAuth } from 'hooks/useAuth';
import { useEffect } from 'react';
import { EXPERIMENTS } from '../constants';
import { mixpanel, peopleProperties } from 'tracking/tracking';
import { useQueryString } from 'hooks/useQueryString';

interface UseVariantsSetupReturn {
  setupIsLoading: boolean;
}

export const useVariantsSetup = (): UseVariantsSetupReturn => {
  const { user } = useAuth();
  const { paramValue } = useQueryString<string>('activateExperiment');

  useEffect(() => {
    for (const key in EXPERIMENTS) {
      emitter.defineVariants(
        EXPERIMENTS[key].id,
        EXPERIMENTS[key].variants,
        EXPERIMENTS[key].variantWeights
      );
    }

    const listener = emitter.addActiveVariantListener((experiment, variant) => {
      mixpanel.setPeopleProperty(
        experiment as keyof typeof peopleProperties,
        variant
      );
    });

    return () => listener.remove();
  }, []);

  useEffect(() => {
    if (!paramValue) {
      return;
    }
    const matchedExperiment = EXPERIMENTS.find((it) => it.id === paramValue);
    if (matchedExperiment) {
      emitter.setActiveVariant(
        paramValue as string,
        matchedExperiment.variants[0]
      );
    }
  }, [paramValue]);

  useEffect(() => {
    if (user.isInternalUser) {
      experimentDebugger.enable();
      experimentDebugger.setDebuggerAvailable(true);
    }
  }, [user]);

  return {
    setupIsLoading: false,
  };
};
