import React, { useEffect } from 'react';
import AntdModal, { ModalProps } from 'antd/lib/modal';
import classnames from 'classnames';
import Typography, {
  CustomTypographyStyleProps,
} from '../typography/Typography';
import { spacing } from '../theme/theme';
import { CloseOutlined } from '@ant-design/icons';
import Button, { ButtonProps } from '../button/Button';
import { TrackEventHandler } from '../types';

export type FooterBtnProps = Omit<ButtonProps, 'size'>;

export interface CustomModalProps
  extends Omit<
    ModalProps,
    | 'onOkay'
    | 'okButtonProps'
    | 'cancelButtonProps'
    | 'closeIcon'
    | 'visible'
    | 'footer'
  > {
  size?: 's' | 'm' | 'l';
  headerText: React.ReactNode;
  headerSize?: 's' | 'm' | 'l';
  variant?: 'functional' | 'marketing' | 'no-style';
  scroll?: boolean;
  footerButtons?: (FooterBtnProps | React.ReactNode)[];
  footerButtonsSpread?: boolean;
  footerExtra?: React.ReactNode;
  fixedImage?: React.ReactNode;
  testId?: string;
  trackEvent?: TrackEventHandler;
}

const modalClassName = 'custom-modal';
const mapModalSizeToHeading: {
  [key: string]: { [key: string]: CustomTypographyStyleProps };
} = {
  functional: {
    s: {
      type: 'headline',
      size: 's',
    },
    m: {
      type: 'headline',
      size: 's',
    },
    l: {
      type: 'headline',
      size: 's',
    },
  },
  marketing: {
    s: {
      type: 'headline',
      size: 'm',
    },
    m: {
      type: 'headline',
      size: 'l',
    },
    l: {
      type: 'headline',
      size: 'l',
    },
  },
};

const Modal: React.FC<React.PropsWithChildren<CustomModalProps>> = (props) => {
  const {
    className,
    size = 'l',
    footerButtons,
    footerExtra,
    fixedImage,
    footerButtonsSpread = false,
    closable = true,
    variant = 'functional',
    scroll = false,
    onCancel = () => null,
    headerText,
    children,
    centered = true,
    testId = '',
    headerSize,
    trackEvent,
    ...modalProps
  } = props;
  const classNames = classnames(modalClassName, {
    [`${modalClassName}--size-${size}`]: size,
    [`${modalClassName}--has-footer`]:
      Boolean(footerButtons) || Boolean(footerExtra),
    [`${modalClassName}--has-floater-button`]:
      variant === 'marketing' && scroll,
    [`${modalClassName}--footer-spread`]: footerButtonsSpread,
    [`${modalClassName}--variant-${variant}`]: variant,
    [`${modalClassName}--scroll`]: scroll,
    [`${modalClassName}--no-bottom-padding`]: Boolean(fixedImage),
    [`${className}`]: className,
  });

  useEffect(() => {
    if (modalProps.open) {
      trackEvent?.({ event: 'USER_VIEWED', element: testId });
    }
  }, [modalProps.open, testId, trackEvent]);

  const handleOnCancel = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    trackEvent?.({ event: 'USER_CLICK', element: `${testId}-close` });
    onCancel(e);
  };

  return (
    <AntdModal
      className={classNames}
      {...modalProps}
      onCancel={handleOnCancel}
      closable={false}
      closeIcon={null}
      footer={null}
      open={props.open}
      centered={centered}
      maskClosable={true}
    >
      <div data-testid={testId} className={`${modalClassName}__wrapper`}>
        {closable ? (
          <Button
            data-testid={`${testId}-close`}
            className={`${modalClassName}__close`}
            onClick={handleOnCancel}
            type="icon"
            variant="link"
            trackEvent={(...args) => trackEvent?.(...args)}
          >
            <CloseOutlined
              style={{
                fontSize: spacing.space16,
                color: '#5F5978',
              }}
            />
          </Button>
        ) : null}
        {variant !== 'no-style' && (
          <section className={`${modalClassName}__header`}>
            <Typography
              {...(headerSize
                ? { type: 'headline', size: headerSize }
                : mapModalSizeToHeading[variant][size])}
              color="secondary7"
              weightLevel="500"
              data-testid={`${testId}-header`}
            >
              {headerText}
            </Typography>
          </section>
        )}
        <section
          data-testid={`${testId}-content`}
          className={`${modalClassName}__content`}
        >
          {children}
          {Boolean(fixedImage) && (
            <div
              data-testid={`${testId}-fixed-image`}
              className={`${modalClassName}__fixed-image`}
            >
              {fixedImage}
            </div>
          )}
        </section>
        {Boolean(footerButtons) || Boolean(footerExtra) ? (
          <div
            data-testid={`${testId}-footer`}
            className={`${modalClassName}__footer`}
          >
            {footerExtra ? (
              <div
                data-testid={`${testId}-footer-extra`}
                className={`${modalClassName}__footer-extra`}
              >
                {footerExtra}
              </div>
            ) : null}
            {footerButtons ? (
              <div
                data-testid={`${testId}-footer-buttons`}
                className={`${modalClassName}__footer-buttons`}
              >
                {footerButtons.map((it, index) =>
                  React.isValidElement(it) ? (
                    it
                  ) : (
                    <Button
                      size={size === 'l' ? 'l' : 'm'}
                      key={`${modalClassName}-${index}`}
                      {...(it as FooterBtnProps)}
                    />
                  )
                )}
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    </AntdModal>
  );
};

export default Modal;
