import React, { memo } from 'react';
import { Space, Tooltip } from 'antd';
import { spacing } from 'component-library/src/theme/theme';
import Typography from 'component-library/src/typography/Typography';
import classNames from 'classnames';
import { SelectProps } from 'antd/lib/select';
import { SelectCTAProps } from './Select';
import Button from 'component-library/src/button/Button';
import Checkbox from 'components/checkbox/Checkbox';
import Counter from 'components/counter/Counter';
import { mixpanel } from 'tracking/tracking';

export interface SelectOptionProps {
  label?: string;
  labelExtra?: string;
  icon?: React.ReactNode;
  imageSrc?: string;
  imageAltText?: string;
  imageRound?: boolean;
  cta?: Pick<SelectCTAProps, 'label' | 'onClick' | 'icon'>;
  disabled?: boolean;
  disabledTooltipText?: string;
  optionSize?: SelectProps<unknown>['size'];
  isCtaButton?: boolean;
  mode?: 'multiple' | 'tags';
  isSelectedOption?: boolean;
  isOptionWithCheckbox?: boolean;
  count?: number;
  testId?: string;
  hidden?: boolean;
}

const SelectOption: React.FC<React.PropsWithChildren<
  SelectOptionProps & {
    imageSize?: SelectProps<unknown>['size'];
    optionIconSize?: SelectProps<unknown>['size'];
  }
>> = (props) => {
  const showCheckbox = props.mode === 'multiple' || props.isOptionWithCheckbox;
  return (
    <Space
      direction="horizontal"
      size={spacing.space8}
      className={classNames({
        'custom-select-item-inner': true,
        'custom-select-item-inner-disabled': props?.disabled,
      })}
      data-testid={props.testId}
    >
      {showCheckbox && (
        <div
          className={classNames({
            'custom-select-checkbox': !props.isOptionWithCheckbox,
          })}
        >
          <Checkbox checked={props.isSelectedOption} />
        </div>
      )}
      {props.icon === undefined && props.imageSrc === undefined ? null : (
        <div
          data-testid="custom-select-item-image"
          className={classNames({
            'custom-select-image': true,
            'custom-select-image-round': props.imageRound,
            [`custom-select-image-size-${props.imageSize}`]: Boolean(
              props.imageSize
            ),
            [`custom-select-image-size-${props.optionIconSize}`]: Boolean(
              props.optionIconSize
            ),
          })}
        >
          {props.icon && props.icon}
          {props.imageSrc && (
            <img src={props.imageSrc} alt={props.imageAltText} />
          )}
        </div>
      )}
      <Space direction="vertical" size={0} className="w-100">
        <Typography
          {...(props.optionSize === 'small'
            ? {
                size: 'xs',
                type: 'body',
                weightLevel: props.isCtaButton ? '500' : '400',
              }
            : props.optionSize === 'large'
            ? { size: 'xxs', type: 'headline', weightLevel: '500' }
            : {
                size: 's',
                type: 'body',
                weightLevel: props.isCtaButton ? '500' : '400',
              })}
          color="neutral9"
          responsiveFont={false}
          customClass="custom-select-label"
          block
        >
          {props.disabled && props.disabledTooltipText ? (
            <Tooltip
              placement="right"
              title={
                <Typography size="xs" type="body" color="neutral1">
                  {props.disabledTooltipText}
                </Typography>
              }
            >
              {props.label}
            </Tooltip>
          ) : (
            props.label
          )}
        </Typography>
        {props.labelExtra && (
          <Typography
            size="xs"
            type="body"
            color={props.isCtaButton ? 'neutral7' : 'neutral8'}
            weightLevel="400"
            customClass="custom-select-item-extra"
            responsiveFont={false}
          >
            {props.labelExtra}
          </Typography>
        )}
      </Space>
      {props.cta && (
        <Button
          type="secondary"
          variant="link"
          size="s"
          data-testid="custom-select-item-cta"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            if (props.cta?.onClick) props.cta?.onClick(e);
          }}
          icon={props.cta.icon}
          className="custom-select-item-cta"
          trackEvent={mixpanel.track.bind(mixpanel)}
        >
          {props.cta.label}
        </Button>
      )}
      {!!props.count && (
        <div
          className="custom-select-item-counter"
          data-testid="custom-select-item-counter"
        >
          <Counter size="s" type="neutral" value={props.count} />
        </div>
      )}
    </Space>
  );
};

export default memo(SelectOption);
