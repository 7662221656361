import { useEffect, useCallback } from 'react';

export const useDebouncedEffect = <T>(
  effect: () => void,
  delay: number,
  deps: Array<T>
): void => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const callback = useCallback(effect, deps);
  useEffect(() => {
    const handler = setTimeout(() => {
      callback();
    }, delay);

    return (): void => {
      clearTimeout(handler);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callback, delay, ...deps]);
};
