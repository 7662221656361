import React from 'react';
import AntTag, { TagProps } from 'antd/lib/tag';
import classnames from 'classnames';

export interface CustomTagProps extends TagProps {
  size?: 'small' | 'large' | 'default';
  rounded?: boolean;
}

const Tag = ({
  size = 'default',
  rounded = false,
  className,
  ...props
}: CustomTagProps): JSX.Element => {
  return (
    <AntTag
      {...props}
      className={classnames(className, `ant-tag-size-${size}`, {
        'ant-tag-size-rounded': rounded,
      })}
    />
  );
};

export default Tag;
