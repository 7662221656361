import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useProfile } from 'hooks/useProfile';

const LocaleChangeListener: FC<React.PropsWithChildren<unknown>> = () => {
  const userProfileHook = useProfile();
  const {
    i18n: { languages },
  } = useTranslation();

  useEffect(() => {
    if (userProfileHook.data && userProfileHook.data?.locale !== languages[0]) {
      userProfileHook.mutatePatch({ locale: languages[0] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [languages, userProfileHook.data?.locale]);

  return null;
};

export default LocaleChangeListener;
