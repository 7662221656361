import React, { useEffect } from 'react';
import { Space } from 'antd';
import Typography from 'component-library/src/typography/Typography';
import { spacing } from 'component-library/src/theme/theme';
import { useTranslation } from 'react-i18next';
import { mixpanel } from 'tracking/tracking';
import { PeepPolkaDot } from 'assets/img';
import Button from 'component-library/src/button/Button';
import { useNavigate } from 'react-router-dom';
import { paths } from 'config/routes';
import { useSubscriptionModalContext } from 'features/subscriptions/SubscriptionModalContext';
import { useProfile } from 'hooks/useProfile';
import Modal from 'component-library/src/modal/Modal';

interface SubscriptionPaySuccessModal {
  isProfileCompete: boolean;
}

const SubscriptionPaySuccessModal: React.FC<React.PropsWithChildren<
  SubscriptionPaySuccessModal
>> = ({ isProfileCompete }) => {
  const modalContext = useSubscriptionModalContext();
  const { data: profile } = useProfile();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    mixpanel.track({
      event: 'PAYMENT_SUCCESS_MODAL_OPEN',
    });
  }, []);

  return (
    <Modal
      onCancel={() => modalContext.dispatch({ type: 'close' })}
      variant="marketing"
      size="m"
      headerText={t('subscriptions.paySuccessModalTitle', {
        name: profile?.firstName,
      })}
      trackEvent={mixpanel.track.bind(mixpanel)}
      fixedImage={<PeepPolkaDot title="Success!" />}
      className="subscription-modal"
      open
      closable
    >
      <Space
        size={spacing.space40}
        direction="vertical"
        className="w-100 text-align-c"
        data-testid="subscribe-pay-success-modal"
      >
        <Typography
          block
          type="body"
          size="m"
          responsiveFont={false}
          color="neutral8"
          customClass="w-100 text-align-c"
        >
          {isProfileCompete
            ? t('subscriptions.paySuccessModalSub')
            : t('subscriptions.paySuccessModalIncompleteProfileSub')}
        </Typography>

        {isProfileCompete ? (
          <Button
            type="primary"
            variant="filled"
            data-testid="pay-success-release-link"
            onClick={() => navigate(paths.releases)}
            trackEvent={mixpanel.track.bind(mixpanel)}
          >
            {t('subscriptions.paySuccessModalButtonText')}
          </Button>
        ) : (
          <Button
            type="primary"
            variant="filled"
            data-testid="pay-success-profile-link"
            onClick={() => navigate(paths.royaltyPayouts)}
            trackEvent={mixpanel.track.bind(mixpanel)}
          >
            {t('subscriptions.paySuccessModalButtonIncompleteProfileText')}
          </Button>
        )}
      </Space>
    </Modal>
  );
};

export default SubscriptionPaySuccessModal;
