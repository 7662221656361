import React from 'react';
import Typography from 'component-library/src/typography/Typography';
import classNames from 'classnames';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { isXsScreen } from 'helper/styling/styling';

type CounterTypoSizes = 'xs' | 's';

export interface CounterProps {
  value: number;
  size: 's' | 'm';
  type: 'neutral' | 'secondary';
  testId?: string;
}

const mapCounterSizeToTypoSize = (
  size: CounterProps['size'],
  isMobile: boolean
): CounterTypoSizes => {
  if (isMobile) return 'xs';

  switch (size) {
    case 's':
      return 'xs';
    default:
      return 's';
  }
};

const Counter: React.FC<React.PropsWithChildren<CounterProps>> = ({
  value,
  size,
  type,
  testId,
}) => {
  const screens = useBreakpoint();

  return (
    <div
      data-testid={testId}
      className={classNames(
        'counter',
        `counter__${type}`,
        `counter__size-${size}`
      )}
    >
      <Typography
        type="body"
        size={mapCounterSizeToTypoSize(size, isXsScreen(screens))}
        weightLevel="500"
      >
        {value}
      </Typography>
    </div>
  );
};

export default Counter;
