import React from 'react';
import Collapse, { CollapsePanelProps, CollapseProps } from 'antd/lib/collapse';
import { DownOutlined } from '@ant-design/icons';
import Typography from 'component-library/src/typography/Typography';

const { Panel } = Collapse;

interface PanelProps extends CollapsePanelProps {
  inner: React.ReactNode;
}

export interface AccordionProps extends CollapseProps {
  panels: PanelProps[];
}

const Accordion: React.FC<React.PropsWithChildren<AccordionProps>> = ({
  panels,
  ...props
}) => {
  return (
    <Collapse
      {...props}
      className="custom-accordion"
      expandIconPosition="right"
      expandIcon={(iconProps) => (
        <DownOutlined
          rotate={iconProps.isActive ? 180 : 0}
          style={{ fontSize: 18 }}
        />
      )}
    >
      {panels.map(({ inner, ...panelProps }) => (
        <Panel
          {...panelProps}
          key={panelProps.key}
          header={
            <Typography
              type="headline"
              size="xs"
              block
              color="neutral9"
              data-testid={`accordion-headline-${panelProps.key}`}
            >
              {panelProps.header}
            </Typography>
          }
        >
          <Typography type="body" size="s" block color="neutral8">
            {inner}
          </Typography>
        </Panel>
      ))}
    </Collapse>
  );
};

export default Accordion;
