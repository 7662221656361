import Card from 'components/card/Card';
import RowInfoItem from 'components/row-info-item/RowInfoItem';
import { countryCodeExpander, toTitleCase } from 'helper/formatter/formatter';
import {
  SubscriptionResponse,
  SepaPaymentResponse,
  CardPaymentResponse,
  PaypalPaymentResponse,
} from 'models/Subscription';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Space } from 'antd';
import { spacing } from 'component-library/src/theme/theme';
import Typography from 'component-library/src/typography/Typography';
import {
  VisaIcon,
  MastercardIcon,
  AmexIcon,
  SepaActivePayment,
  PaypalIcon,
} from 'assets/img';

interface BankAccountViewProps {
  accountHolderName: SepaPaymentResponse['accountHolderName'];
  ibanLastFour: SepaPaymentResponse['ibanLastFour'];
  country?: string;
}

export const BankAccountViewCard: React.FC<React.PropsWithChildren<
  BankAccountViewProps
>> = ({ accountHolderName, ibanLastFour, country }) => (
  <Space
    direction="horizontal"
    size={spacing.space16}
    data-testid="sepa-payment-view"
  >
    <SepaActivePayment />
    <Space direction="vertical" size={0}>
      <Typography
        type="body"
        size="s"
        weightLevel="500"
        color="neutral9"
        data-testid="iban-view-value"
      >
        {ibanLastFour
          ? `${
              country ? country.toUpperCase() : '••'
            }••••••••••••••••${ibanLastFour}`
          : '-'}
      </Typography>
      <Typography
        type="body"
        size="xs"
        weightLevel="400"
        color="neutral8"
        data-testid="account-name-view-value"
      >
        {accountHolderName}
      </Typography>
    </Space>
  </Space>
);

export const CardViewCard: React.FC<React.PropsWithChildren<
  CardPaymentResponse
>> = (props) => {
  const { t } = useTranslation();

  return (
    <Space
      direction="horizontal"
      size={spacing.space16}
      data-testid="card-payment-view"
    >
      {props.brand === 'visa' && <VisaIcon />}
      {props.brand === 'mastercard' && <MastercardIcon />}
      {props.brand === 'amex' && <AmexIcon />}
      <Space direction="vertical" size={0}>
        <Typography size="s" type="body" color="neutral9">
          <Typography size="s" type="body" weightLevel="500" color="neutral9">
            {toTitleCase(props.brand)}
          </Typography>{' '}
          {t('subscriptions.cardViewEndingIn')}{' '}
          <Typography size="s" type="body" weightLevel="500" color="neutral9">
            {props.cardLastFour}
          </Typography>
        </Typography>
        <Typography size="xs" type="body" color="neutral8">
          {t('subscriptions.cardViewExpires')}{' '}
          {props.expirationMonth?.toString().padStart(2, '0')}/
          {props.expirationYear?.toString().slice(2)}
        </Typography>
      </Space>
    </Space>
  );
};

export const PaypalViewCard: React.FC<React.PropsWithChildren<
  PaypalPaymentResponse
>> = (props) => {
  return (
    <Space
      direction="horizontal"
      size={spacing.space16}
      data-testid="paypal-payment-view"
      align="start"
    >
      <div className="paypal-payment-icon">
        <PaypalIcon />
      </div>
      <Space direction="vertical" size={0}>
        <Typography size="s" type="body" weightLevel="500" color="neutral9">
          PayPal
        </Typography>
        <Typography size="xs" type="body" color="neutral8">
          {props.email}
        </Typography>
      </Space>
    </Space>
  );
};

export const BillingInfoView: React.FC<React.PropsWithChildren<
  Pick<SubscriptionResponse, 'addressLine1' | 'postcode' | 'city' | 'country'>
>> = ({ addressLine1, postcode, city, country }) => {
  const {
    t,
    i18n: { languages },
  } = useTranslation();

  return (
    <Card heading={t('account.billingInfoTitle')}>
      <RowInfoItem
        testId="country-view"
        label={t('account.country')}
        value={countryCodeExpander(country, languages[0])}
      />
      <RowInfoItem
        testId="address-1-view"
        label={t('account.addressLine1')}
        value={toTitleCase(addressLine1)}
      />
      <RowInfoItem
        testId="postcode-view"
        label={t('account.postcode')}
        value={postcode}
      />
      <RowInfoItem
        testId="city-view"
        label={t('account.city')}
        value={toTitleCase(city)}
      />
    </Card>
  );
};

export const TaxInfoView: React.FC<React.PropsWithChildren<
  Pick<SubscriptionResponse, 'vatId'>
>> = ({ vatId }) => {
  const { t } = useTranslation();

  return (
    <Card heading={t('account.tax')}>
      <RowInfoItem
        testId="vat-id-view"
        label={t('account.vatId')}
        value={vatId || '-'}
      />
    </Card>
  );
};
