import {
  AgeGenderResponse,
  StreamCountPerCountry,
  TimeSeriesResponse,
} from 'models/MusicAnalytics';
import dayjs, { Dayjs } from 'dayjs';
import { lastXDaysArray } from 'helper/time/time';
import { Dictionary } from 'lodash';
import keyBy from 'lodash/keyBy';
import {
  audienceAgeGenderLabels,
  AudienceDemographicsType,
  DEFAULT_TIMEFRAME,
} from 'helper/constants/constants';

// currency formatting helpers
interface NumberFormat {
  [key: string]: Intl.NumberFormat;
}

export const currencyFormat: NumberFormat = {
  de: new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
  }),
  en: new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'EUR',
  }),
};

export const formatCurrency = (
  lng: string,
  value: number,
  showPlus = true
): string => {
  let formattedOut: string;
  if (!currencyFormat.hasOwnProperty(lng)) {
    formattedOut = currencyFormat.de.format(value);
  }
  formattedOut = currencyFormat[lng].format(value);

  return Math.sign(value) === -1
    ? formattedOut
    : `${showPlus ? '+' : ''}${formattedOut}`;
};

const numberFormat = (minDigits = 2): NumberFormat => ({
  de: new Intl.NumberFormat('de-DE', { minimumFractionDigits: minDigits }),
  en: new Intl.NumberFormat('en-US', { minimumFractionDigits: minDigits }),
});

export const formatNumber = (
  lng: string,
  value: number,
  minDigits?: number
): string => {
  if (!numberFormat().hasOwnProperty(lng)) {
    return numberFormat(minDigits).en.format(value);
  }
  return numberFormat(minDigits)[lng].format(value);
};

/**
 * Thousands Number to String Formatter
 * @param inputNum
 * @returns String of any numbers over 1000 formatted e.g. to 1.1K
 */
export const kStringFormatter = (
  lng: string,
  inputNum?: string | number
): string => {
  let numFormat: number;

  if (inputNum === undefined) {
    return '';
  } else if (typeof inputNum === 'string') {
    numFormat = parseInt(inputNum.replace(/,|[.]/gm, ''));
  } else {
    numFormat = inputNum;
  }

  return Math.abs(numFormat) > 999
    ? `${numberFormat(1)[lng].format(
        Number(
          (
            Math.sign(numFormat) *
            (Math.round(Math.abs(numFormat) / 100) / 10)
          ).toFixed(1)
        )
      )}K`
    : `${Math.sign(numFormat) * Math.abs(numFormat)}`;
};

export const percentageChangeFunc = (
  currNum: number,
  prevNum: number,
  precision: number
): number => {
  const roundingFunc = (value: number): number => {
    const multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  };

  if (currNum && prevNum) {
    return roundingFunc(((currNum - prevNum) / prevNum) * 100);
  } else {
    return 0;
  }
};

export const percentageOf = (
  curr: number,
  total: number,
  precision: number
): string => {
  return ((curr / total) * 100).toFixed(precision);
};

/**
 * Text Formatter - String to Title Case
 * @param str String to format
 * @returns: string - Formatted string
 */
export const toTitleCase = (str?: string | null): string => {
  if (str === undefined || str === null) {
    return '';
  }
  return str.replace(
    /\w\S*/g,
    (txt) => `${txt.charAt(0).toUpperCase()}${txt.substr(1).toLowerCase()}`
  );
};

export const formattedSmartlink = (str?: string): string | undefined => {
  return str?.replace(/^http(s)?\:\/\//gim, '').toLowerCase();
};

/**
 *
 * STATS DATA FORMATTERS
 */

/**
 * Format TimeSeries Response Data, add missing dates with 0 value
 * @param timeframe
 * @param seriesData
 * @param startDate
 * @param endDate
 * @returns Object of Date Labels / Stream Values Arrays
 */
export const formattedStreamData = (
  timeframe: number | undefined,
  startDate: Dayjs,
  endDate: Dayjs,
  seriesData: TimeSeriesResponse['data'],
  isMobile = false
): {
  labels: Array<string>;
  streamData: Array<number>;
} => {
  const dataLabels: Array<string> = [];
  const streamDataCounts: Array<number> = [];
  const diffMoreThan7 = endDate.diff(startDate, 'd') > 7;

  const requiredDates = lastXDaysArray(startDate, endDate, 'YYYY-MM-DD', false);

  const seriesDataMap = keyBy(seriesData, (obj) => obj.Date) as Dictionary<
    StreamCountPerCountry
  >;

  const dateFormat =
    (timeframe === 30 || diffMoreThan7) && !isMobile ? 'DD' : 'MMM, DD';

  requiredDates.forEach((date, index) => {
    if (isMobile) {
      dataLabels.push(
        index % (timeframe || DEFAULT_TIMEFRAME) === 0
          ? dayjs(date).format(dateFormat)
          : ''
      );
    } else {
      dataLabels.push(dayjs(date).format(dateFormat));
    }
    streamDataCounts.push(seriesDataMap[date]?.Count || 0);
  });

  return {
    labels: dataLabels,
    streamData: streamDataCounts,
  };
};

/**
 * Format Age/Gender Data from EMS API for Chart Usages
 * @param audienceData
 * @returns
 */
export const audienceDemographicFormat = (
  audienceData: AgeGenderResponse['data']
): AudienceDemographicsType =>
  audienceData.reduce<AudienceDemographicsType>(
    (obj, item: typeof audienceData[0]) => {
      const keyVal = item.AgeGenderGroup?.replace(
        /AgeFemale|AgeMale/g,
        ''
      ) as keyof AudienceDemographicsType;

      const subKeyVal = item.AgeGenderGroup?.includes('AgeFemale')
        ? 'female'
        : item.AgeGenderGroup?.includes('AgeMale')
        ? 'male'
        : item.AgeGenderGroup?.includes('TotalKnown')
        ? 'TotalKnown'
        : '';

      return {
        ...obj,
        [keyVal]: {
          ...obj[keyVal],
          [subKeyVal]: item.Count,
          total: item.Count
            ? item.Count + (obj[keyVal] ? obj[keyVal].total : 0)
            : 0,
        },
      };
    },
    audienceAgeGenderLabels
  );

/**
 * STRING DATA FORMATTERS
 */

/**
 * Replaces German special chars with standard format, and removes any
 * chars not in CISAC Format rule
 *
 * @param inputStr - String to format
 * @returns - String with charactes not matching CISAC standards formatted out
 */
export const formatCisacAllowedChars = (inputStr?: string | null): string => {
  const germanSpecialCharsMap: { [key: string]: string } = {
    Ü: 'UE',
    Ä: 'AE',
    Ö: 'OE',
    Ë: 'E',
    Ï: 'I',
    ü: 'ue',
    ä: 'ae',
    ö: 'oe',
    ë: 'e',
    ï: 'i',
    ß: 'ss',
  };

  const allowedCharsRegex = /([^'!“#$%&"()*+,-./:;<=>?@[\]^_`{}~ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789\s])+/gi;

  return typeof inputStr === 'string'
    ? inputStr
        .replace(
          new RegExp(
            '[' + Object.keys(germanSpecialCharsMap).join('|') + ']',
            'g'
          ),
          (subStr: string) => {
            return germanSpecialCharsMap[subStr];
          }
        )
        .replace(allowedCharsRegex, '')
    : '';
};

const countriesList = require('helper/static/countries.json');
/**
 *
 * @param countryCode Two letter country code to expand
 * @param langKey Language to translate country into
 * @returns Full name of country given the country's code
 */
export const countryCodeExpander = (
  countryCode?: string,
  langKey = 'en'
): string => {
  if (countryCode === undefined) {
    return '-';
  }

  return countriesList[langKey][countryCode.toUpperCase()] ?? '-';
};

export const addLeadingZero = (value: number): string =>
  value < 10 ? `0${value}` : `${value}`;

export const bytesToGB = (bytes: number): string => {
  const gbValue = bytes / 1024 ** 3;
  return Number.isInteger(gbValue) ? `${gbValue}GB` : `${gbValue.toFixed(2)}GB`;
};
