import React from 'react';
import { CheckOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import Typography from '../../../typography/Typography';
import theme, { ThemeColorProps, getThemeColor } from '../../../theme/theme';
import List from '../../../list/List';
import { spacing } from '../../../theme/theme';
import Button from '../../..//button/Button';
import { TrackEventHandler } from '../../../types';

interface StepItem {
  icon?: React.ReactNode;
  title?: string;
  listItems?: (string | React.ReactNode)[];
  listIcon?: React.ReactNode;
  nextStepText?: string;
  description?: string | React.ReactNode;
  cta?: {
    url?: string;
    text?: string;
  };
}

export interface StepsProps {
  steps: StepItem[];
  background?: keyof ThemeColorProps['color'];
  testId?: string;
  variant?: 'prompt' | 'next';
  trackEvent?: TrackEventHandler;
}

const modalStepsClass = 'modal-steps';

const Steps: React.FC<React.PropsWithChildren<StepsProps>> = ({
  steps,
  background = 'transparent',
  testId = '',
  variant,
  trackEvent,
}) => {
  const { t } = useTranslation();
  const classNames = classnames(modalStepsClass);

  return (
    <section
      className={classNames}
      data-testid={testId}
      style={{
        background: getThemeColor(background as keyof ThemeColorProps['color']),
      }}
    >
      {steps.map(
        (
          {
            title,
            icon,
            listItems = [],
            description,
            nextStepText,
            listIcon,
            cta,
          },
          stepNumber
        ) => (
          <div
            key={`${modalStepsClass}__step-${title}`}
            className={`${modalStepsClass}__step`}
          >
            <div className={`${modalStepsClass}__icon`}>{icon}</div>

            {variant === 'next' && (
              <Typography
                type="body"
                size="xs"
                color="neutral7"
                weightLevel="500"
              >
                {nextStepText
                  ? nextStepText
                  : t('modal.step', { stepNumber: stepNumber + 1 })}
              </Typography>
            )}

            <Typography
              type={variant === 'prompt' ? 'headline' : 'body'}
              size={variant === 'prompt' ? 'xs' : 's'}
              color="neutral9"
              weightLevel="500"
              responsiveFont={false}
              block
            >
              {title}
            </Typography>

            {description && (
              <Typography align="center" type="body" size="xs" color="neutral8">
                {description}
              </Typography>
            )}

            {Boolean(cta?.text) && Boolean(cta?.url) && (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={cta?.url}
                title={`${title} link`}
              >
                <Button
                  type="secondary"
                  variant="outline"
                  eventElementName={`${testId}-steps-${stepNumber}`}
                  eventElementUrl={cta?.url}
                  trackEvent={trackEvent}
                >
                  {cta?.text}
                </Button>
              </a>
            )}

            {listItems && (
              <List
                listItems={listItems}
                listIcon={
                  listIcon ? (
                    listIcon
                  ) : (
                    <CheckOutlined style={{ color: theme.colors.accent4 }} />
                  )
                }
                gap={{ xs: spacing.space8, md: spacing.space12 }}
              />
            )}
          </div>
        )
      )}
    </section>
  );
};

export default Steps;
