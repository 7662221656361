import useSWR from 'swr';
import { AxiosError, AxiosResponse } from 'axios';
import { ReleaseCount } from '../models/Release';
import api from '../helper/api/api';

export interface UseReleaseCountReturn {
  releaseCount?: ReleaseCount;
}

export const useReleaseCount = (): UseReleaseCountReturn => {
  const { data: releaseCount } = useSWR<ReleaseCount, AxiosError<Error>>(
    '/releaseCount',
    () =>
      api.release
        .releaseCount()
        .then((res: AxiosResponse<ReleaseCount>) => res.data)
  );

  return {
    releaseCount: releaseCount,
  };
};
