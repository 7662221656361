import React, { ReactNode, useMemo } from 'react';
import { MusicHubUser } from 'hooks/useAuth';
import { useAuth } from 'hooks/useAuth';
import { UseSubscriptionReturn } from 'hooks/useSubscription';

interface UserConditionProps {
  children: ReactNode;
  fallback?: ReactNode;
  condition?: (
    user: MusicHubUser,
    userSubscription: UseSubscriptionReturn['subscription']
  ) => boolean;
}

const UserCondition: React.FC<UserConditionProps> = ({
  children,
  fallback = null,
  condition = () => true,
}) => {
  const user = useAuth();
  const showContent = useMemo(() => {
    return condition(user?.user, user?.userSubscription?.subscription);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  if (showContent) return <>{children}</>;
  return <>{fallback}</>;
};

export default UserCondition;
