import { ArrowLeftOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import Button from 'component-library/src/button/Button';
import InputText from 'component-library/src/input-text/InputText';
import Radio, { Group } from 'components/radio/Radio';
import Select from 'components/select/Select';
import Space from 'component-library/src/space/Space';
import Typography from 'component-library/src/typography/Typography';
import { UserRegisterFormData, UserRole } from 'models/Users';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { mixpanel } from 'tracking/tracking';

interface Step2Props {
  backClickHandle?: () => void;
  nextClickHandle: (
    arg1: Pick<UserRegisterFormData, 'discoverySource' | 'userRole'>
  ) => void;
}

export const mainRoleOptions: {
  key: string;
  value: UserRole;
}[] = [
  {
    key: 'artist',
    value: 'ARTIST_SOLO',
  },
  {
    key: 'band',
    value: 'ARTIST_BAND',
  },
  {
    key: 'manager',
    value: 'ARTIST_MANAGER',
  },
  {
    key: 'producer',
    value: 'PRODUCER',
  },
  {
    key: 'publisher',
    value: 'PUBLISHER',
  },
  {
    key: 'label',
    value: 'LABEL',
  },
  {
    key: 'author',
    value: 'AUTHOR',
  },
  {
    key: 'other',
    value: 'OTHER',
  },
];
const discoveryOptions = [
  {
    key: 'searchEngine',
    value: 'Search Engine',
  },
  {
    key: 'socialMedia',
    value: 'Social Media',
  },
  {
    key: 'recommendation',
    value: 'Recommendation',
  },
  {
    key: 'onlineAd',
    value: 'Online Ad',
  },
  {
    key: 'community',
    value: 'Community',
  },
  {
    key: 'networking',
    value: 'Networking',
  },
  {
    key: 'magazine',
    value: 'Magazine',
  },
  {
    key: 'otherMedia',
    value: 'Other Media',
  },
  {
    key: 'thirdParty',
    value: 'Third Party',
  },
  {
    key: 'other',
    value: 'Other',
  },
];

const RegisterStep2 = ({
  backClickHandle,
  nextClickHandle,
}: Step2Props): JSX.Element => {
  const { t } = useTranslation();
  const nextRef = useRef<HTMLButtonElement | null>(null);

  const enterNext = (event: React.KeyboardEvent): void => {
    if (!nextRef.current) {
      return;
    }
    const key = event.key || event.keyCode;
    if (key === 'Enter' || key === 13) nextRef.current.click();
  };

  return (
    <>
      <div className="w-100 mb-4 register-title-container">
        <Typography type="headline" size="m" level={2}>
          {t('userSegmentation.formRegisterMainRoleTitle')}
        </Typography>
      </div>
      <Col xs={24} className="w-100">
        <FormItem
          name="userRole"
          label={t('userSegmentation.mainRoleOptionsLabel')}
        >
          <Select
            allowClear
            showSearch
            size="large"
            testId="user-role-selector"
            placeholder={t('userSegmentation.mainRoleOptionsPlaceholder')}
            options={mainRoleOptions.map(({ key, value }) => ({
              optionKey: key,
              value,
              label: t(`userSegmentation.mainRoleOptions.${key}`),
              ['data-testid']: `user-role-selector-${value}`,
            }))}
          />
        </FormItem>
      </Col>

      <div className="w-100 mb-4 register-title-container">
        <Typography type="headline" size="m" level={2}>
          {t('userSegmentation.formRegisterDiscoveryTitle')}
        </Typography>
      </div>

      <Row className="w-100">
        <Col xs={24} className="w-100">
          <FormItem name="discoverySource">
            <Group>
              <Space direction="vertical" size={16}>
                {discoveryOptions.map(({ key, value }) => (
                  <Radio
                    key={key}
                    variant="default"
                    value={value}
                    testId={`radio-${key}`}
                    label={t(`userSegmentation.discoveryOptions.${key}`)}
                  />
                ))}
              </Space>
            </Group>
          </FormItem>

          <FormItem noStyle shouldUpdate>
            {({ getFieldValue }): JSX.Element => {
              const discoverySource = getFieldValue('discoverySource');

              return (
                <>
                  {discoverySource === 'Other' && (
                    <div className="mb-4">
                      <FormItem
                        name="discoverySourceOther"
                        label={t('userSegmentation.otherLabel')}
                      >
                        <InputText
                          onKeyDown={(event) => enterNext(event)}
                          type="text"
                          testId="other-option-input"
                          size="large"
                          placeholder={t('userSegmentation.otherPlaceholder')}
                        />
                      </FormItem>
                    </div>
                  )}
                </>
              );
            }}
          </FormItem>
        </Col>
      </Row>

      <Row className="register-form-actions">
        <Col xs={24}>
          <Row justify="center">
            <FormItem noStyle shouldUpdate>
              {({ getFieldValue }): JSX.Element => {
                const userRole = getFieldValue('userRole');
                const discoverySource = getFieldValue('discoverySource');
                const discoverySourceOther = getFieldValue(
                  'discoverySourceOther'
                );

                return (
                  <Button
                    ref={nextRef}
                    size="l"
                    type="primary"
                    className="user-signup-next"
                    testId="register-step-2-next"
                    disabled={Boolean(
                      !userRole ||
                        !discoverySource ||
                        (discoverySource === 'Other' && !discoverySourceOther)
                    )}
                    onClick={(): void =>
                      nextClickHandle({
                        userRole,
                        discoverySource:
                          discoverySource === 'Other' && discoverySourceOther
                            ? discoverySourceOther
                            : discoverySource,
                      })
                    }
                    fullWidth
                    trackEvent={mixpanel.track.bind(mixpanel)}
                  >
                    {t('general.next')}
                  </Button>
                );
              }}
            </FormItem>
          </Row>
        </Col>

        {typeof backClickHandle !== 'undefined' && (
          <Col xs={24}>
            <Row justify="center">
              <Button
                className="register-go-back"
                type="secondary"
                size="l"
                variant="outline"
                data-testid="register-go-back"
                fullWidth
                onClick={backClickHandle}
                icon={<ArrowLeftOutlined />}
                trackEvent={mixpanel.track.bind(mixpanel)}
              >
                {t('general.goBack')}
              </Button>
            </Row>
          </Col>
        )}
      </Row>
    </>
  );
};

export default RegisterStep2;
