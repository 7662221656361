import React from 'react';
import { spacing } from '../theme/theme';
import Typography from '../typography/Typography';
import { ThemeColorProps } from '../theme/theme';
import Space, { SpaceProps } from '../space/Space';

export interface ListProps {
  listItems: (string | React.ReactNode)[];
  listIcon?: React.ReactNode;
  fontColor?: ThemeColorProps['color'];
  fontSize?: 'xs' | 's' | 'm' | 'l';
  gap?: SpaceProps['size'];
  customClass?: string;
}

const List: React.FC<React.PropsWithChildren<ListProps>> = ({
  listItems,
  customClass,
  listIcon,
  gap = {
    md: spacing.space12,
    xs: spacing.space8,
  },
  fontColor = 'secondary7',
  fontSize = 's',
}) => (
  <Space direction="vertical" size={gap} className={`${customClass} w-100`}>
    {listItems.map((it, key) => (
      <Space
        direction="horizontal"
        align="start"
        size={gap}
        key={`list-item-${key}`}
        className="custom-list"
      >
        {Boolean(listIcon) && listIcon}
        <Typography
          type="body"
          size={fontSize}
          color={fontColor}
          weightLevel="400"
          responsiveFont={false}
        >
          {it}
        </Typography>
      </Space>
    ))}
  </Space>
);

export default List;
