import React, { ReactNode } from 'react';
import { useFeatureFlagCheck } from 'hooks/useFeatureFlagCheck';
import flags from 'config/flags';
import { useAuth } from 'hooks/useAuth';

interface FeatureProps {
  name?: keyof typeof flags;
  children: ReactNode;
  fallback?: ReactNode;
  displayWhileLoading?: ReactNode;
}

const Feature: React.FC<React.PropsWithChildren<FeatureProps>> = ({
  children,
  name,
  fallback = null,
  displayWhileLoading = null,
}) => {
  const user = useAuth();
  const flag = useFeatureFlagCheck(name);

  if (flag?.loading || user?.isLoadingState) return <>{displayWhileLoading}</>;
  if (flag?.value) return <>{children}</>;
  return <>{fallback}</>;
};

export default Feature;
