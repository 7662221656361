import Banner from '../banner/Banner';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { useUserFeatures } from '../../hooks/useUserFeatures';

const FeatureDisabledBanner = (): JSX.Element => {
  const { data: userFeatures } = useUserFeatures();
  const { t } = useTranslation();
  const showBanner =
    userFeatures?.some((f) => f.feature === 'SUBMISSION') ?? false;

  return showBanner ? (
    <Banner
      type="warning"
      testId="feature-disabled-banner"
      message={t('banner.featureDisabledMessage')}
      description={t('banner.featureDisabledDescription')}
      actionButton={{
        text: t('banner.contactUs'),
        onClick: (): void => window.Intercom('show'),
      }}
    />
  ) : (
    <></>
  );
};
export default FeatureDisabledBanner;
