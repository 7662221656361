import { AxiosError } from 'axios';
import { useAuth } from 'hooks/useAuth';
import useSWR, { SWRResponse } from 'swr';
import { UserOpenAgreements } from '../models/termsConditionsModels';
import termsConditionsService from '../services/termsConditionsService';

interface UseTermsConditionsReturn
  extends Pick<SWRResponse<UserOpenAgreements, AxiosError<Error>>, 'error'> {
  data?: UserOpenAgreements;
  isLoading: boolean;
  revalidate: () => void;
}

export const useTermsConditions = (): UseTermsConditionsReturn => {
  const {
    user: { id: userId },
  } = useAuth();
  const { data, error: swrError, revalidate, isValidating } = useSWR<
    UserOpenAgreements | undefined,
    AxiosError<Error>
  >(
    ['/users/agreements', userId],
    () =>
      userId === undefined
        ? undefined
        : termsConditionsService.getUserTermsAgreement(userId),
    {
      revalidateOnFocus: false,
      shouldRetryOnError: false,
    }
  );

  return {
    data,
    isLoading: isValidating && !data && !swrError,
    error: swrError,
    revalidate,
  };
};
