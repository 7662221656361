import React, { useMemo } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { UserAgreementRes } from '../models/termsConditionsModels';
import termsConditionsService from '../services/termsConditionsService';

interface TermsConditionsIframeProps {
  // code ignored for now as we need to set in DB and reference as version
  code?: string;
  isGemaMember?: boolean;
  language: string;
  fullWidth?: boolean;
  size?: 's' | 'm' | 'l';
  links?: UserAgreementRes['agreementLinks'];
}

const TermsConditionsIframe: React.FC<React.PropsWithChildren<
  TermsConditionsIframeProps
>> = ({
  language,
  links,
  size = 'm',
  fullWidth = false,
  isGemaMember = false,
}) => {
  const { t } = useTranslation();

  const termsUrl = useMemo(
    () =>
      termsConditionsService.getTermsUrl(
        links,
        language,
        isGemaMember,
        'iframeUrl'
      ),
    [isGemaMember, language, links]
  );

  return (
    <div
      className="terms-conditions-frame-container"
      data-testid="terms-conditions-frame-container"
    >
      <iframe
        className={classnames(
          `terms-and-conditions-frame`,
          `terms-and-conditions-frame-${size}`,
          {
            'w-100': fullWidth,
          }
        )}
        data-testid="terms-and-conditions-frame"
        src={termsUrl}
        title={t('termsAndConditions.musicHubTsAndCs')}
      />
    </div>
  );
};

export default TermsConditionsIframe;
