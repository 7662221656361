import { Axios } from 'axios';
import { instance } from 'helper/api/api';
import {
  EuropeanCountriesResponse,
  LanguagesResponse,
  ValidationConfigResponse,
} from 'features/config/Config';

export class ConfigService {
  configInstance: Axios;

  constructor(configInstance: Axios) {
    this.configInstance = configInstance;
  }

  async getEuropeanUnionCountries(): Promise<string[]> {
    const { data } = await this.configInstance.get<EuropeanCountriesResponse>(
      `/config/europeanUnionCountries`
    );
    return data?.['EU'] || [];
  }

  async getValidationConfig(): Promise<ValidationConfigResponse> {
    const { data } = await this.configInstance.get<ValidationConfigResponse>(
      `/config/validation`
    );
    return data;
  }

  async getLanguages(): Promise<LanguagesResponse> {
    const { data } = await this.configInstance.get<LanguagesResponse>(
      `/config/languages`
    );
    return data;
  }
}

export default new ConfigService(instance);
