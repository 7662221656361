import useSWR, { SWRResponse, SWRConfiguration } from 'swr';
import { AxiosError } from 'axios';
import {
  SubscriptionPlan,
  SubscriptionPreviewResponse,
} from 'models/Subscription';
import subscriptionService from 'features/subscriptions/services/subscriptionService';
import { BillingCycleType } from 'helper/constants/constants';

export interface UseSubscriptionBillingPreviewReturn
  extends Pick<
    SWRResponse<SubscriptionPreviewResponse, AxiosError>,
    'error' | 'revalidate'
  > {
  isLoading: boolean;
  billingPreview?: SubscriptionPreviewResponse;
}

export const useSubscriptionBillingPreview = (
  billingCycle: BillingCycleType,
  plan: SubscriptionPlan,
  subscriptionId?: string,
  voucherCode?: string,
  options?: SWRConfiguration
): UseSubscriptionBillingPreviewReturn => {
  const { data: billingPreview, error, revalidate } = useSWR<
    SubscriptionPreviewResponse,
    AxiosError
  >(
    [
      '/payments/subscriptions',
      billingCycle,
      plan,
      subscriptionId,
      voucherCode,
    ],
    () =>
      subscriptionService.billingInvoicePreview(
        billingCycle,
        plan,
        subscriptionId,
        voucherCode
      ),
    { revalidateOnFocus: false, shouldRetryOnError: false, ...options }
  );

  return {
    billingPreview: billingPreview,
    isLoading: !Boolean(error) && !Boolean(billingPreview),
    error,
    revalidate,
  };
};
