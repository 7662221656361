import React from 'react';
import { track, mixpanel } from 'tracking/tracking';
import 'styles/App.css';
import 'antd/dist/reset.css';
import 'config/i18n';
import { AuthProvider } from 'hooks/useAuth';
import Routes from 'components/routes/Routes';
import { useTranslation } from 'react-i18next';
import ThemeProvider from 'component-library/src/theme/ThemeProvider';

declare global {
  interface Window {
    Intercom: (arg1: unknown, arg2?: unknown, arg3?: object) => void;
  }
}

const App: React.FC<React.PropsWithChildren<unknown>> = () => {
  const {
    i18n: {
      languages: [language],
    },
  } = useTranslation();

  return (
    <ThemeProvider language={language}>
      <AuthProvider>
        <Routes />
      </AuthProvider>
    </ThemeProvider>
  );
};

const AppWithTracking = track(
  {},
  {
    dispatch: mixpanel.track.bind(mixpanel),
  }
)(App);

export default AppWithTracking;
