import { LoadingOutlined } from '@ant-design/icons';
import Card from 'antd/lib/card';
import Col from 'antd/lib/col';
import message from 'antd/lib/message';
import Row from 'antd/lib/row';
import Space from 'antd/es/space';
import Spin from 'antd/lib/spin';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import axios from 'axios';
import {
  BankAccountViewCard,
  CardViewCard,
} from 'components/subscriptions-payment/FormViewItems';
import Typography from 'component-library/src/typography/Typography';
import dayjs from 'dayjs';
import {
  BillingCycleType,
  GLOBAL_DATE_FORMAT,
  getPlanValue,
} from 'helper/constants/constants';
import { spacing } from 'component-library/src/theme/theme';
import { currencyFormat, formatCurrency } from 'helper/formatter/formatter';
import { isSmScreen } from 'helper/styling/styling';
import theme from 'component-library/src/theme/theme';
import { useAuth } from 'hooks/useAuth';
import React, { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSubscriptionBillingPreview } from 'features/subscriptions/hooks/useSubscriptionBillingPreview';
import { useSubscriptionModalContext } from 'features/subscriptions/SubscriptionModalContext';
import { useStripe } from '@stripe/react-stripe-js';
import subscriptionService from 'features/subscriptions/services/subscriptionService';
import Modal from 'component-library/src/modal/Modal';
import { SubscriptionStripeCardError } from 'errors/subscriptionErrors';
import { logging } from 'logging/logging';
import { mixpanel } from 'tracking/tracking';
import { SubscriptionPlan } from 'models/Subscription';

const SubscriptionBillingCycleSwitchModal = () => {
  const stripe = useStripe();
  const { dispatch: modalDispatch } = useSubscriptionModalContext();
  const { userSubscription } = useAuth();
  const { billingPreview, isLoading } = useSubscriptionBillingPreview(
    BillingCycleType.ANNUAL,
    userSubscription?.subscription?.plan || SubscriptionPlan.BASIC,
    userSubscription?.subscription?.id
  );
  const {
    t,
    i18n: { languages },
  } = useTranslation();
  const screens = useBreakpoint();

  const screenType = useMemo(
    () => (!isSmScreen(screens) ? 'mobile' : 'desktop'),
    [screens]
  );
  const isMobile = screenType === 'mobile';
  const cardPayment = userSubscription?.subscription?.paymentMethod?.card;
  const sepaPayment = userSubscription?.subscription?.paymentMethod?.sepa;

  const handleBillingCycleSwitch = async (cycleType: BillingCycleType) => {
    modalDispatch({ type: 'subscriptionsPayProcessing' });
    try {
      await subscriptionService.switchBillingCycle(
        cycleType,
        userSubscription?.subscription?.id,
        stripe
      );

      await userSubscription?.revalidate();
      modalDispatch({ type: 'subscriptionsUpdateSuccess' });
    } catch (err) {
      await userSubscription?.revalidate();
      modalDispatch({ type: 'close' });

      if (err instanceof SubscriptionStripeCardError) {
        modalDispatch({
          type: 'subscriptionsPaymentError',
          error: err.message,
        });
        return;
      }

      if (axios.isAxiosError(err)) {
        logging.error({
          productArea: 'subscription',
          message: 'BILLING_CYCLE_SWITCH_FAILURE',
          messageContext: {
            error: err.response,
            errorMessage: err.response?.data,
          },
          error: err as Error,
        });

        return message.error({
          content: err.message,
          className: 'generic-api-error',
        });
      }
    }
  };

  return (
    <Modal
      headerText={t('subscriptions.billingCycle.checkoutTitle')}
      open
      closable
      variant="functional"
      size="m"
      scroll={false}
      className="switch-billing-cycle-modal"
      testId="switch-billing-cycle-modal"
      onCancel={() => modalDispatch({ type: 'close' })}
      trackEvent={mixpanel.track.bind(mixpanel)}
      destroyOnClose
      footerButtons={[
        {
          type: 'secondary',
          variant: 'outline',
          children: t('general.cancel'),
          onClick: () => modalDispatch({ type: 'close' }),
        },

        {
          type: 'primary',
          variant: 'filled',
          ['data-testid']: 'modal-cycle-switch-confirm-btn',
          children: t('subscriptions.billingCycle.annual.switch'),
          onClick: () => handleBillingCycleSwitch(BillingCycleType.ANNUAL),
        },
      ]}
    >
      <Row className="w-100" gutter={[0, 0]}>
        <Col xs={24} sm={10} data-testid="payment-method-details">
          <Space size={spacing.space16} direction="vertical" className="w-100">
            <Typography
              type="headline"
              size="xs"
              weightLevel="500"
              color="neutral9"
            >
              {t('subscriptions.paymentMethodTitle')}
            </Typography>

            {Boolean(cardPayment) && <CardViewCard {...cardPayment} />}
            {Boolean(sepaPayment) && (
              <BankAccountViewCard
                accountHolderName={sepaPayment?.accountHolderName}
                ibanLastFour={sepaPayment?.ibanLastFour}
                country={userSubscription?.subscription?.country}
              />
            )}
          </Space>
        </Col>

        <Col xs={24} sm={14} className={`${isMobile ? 'mt-6' : ''}`}>
          <Card
            className="switch-modal-billing-summary"
            data-testid="modal-billing-summary-card"
          >
            {isLoading ? (
              <Row justify="center" className="w-100 mt-8 mb-8">
                <Spin
                  spinning
                  indicator={
                    <LoadingOutlined
                      style={{
                        color: theme.colors.secondary5,
                        fontSize: spacing.space40,
                      }}
                      spin
                    />
                  }
                />
              </Row>
            ) : (
              <Space
                size={spacing.space24}
                direction="vertical"
                className="w-100"
              >
                <Typography
                  type="headline"
                  size="xs"
                  weightLevel="500"
                  color="neutral9"
                >
                  {t('subscriptions.billingCycle.titleSummary')}
                </Typography>

                <Space
                  className="plan-price-details w-100"
                  size={spacing.space8}
                  direction="vertical"
                >
                  {/* PLAN AMOUNT */}
                  <Space
                    direction="horizontal"
                    align="center"
                    className="plan-pricing-cycle w-100"
                  >
                    <Typography type="body" size="s" color="neutral9">
                      {t('subscriptions.pricingModal.annualPlan.heading')}
                    </Typography>

                    <Typography
                      type="body"
                      size="s"
                      color="neutral9"
                      weightLevel="500"
                      data-testid="checkout-plan-total-amount"
                    >
                      <Trans
                        i18nKey="subscriptions.subscribeBannerPrice"
                        values={{
                          price:
                            billingPreview?.planAmount !== undefined
                              ? billingPreview?.planAmount / 100
                              : '-',
                          cycle: t('subscriptions.billingCycle.annual.cycle'),
                        }}
                      >
                        <span />
                      </Trans>
                    </Typography>
                  </Space>

                  {/* PRORATED AMOUNT GIVEN BACK */}
                  <Space
                    direction="horizontal"
                    align="center"
                    className="plan-pricing-cycle w-100"
                    data-testid="checkout-plan-prorated-amount"
                  >
                    <Typography type="body" size="s" color="neutral9">
                      {t('subscriptions.pricingModal.unusedMonthlySub')}
                    </Typography>

                    <Typography
                      type="body"
                      size="s"
                      color="danger4"
                      weightLevel="500"
                    >
                      {currencyFormat[languages[0]].format(
                        billingPreview?.proratedAmount !== undefined
                          ? billingPreview?.proratedAmount / 100
                          : 0.0
                      )}
                    </Typography>
                  </Space>

                  {/* ANNUAL DISCOUNT OFFER REDUCTION */}
                  {billingPreview?.discountAmount ? (
                    <Space
                      direction="horizontal"
                      align="center"
                      className="plan-pricing-cycle w-100"
                      data-testid="checkout-discout-offer-amount"
                    >
                      <Typography type="body" size="s" color="neutral9">
                        {t(
                          'subscriptions.pricingModal.annualDiscountPlan.checkoutDiscountText',
                          {
                            percentage: billingPreview?.discountPercentage?.toString(),
                          }
                        )}
                      </Typography>

                      <Typography
                        type="body"
                        size="s"
                        color="danger4"
                        weightLevel="500"
                      >
                        {`-${currencyFormat[languages[0]].format(
                          billingPreview?.discountAmount !== undefined
                            ? billingPreview?.discountAmount / 100
                            : 0.0
                        )}`}
                      </Typography>
                    </Space>
                  ) : (
                    <></>
                  )}
                </Space>

                {/* AMOUNT DUE TODAY */}
                <Space
                  className="plan-price-details"
                  size={spacing.space8}
                  direction="vertical"
                  data-testid="checkout-due-today-amount"
                >
                  <Typography
                    type="headline"
                    size="s"
                    weightLevel="500"
                    color="neutral9"
                  >
                    <Trans
                      i18nKey="subscriptions.billingCycle.dueToday"
                      values={{
                        price:
                          billingPreview?.total !== undefined
                            ? currencyFormat[languages[0]].format(
                                billingPreview?.total / 100
                              )
                            : '-',
                      }}
                    />
                  </Typography>

                  <Typography
                    size="xs"
                    type="body"
                    weightLevel="400"
                    color="neutral8"
                    data-testid="next-billing-information"
                  >
                    <Trans
                      i18nKey="subscriptions.billingCycle.nextCharge.desktop"
                      values={{
                        dueAmount: formatCurrency(
                          languages[0],
                          getPlanValue(BillingCycleType.ANNUAL),
                          false
                        ).replace('.00', ''),
                        dueDate: dayjs()
                          .add(1, 'year')
                          .format(GLOBAL_DATE_FORMAT),
                      }}
                    >
                      <Typography
                        size="xs"
                        type="body"
                        weightLevel="500"
                        color="neutral9"
                      />
                      <Typography
                        size="xs"
                        type="body"
                        weightLevel="500"
                        color="neutral9"
                      />
                    </Trans>
                  </Typography>
                </Space>
              </Space>
            )}
          </Card>
        </Col>
      </Row>
    </Modal>
  );
};

export default SubscriptionBillingCycleSwitchModal;
