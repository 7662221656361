import React, { createContext } from 'react';
import useSWR, { cache } from 'swr';
import configService from 'features/config/configService';
import {
  LanguagesResponse,
  ValidationConfigResponse,
} from 'features/config/Config';

export interface ConfigContextProps {
  validation?: ValidationConfigResponse;
  euCountries?: string[];
  languages?: LanguagesResponse;
  loading?: boolean;
}

export const ConfigContext = createContext<ConfigContextProps>({
  validation: undefined,
  euCountries: undefined,
  languages: undefined,
  loading: false,
});

export const ConfigContextProvider: React.FC<React.PropsWithChildren<
  unknown
>> = ({ children }) => {
  const cacheKey = '/config';
  const { data, isValidating } = useSWR<
    Pick<ConfigContextProps, 'euCountries' | 'validation' | 'languages'>
  >(
    cacheKey,
    async () => {
      if (cache.has(cacheKey)) {
        return cache.get(cacheKey);
      }

      const [euCountries, validation, languages] = await Promise.all([
        configService.getEuropeanUnionCountries(),
        configService.getValidationConfig(),
        configService.getLanguages(),
      ]);

      return {
        euCountries,
        validation,
        languages,
      };
    },
    {
      revalidateOnFocus: false,
    }
  );

  const props: ConfigContextProps = {
    euCountries: data?.euCountries,
    validation: data?.validation,
    languages: data?.languages,
    loading: isValidating,
  };

  return (
    <ConfigContext.Provider value={props}>{children}</ConfigContext.Provider>
  );
};
