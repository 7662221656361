import { useAuth } from './useAuth';
import subscriptionService from 'features/subscriptions/services/subscriptionService';

interface UseUserIsSubscribedReturn {
  isLoading: boolean;
  isUserSubscribed: boolean;
}

export const useUserIsSubscribed = (): UseUserIsSubscribedReturn => {
  const { userSubscription: { subscription, isLoading } = {} } = useAuth();

  return {
    isLoading: Boolean(isLoading),
    isUserSubscribed: subscriptionService.userIsSubscribed(subscription),
  };
};
