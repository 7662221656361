import React from 'react';
import Accordion from 'components/accordion/Accordion';
import { useTranslation, Trans } from 'react-i18next';
import {
  termsUrl,
  withdrawTypeformUrl,
  publishedReleasesToBeatport,
} from 'helper/constants/constants';
import { useAuth } from 'hooks/useAuth';
import subscriptionService from './services/subscriptionService';

const SubscriptionFaqs: React.FC<React.PropsWithChildren<unknown>> = () => {
  const {
    t,
    i18n: { languages },
  } = useTranslation();
  const {
    user: { isGemaMember },
    userSubscription,
  } = useAuth();

  const userIsSubscribed =
    !userSubscription?.isLoading &&
    subscriptionService.userIsSubscribed(userSubscription?.subscription);

  const allFaqs = [
    {
      key: '1',
      header: t('subscriptions.faq1Header'),
      inner: t('subscriptions.faq1Content'),
    },
    {
      key: '6',
      header: t('subscriptions.faq6Header'),
      inner: (
        <Trans i18nKey={'subscriptions.faq6Content'}>
          <a
            href={`${publishedReleasesToBeatport[languages[0]]}`}
            rel="noopener noreferrer"
            className="link-in-text"
            target="_blank"
          >
            {''}
          </a>
        </Trans>
      ),
    },
    {
      key: '2',
      header: t('subscriptions.faq2Header'),
      inner: t('subscriptions.faq2Content'),
    },
    {
      key: '3',
      header: t('subscriptions.faq3Header'),
      inner: <Trans i18nKey={'subscriptions.faq3ContentNewCancellation'} />,
    },
    {
      key: '4',
      header: t('subscriptions.faq4Header'),
      inner: (
        <Trans i18nKey={'subscriptions.faq4Content'}>
          <a
            href={`${termsUrl[languages[0]]}`}
            rel="noopener noreferrer"
            className="link-in-text"
            target="_blank"
          >
            {''}
          </a>
          <a
            href={`${withdrawTypeformUrl[languages[0]]}`}
            rel="noopener noreferrer"
            className="link-in-text"
            target="_blank"
          >
            {''}
          </a>
        </Trans>
      ),
    },
    {
      key: '5',
      header: t('subscriptions.faq5Header'),
      inner: t('subscriptions.faq5Content'),
    },
  ];

  let filterKeys = ['5'];
  if (isGemaMember) {
    filterKeys = ['3', '4', '5'];
  } else if (userIsSubscribed) {
    filterKeys = [];
  }
  const faqPanels = allFaqs.filter(({ key }) => !filterKeys.includes(key));

  return <Accordion panels={faqPanels} />;
};

export default SubscriptionFaqs;
