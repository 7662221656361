import 'dayjs/locale/de';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { AvailableLocale } from 'helper/constants/constants';
import dayjs from 'dayjs';

const resources = {
  en: {
    translation: require('helper/i18n/en.json'),
  },
  de: {
    translation: require('helper/i18n/de.json'),
  },
};

// handle locale and language change
export const changeLanguage = (lng: string): void => {
  switch (lng.substr(7)) {
    case AvailableLocale.DE:
      i18n.changeLanguage(AvailableLocale.DE);
      dayjs.locale(AvailableLocale.DE);
      break;
    case AvailableLocale.EN:
    default:
      i18n.changeLanguage(AvailableLocale.EN);
      dayjs.locale(AvailableLocale.EN);
      break;
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    load: 'languageOnly',
    supportedLngs: ['en', 'de'],
    lowerCaseLng: true,
    resources,
    fallbackLng: 'en',
    keySeparator: '.',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    detection: {
      order: ['localStorage', 'navigator'],
      caches: ['localStorage'],
    },
    returnNull: false,
  })
  .then(() => {
    dayjs.locale(
      i18n.language.substr(0, 2).toLowerCase() === 'de'
        ? AvailableLocale.DE
        : AvailableLocale.EN
    );
  });

dayjs.locale(AvailableLocale.EN);

export default i18n;
