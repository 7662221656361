import React from 'react';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { MenuClickEventHandler } from 'rc-menu/lib/interface';
import i18n, { changeLanguage } from 'config/i18n';
import { Row, Col, Dropdown, Menu, message, Grid, Space, Divider } from 'antd';
import { GlobalOutlined, SettingOutlined } from '@ant-design/icons';
import { isSmScreen } from 'helper/styling/styling';
import { useAuth } from 'hooks/useAuth';
import Typography from 'component-library/src/typography/Typography';
import dayjs from 'dayjs';
import {
  SocialFacebookLink,
  SocialInstaLink,
  SocialLinkedinLink,
  SocialYTLink,
  gemaTermsUrl,
  termsUrl,
} from 'helper/constants/constants';
import { spacing } from 'component-library/src/theme/theme';
import Button from 'component-library/src/button/Button';
import {
  FooterFacebook,
  FooterInstagram,
  FooterLinkedIn,
  FooterYoutube,
} from 'assets/img';
import Container from 'components/container/Container';
import { mixpanel } from 'tracking/tracking';

interface MenuLink {
  title: string;
  url: string;
  key: string;
}

const createLinks = (t: TFunction, userIsGema?: boolean): Array<MenuLink> => [
  {
    title: t('footer.terms'),
    url: userIsGema
      ? gemaTermsUrl[i18n.languages[0]]
      : termsUrl[i18n.languages[0]],
    key: 'terms',
  },
  {
    title: t('footer.privacy'),
    url:
      i18n.languages[0] === 'de'
        ? 'https://www.music-hub.com/datenschutzerklaerung'
        : 'https://www.music-hub.com/en/privacy-notice',
    key: 'privacy',
  },
  {
    title: t('footer.imprint'),
    url:
      i18n.languages[0] === 'de'
        ? 'https://www.music-hub.com/de/impressum'
        : 'https://www.music-hub.com/en/impressum-en',
    key: 'imprint',
  },
];

const Footer: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  const handleLanguageChange: MenuClickEventHandler = (e): void => {
    const selectedKey = e.key as string;
    changeLanguage(selectedKey);
    message.info(t('footer.languageChanged'));
  };

  const languageDropdown = (
    <Menu title={t('menu.changeLanguage')} onClick={handleLanguageChange}>
      <Menu.Item
        key="locale.de"
        disabled={i18n.languages[0] === 'de'}
        data-testid="footer-language-de"
      >
        {t('language.german')}
      </Menu.Item>
      <Menu.Item
        key="locale.en"
        disabled={i18n.languages[0] === 'en'}
        data-testid="footer-language-en"
      >
        {t('language.english')}
      </Menu.Item>
    </Menu>
  );

  const links = createLinks(t, user.isGemaMember);
  const linkItems = links.map((link: MenuLink) => (
    <li key={link.key}>
      <a
        href={link.url}
        target="_blank"
        rel="noopener noreferrer"
        className="link"
        data-testid={`footer-${link.key}`}
      >
        <Typography type="body" size="s" weightLevel="500" color="secondary6">
          {link.title}
        </Typography>
      </a>
    </li>
  ));

  return (
    <footer className="footer">
      <Container withPadding>
        <Row
          gutter={[24, 32]}
          justify={isSmScreen(screens) ? 'space-between' : 'start'}
          align="middle"
        >
          <Col
            className="footer-links-col"
            order={isSmScreen(screens) ? 1 : 2}
            span={isSmScreen(screens) ? undefined : 24}
          >
            <ul>
              <Space
                direction={isSmScreen(screens) ? 'horizontal' : 'vertical'}
                size={isSmScreen(screens) ? spacing.space32 : spacing.space16}
              >
                <li
                  data-testid="footer-privacy-settings"
                  className="footer-privacy-settings"
                >
                  <a
                    title={t('footer.privacySettings')}
                    onClick={() => {
                      if (window.UC_UI && window.UC_UI.isInitialized()) {
                        window.UC_UI.showSecondLayer();
                      } else {
                        window.addEventListener('UC_UI_INITIALIZED', () => {
                          window.UC_UI.showSecondLayer();
                        });
                      }
                    }}
                  >
                    <Space direction="horizontal" size={spacing.space8}>
                      <SettingOutlined />
                      <Typography
                        type="body"
                        size="s"
                        weightLevel="500"
                        color="secondary6"
                      >
                        {t('footer.privacySettings')}
                      </Typography>
                    </Space>
                  </a>
                </li>

                {linkItems}
              </Space>
            </ul>
          </Col>

          <Col
            className="footer-language-button"
            order={isSmScreen(screens) ? 2 : 1}
            span={isSmScreen(screens) ? undefined : 24}
          >
            <Dropdown
              overlay={languageDropdown}
              trigger={['click']}
              placement={isSmScreen(screens) ? 'topRight' : 'top'}
            >
              <Button
                data-testid="footer-change-language"
                className="footer-change-language"
                type="secondary"
                size={isSmScreen(screens) ? 'm' : 's'}
                variant="outline"
                onClick={(e): void => {
                  e.preventDefault();
                }}
                trackEvent={mixpanel.track.bind(mixpanel)}
              >
                <Space direction="horizontal" size={spacing.space8}>
                  <Typography
                    type="body"
                    size="s"
                    color="secondary6"
                    weightLevel="600"
                  >
                    {i18n.language.toUpperCase() || t('menu.changeLanguage')}
                  </Typography>
                  <GlobalOutlined />
                </Space>
              </Button>
            </Dropdown>
          </Col>
        </Row>

        <Divider style={{ backgroundColor: '#C3BCDB' }} />

        {user.isLoggedIn && user.emailVerified && (
          <Row gutter={[24, 32]} justify="space-between" align="middle">
            <Col {...(!isSmScreen(screens) ? { order: 1, span: 24 } : {})}>
              <Space
                direction="horizontal"
                size={spacing.space20}
                align="center"
              >
                <a
                  href={SocialInstaLink}
                  rel="noopener noreferrer"
                  target="_blank"
                  title="Instagram Link"
                >
                  <FooterInstagram />
                </a>
                <a
                  href={SocialFacebookLink}
                  rel="noopener noreferrer"
                  target="_blank"
                  title="Facebook Link"
                >
                  <FooterFacebook />
                </a>
                <a
                  href={SocialLinkedinLink}
                  rel="noopener noreferrer"
                  target="_blank"
                  title="LinkedIn Link"
                >
                  <FooterLinkedIn />
                </a>
                <a
                  href={SocialYTLink}
                  rel="noopener noreferrer"
                  target="_blank"
                  title="YouTube Link"
                >
                  <FooterYoutube />
                </a>
              </Space>
            </Col>

            <Typography type="body" size="xs" color="neutral8">
              {t('footer.copyright', { date: dayjs().format('YYYY') })}
            </Typography>
          </Row>
        )}
      </Container>
    </footer>
  );
};

export default Footer;
