import React, { ReactNode } from 'react';
import { Breakpoint, Grid } from 'antd';

interface ScreenConditionProps {
  children: ReactNode;
  fallback?: ReactNode;
  hideOn: Breakpoint;
}

const ScreenCondition: React.FC<ScreenConditionProps> = ({
  children,
  hideOn,
  fallback = null,
}) => {
  const screens = Grid.useBreakpoint();
  const showContent = screens[hideOn];

  if (showContent) return <>{children}</>;
  return <>{fallback}</>;
};

export default ScreenCondition;
