import React from 'react';
import classNames from 'classnames';
import Typography from 'component-library/src/typography/Typography';
import RadioAntd, { RadioProps as RadioAntdProps } from 'antd/lib/radio';
import { CheckboxRef } from 'antd/lib/checkbox';

export interface RadioBaseProps extends RadioAntdProps {
  radioKey?: string;
  label?: React.ReactNode;
  testId?: string;
  icon?: React.ReactNode;
  responsiveFont?: boolean;
}

export type RadioStyleProps = {
  variant: 'default' | 'bordered';
  description?: string;
};

export type RadioProps = RadioBaseProps & RadioStyleProps;

const Radio = React.forwardRef<CheckboxRef, RadioProps>(
  (
    {
      radioKey,
      variant,
      label,
      value,
      description,
      testId,
      icon,
      disabled,
      checked = true,
      responsiveFont = true,
    }: RadioProps,
    ref
  ): JSX.Element => (
    <RadioAntd
      ref={ref}
      key={radioKey}
      value={value}
      data-testid={testId}
      className={classNames({
        'custom-radio': true,
        [`custom-radio-${variant}`]: variant,
        'custom-radio-centered': !description,
      })}
      checked={checked}
      defaultChecked
      disabled={disabled}
    >
      <div className="custom-radio-container">
        <div className="custom-radio-label-container">
          <Typography
            size="s"
            type="body"
            color="neutral9"
            weightLevel="500"
            data-testid={`${testId}-label`}
            responsiveFont={responsiveFont}
          >
            {label}
          </Typography>
          {description && (
            <Typography
              type="body"
              size="xs"
              color="neutral7"
              weightLevel="400"
              data-testid={`${testId}-description`}
              responsiveFont={responsiveFont}
            >
              {description}
            </Typography>
          )}
        </div>
        {icon && <div className="custom-radio-icon">{icon}</div>}
      </div>
    </RadioAntd>
  )
);

export const Group = RadioAntd.Group;

export default Radio;
