import React, { ReactNode } from 'react';
import { Layout as AntLayout } from 'antd';
import Footer from 'components/footer/Footer';
import { ImpersonatedUserAlert } from 'components/impersonated-user-alert/ImpersonatedUserAlert';
import BackendErrorBanner from 'components/backend-error-banner/BackendErrorBanner';
import FileUploadProgress from 'components/upload/FileUploadProgress';
import Sticky from 'components/sticky/Sticky';
import FeatureDisabledBanner from '../feature-disabled-banner/FeatureDisabledBanner';
import PageLoader from 'components/page-loader/PageLoader';
import classNames from 'classnames';
import SubscriptionAlertBanner from 'features/subscriptions/info-banners/SubscriptionAlertBanner';

export interface LayoutProps {
  header?: ReactNode;
  headerSticky?: boolean;
  children: ReactNode;
  isLoading?: boolean;
  className?: string;
  dark?: boolean;
  loadingIcon?: ReactNode;
  testId?: string;
}

const Layout: React.FC<React.PropsWithChildren<LayoutProps>> = ({
  header,
  children,
  isLoading,
  className,
  dark,
  headerSticky = false,
  loadingIcon = <PageLoader />,
  testId = 'layout',
}: LayoutProps) => {
  const AntContent = AntLayout.Content;

  return (
    <AntLayout
      className={classNames(className, 'ant-content-layout', 'custom-layout')}
      data-testid={testId}
    >
      <ImpersonatedUserAlert />
      <BackendErrorBanner />
      <FeatureDisabledBanner />
      <SubscriptionAlertBanner />

      <AntContent
        className="custom-layout-content"
        data-theme={dark ? 'dark' : undefined}
      >
        {headerSticky ? (
          <Sticky className="layout-header-sticky">{header}</Sticky>
        ) : (
          header
        )}

        {isLoading ? loadingIcon : children}
      </AntContent>

      <Footer />
      <FileUploadProgress />
    </AntLayout>
  );
};

export default Layout;
