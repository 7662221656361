import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStatusCheck } from 'hooks/useStatusCheck';
import Banner from 'components/banner/Banner';
import {
  AlertType,
  ButtonWithClickHandler,
} from 'components/section-alert/SectionAlert';

const BackendErrorBanner = (): JSX.Element => {
  const { t } = useTranslation();
  const { showMaintenance, showResolved } = useStatusCheck();
  const [type, closable, message, description, actionButton, testId]: [
    AlertType,
    boolean,
    string | undefined,
    string,
    ButtonWithClickHandler | undefined,
    string
  ] = showMaintenance
    ? [
        'warning',
        false,
        t('error.apiStatusErrorMessage'),
        t('error.apiStatusErrorDescription'),
        {
          text: t('banner.contactUs'),
          onClick: (): void => window.Intercom('show'),
        },
        'maintenance-message',
      ]
    : [
        'success',
        true,
        undefined,
        t('error.apiStatusUpBody'),
        undefined,
        'resolved-message',
      ];

  return showMaintenance || showResolved ? (
    <Banner
      type={type}
      message={message}
      closable={closable}
      description={description}
      actionButton={actionButton}
      testId={testId}
    />
  ) : (
    <></>
  );
};

export default BackendErrorBanner;
