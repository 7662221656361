import React, {
  createContext,
  useContext,
  useReducer,
  useMemo,
  useLayoutEffect,
  useRef,
} from 'react';
import { mixpanel } from 'tracking/tracking';
import {
  SubscriptionPaySuccessModal,
  SubscriptionsCancelCheck,
  SubscriptionsCancelConfirm,
  SubscriptionProcessing,
  SubscriptionReactivateSuccessModal,
  SubscriptionBillingCycleSwitchModal,
  SubscriptionCycleSwitchSuccess,
} from './modals';
import SubscriptionPaymentErrorModal, {
  SubscriptionPaymentErrorModalType,
} from './modals/SubscriptionPaymentErrorModal';
import SubscriptionsPricingModal from './modals/SubscriptionsPricingModal';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import i18n from 'config/i18n';
import { useLocation } from 'react-router-dom';

type PageType =
  | 'subscriptionsFaq'
  | 'subscriptionsPayProcessing'
  | 'subscriptionsReactivateProcessing'
  | 'subscriptionsPaySuccess'
  | 'subscriptionsPaySuccessProfileIncomplete'
  | 'subscriptionsReactivateSuccess'
  | 'subscriptionsCancelCheck'
  | 'subscriptionsCancelConfirm'
  | 'subscriptionsBillingCycleSwitch'
  | 'subscriptionsUpdateSuccess'
  | 'subscriptionsPaymentError';

type State = {
  error?: string;
  page?: PageType;
  paymentType?: SubscriptionPaymentErrorModalType;
  isOpen: boolean;
};

type Action =
  | { type: 'subscriptionsFaq' }
  | { type: 'subscriptionsPayProcessing' }
  | { type: 'subscriptionsReactivateProcessing' }
  | { type: 'subscriptionsPaySuccess' }
  | { type: 'subscriptionsPaySuccessProfileIncomplete' }
  | { type: 'subscriptionsReactivateSuccess' }
  | { type: 'subscriptionsCancelCheck' }
  | { type: 'subscriptionsCancelConfirm' }
  | {
      type: 'subscriptionsPaymentError';
      error: string;
      paymentType?: SubscriptionPaymentErrorModalType;
    }
  | { type: 'subscriptionsBillingCycleSwitch' }
  | { type: 'subscriptionsUpdateSuccess' }
  | { type: 'close' };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'close':
      mixpanel.track({
        event: 'SUBSCRIPTIONS_MODAL_CLOSE',
        element: state.page,
      });
      return {
        page: undefined,
        isOpen: false,
        error:
          state.page === 'subscriptionsPaymentError' ? state.error : undefined,
      };
    case 'subscriptionsPaymentError':
      return {
        page: action.type,
        isOpen: true,
        error: action.error,
      };
    default:
      return {
        page: action.type,
        isOpen: true,
        error: undefined,
      };
  }
};

type SubscriptionModalContextProps = State & {
  dispatch: (action: Action) => void;
};

export const SubscriptionModalContext = createContext<
  SubscriptionModalContextProps
>({
  isOpen: false,
  page: undefined,
  paymentType: undefined,
  dispatch: () => null,
});

export const useSubscriptionModalContext = (): SubscriptionModalContextProps => {
  return useContext(SubscriptionModalContext);
};

const SubscriptionModalContextProvider: React.FC<React.PropsWithChildren<
  unknown
>> = ({ children }) => {
  const location = useLocation();
  const previousLocation = useRef<string | undefined>();
  const [state, dispatch] = useReducer(reducer, {
    isOpen: false,
  });
  const stripePromise = useMemo(
    () =>
      loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || '', {
        locale: i18n.language as 'de' | 'en',
      }),
    []
  );
  const { isOpen, page, paymentType } = state;

  useLayoutEffect(() => {
    if (
      Boolean(previousLocation.current) &&
      Boolean(page) &&
      isOpen &&
      previousLocation.current != location.pathname
    ) {
      dispatch({ type: 'close' });
    }
    previousLocation.current = location.pathname;
  }, [location.pathname, isOpen, page]);

  return (
    <SubscriptionModalContext.Provider
      value={{
        ...state,
        dispatch,
      }}
    >
      <>
        {isOpen && page === 'subscriptionsFaq' ? (
          <SubscriptionsPricingModal />
        ) : null}
        {isOpen && page === 'subscriptionsPaySuccess' ? (
          <SubscriptionPaySuccessModal isProfileCompete={true} />
        ) : null}
        {isOpen && page === 'subscriptionsPaySuccessProfileIncomplete' ? (
          <SubscriptionPaySuccessModal isProfileCompete={false} />
        ) : null}
        {isOpen && page === 'subscriptionsReactivateSuccess' ? (
          <SubscriptionReactivateSuccessModal
            handleClose={() => dispatch({ type: 'close' })}
          />
        ) : null}
        {isOpen && page === 'subscriptionsCancelCheck' ? (
          <SubscriptionsCancelCheck />
        ) : null}
        {isOpen && page === 'subscriptionsCancelConfirm' ? (
          <SubscriptionsCancelConfirm />
        ) : null}
        {isOpen && page === 'subscriptionsPayProcessing' ? (
          <SubscriptionProcessing type="PAYMENT" />
        ) : null}
        {isOpen && page === 'subscriptionsReactivateProcessing' ? (
          <SubscriptionProcessing type="REACTIVATION" />
        ) : null}
        {isOpen && page === 'subscriptionsPaymentError' ? (
          <SubscriptionPaymentErrorModal paymentType={paymentType} />
        ) : null}
        {isOpen && page === 'subscriptionsBillingCycleSwitch' ? (
          <Elements
            stripe={stripePromise}
            options={{
              fonts: [
                {
                  cssSrc:
                    'https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap',
                },
              ],
            }}
          >
            <SubscriptionBillingCycleSwitchModal />
          </Elements>
        ) : null}
        {isOpen && page === 'subscriptionsUpdateSuccess' ? (
          <SubscriptionCycleSwitchSuccess />
        ) : null}
        {children}
      </>
    </SubscriptionModalContext.Provider>
  );
};

export default SubscriptionModalContextProvider;
