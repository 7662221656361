import React, { ReactNode } from 'react';
import classnames from 'classnames';
import Typography from 'component-library/src/typography/Typography';

export interface SectionProps {
  className?: string;
  title?: string;
  subtitle?: ReactNode;
  subtitleInline?: boolean;
  children: ReactNode;
  actions?: ReactNode;
  testId?: string;
}

const Section: React.FC<SectionProps> = ({
  className = 'section',
  title,
  subtitle,
  children,
  actions,
  subtitleInline = false,
  testId,
}: SectionProps) => {
  return (
    <>
      <div
        className={classnames({
          'section-header': true,
          [`${className}-header`]: typeof className !== 'undefined',
        })}
        data-testid={testId}
      >
        {title || subtitle ? (
          <div
            className={classnames({
              'section-header-title': true,
              'section-header-title--inline': subtitleInline,
            })}
          >
            {title && (
              <Typography
                data-testid={`${testId}-title`}
                type="headline"
                size="s"
                color="secondary7"
              >
                {title}
              </Typography>
            )}
            {subtitle && (
              <Typography type="body" size="m" color="neutral7">
                {subtitle}
              </Typography>
            )}
          </div>
        ) : null}

        {actions && <div className="section-header-actions">{actions}</div>}
      </div>
      <div className={`${className}-child`}>{children}</div>
    </>
  );
};

export default Section;
