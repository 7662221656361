/*
TODO: this file was exported from less theme variables before the CRA4/craco upgrade
*/

const baseTheme = {
  primaryColor: '#9ef8d0',
  secondaryColor: '#17122b',
  neutralColor: '#262626',
  dangerColor: '#f5222d',
  warningColor: '#fa8c16',
  successColor: '#52c41a',
  infoColor: '#286ef0',
  accentColor: '#a547fb',
  white: '#ffffff',
};

const theme = {
  colors: {
    ...baseTheme,
    primary1: '#e5fff3',
    primary2: '#c7ffe5',
    primary3: '#9ef8d0',
    primary4: '#7cd9b3',
    primary5: '#5db393',
    primary6: '#428c73',
    primary7: '#2f6655',

    secondary1: '#f5f3fd',
    secondary2: '#ded9ef',
    secondary3: '#c3bcdb',
    secondary4: '#9a93b2',
    secondary5: '#635c7e',
    secondary6: '#45415c',
    secondary7: baseTheme.secondaryColor,

    // NOTE: These should be not be used in custom components they are additional to the design system but needed for antd
    secondary8: '#9a93b2',
    secondary9: '#9a93b2',
    secondary10: baseTheme.secondaryColor,
    secondary11: '#ded9ef',
    secondary12: '#f5f3fd',

    neutral1: baseTheme.white,
    neutral2: '#fafafa',
    neutral3: '#f5f5f5',
    neutral4: '#e8e8e8',
    neutral5: '#bfbfbf',
    neutral6: '#999999',
    neutral7: '#707070',
    neutral8: '#515151',
    neutral9: baseTheme.neutralColor,

    success1: '#f1fce6',
    success2: '#d9f7be',
    success3: '#b7eb8f',
    success4: baseTheme.successColor,
    success5: '#389e0d',
    success6: '#237804',

    warning1: '#fff7e6',
    warning2: '#ffe7ba',
    warning3: '#ffd591',
    warning4: baseTheme.warningColor,
    warning5: '#d46b08',
    warning6: '#ad4e00',

    danger1: '#fff1f0',
    danger2: '#ffccc7',
    danger3: '#ffa39e',
    danger4: baseTheme.dangerColor,
    danger5: '#cf1322',
    danger6: '#a8071a',

    info1: '#f0f8ff',
    info2: '#cfe6ff',
    info3: '#a6ceff',
    info4: baseTheme.infoColor,
    info5: '#1850c9',
    info6: '#0b36a3',

    accent: baseTheme.accentColor,
    accent1: '#faf0ff',
    accent2: '#f4e1ff',
    accent3: '#e8c2ff',
    accent4: baseTheme.accentColor,
    accent5: '#7f31d4',
    accent6: '#431287',

    transparent: 'transparent',
    primary: baseTheme.primaryColor,
    secondary: baseTheme.secondaryColor,
    neutral: baseTheme.neutralColor,
    danger: baseTheme.dangerColor,
    warning: baseTheme.warningColor,
    success: baseTheme.successColor,
    info: baseTheme.infoColor,
  },
};

export type Theme = typeof theme;
export interface ThemeColorProps {
  color?: keyof Theme['colors'];
}

export const getThemeColor = (key: keyof Theme['colors']): string =>
  theme.colors[key];

export const spacing = {
  space2: 2,
  space4: 4,
  space8: 8,
  space12: 12,
  space16: 16,
  space20: 20,
  space22: 22,
  space24: 24,
  space28: 28,
  space32: 32,
  space40: 40,
  space42: 42,
  space64: 64,
  space80: 80,
};

// base row gutter
export const ROW_GUTTER = {
  xs: spacing.space8,
  sm: spacing.space16,
  md: spacing.space24,
  xl: spacing.space24,
};

export default theme;
