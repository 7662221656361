import { Track } from 'models/Track';
import i18next from 'i18next';

export class TrackError extends Error {
  trackId: string;
  fieldId: keyof Track;

  constructor(message: string, trackId: string, fieldId: keyof Track) {
    super(message);
    this.message = message;
    this.trackId = trackId;
    this.fieldId = fieldId;
  }
}

export class DuplicateTrackError extends TrackError {
  constructor(trackId: string) {
    const message = i18next.t('track.isrcDuplicateError');
    super(message, trackId, 'isrc');
    this.name = 'DuplicateTrackError';
  }
}
