import React, { MutableRefObject } from 'react';
import classnames from 'classnames';
import { useRef, useEffect, useState } from 'react';

interface StickyProps {
  className: string;
  rootRef?: MutableRefObject<HTMLDivElement | null>;
}

const Sticky: React.FC<React.PropsWithChildren<StickyProps>> = (props) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const cachedRef = ref.current;
    const observer = new IntersectionObserver(
      ([e]) => {
        setIsSticky(e.intersectionRatio < 1);
      },
      {
        root: props.rootRef?.current ? props.rootRef.current : null,
        threshold: [1],
        rootMargin: '-1px 0px 0px 0px',
      }
    );
    observer.observe(cachedRef);
    return (): void => observer.unobserve(cachedRef);
  }, [props.rootRef]);

  return (
    <div ref={ref} className={classnames(props.className, { stuck: isSticky })}>
      {props.children}
    </div>
  );
};

export default Sticky;
